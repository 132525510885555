import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import { IAirAuditData } from "./FinishedReports";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../common/interface/TableColumn";
import dayjs from "dayjs";
import { PageConstants } from "../../common/PageConstants";
import { AuditTypes } from "../../common/AuditTypeConstants";
import { useRequest } from "../../services/agent";
import { RequestApi } from "../../common/Constant";

export interface IEnergyRecoveryAuditData extends IAirAuditData {
  energySource: string;
}

export type tableColumns =
  | "id"
  | "auditId"
  | "projectSource"
  | "uploadedDate"
  | "c4CId"
  | "customer"
  | "auditName"
  | "typeOfAudit"
  | "country"
  | "city"
  | "energySource"
  | "responsible";

const energyRecoverycolumns: TableColumn[] = [
  {
    id: "auditId" as tableColumns,
    label: "Project ID",
    align: "left",
    minWidth: 150,
    filter: true,
    filterType: "text",
    sorting: true,
  },
  {
    id: "uploadedDate",
    label: "Upload Date",
    minWidth: 155,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
    filterType: "datepicker",
    sorting: true,
    datePickerMode: DatePickerMode.MonthYear,
  },
  {
    id: "c4CId",
    label: "Account ID",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 200,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditName",
    label: "Project Name",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditType",
    label: "Type Of Project",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
  },
  {
    id: "energySource",
    label: "Energy Source",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "city",
    label: "City",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },

  {
    id: "responsible",
    label: "Responsible",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
];

interface AirNetProp {
  actionButtons: TableActionButtons[];
  energyRecoveryAuditFilters: any;
}

export default function EnergyRecovery({
  actionButtons,
  energyRecoveryAuditFilters,
}: AirNetProp) {
  const [getEnergyRecoveryData, SetGetEnergyRecoveryData] = useState(false);
  const [energyRecoveryAuditData, setEnergyRecoveryAuditData] = useState<
    Array<IEnergyRecoveryAuditData>
  >([]);

  const [energyRecoveryAuditTotalRecords, setEnergyRecoveryAuditTotalRecords] =
    useState<number>(0);

  const {
    energyRecoveryAuditFilterC4cId,
    setEnergyRecoveryAuditFilterC4cId,
    energyRecoveryAuditFilterUploadedDate,
    setEnergyRecoveryAuditFilterUploadedDate,
    energyRecoveryFilterLocalDate,
    setEnergyRecoveryFilterLocalDate,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditFilterAuditId,
    energyRecoveryAuditFilterAuditType,
    setEnergyRecoveryAuditFilterAuditType,
    energyRecoveryAuditFilterProjectSource,
    setEnergyRecoveryAuditFilterProjectSource,
    energyRecoveryAuditFilterEnergySource,
    setEnergyRecoveryAuditFilterEnergySource,
    energyRecoveryAuditFilterResponsible,
    setEnergyRecoveryAuditFilterResponsible,
    energyRecoveryAuditFilterAuditName,
    setEnergyRecoveryAuditFilterAuditName,
    energyRecoveryAuditFilterCustomer,
    setEnergyRecoveryAuditFilterCustomer,
    energyRecoveryAuditFilterCity,
    setEnergyRecoveryAuditFilterCity,
    energyRecoveryAuditFilterCountry,
    setEnergyRecoveryAuditFilterCountry,
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
  } = energyRecoveryAuditFilters;

  const request = useRequest();

  const energyRecoveryAuditfilterObject: any = useMemo(
    () => ({
      c4CId: energyRecoveryAuditFilterC4cId,
      auditId: energyRecoveryAuditFilterAuditId,
      uploadedDate: dayjs(energyRecoveryFilterLocalDate).isValid()
        ? dayjs(energyRecoveryFilterLocalDate)
        : "",
      auditType: energyRecoveryAuditFilterAuditType,
      responsible: energyRecoveryAuditFilterResponsible,
      auditName: energyRecoveryAuditFilterAuditName,
      customerName: energyRecoveryAuditFilterCustomer,
      city: energyRecoveryAuditFilterCity,
      country: energyRecoveryAuditFilterCountry,
      projectSource: energyRecoveryAuditFilterProjectSource,
      energySource: energyRecoveryAuditFilterEnergySource,
    }),
    [
      energyRecoveryAuditFilterAuditId,
      energyRecoveryAuditFilterAuditName,
      energyRecoveryAuditFilterAuditType,
      energyRecoveryAuditFilterC4cId,
      energyRecoveryAuditFilterResponsible,
      energyRecoveryFilterLocalDate,
      energyRecoveryAuditFilterCustomer,
      energyRecoveryAuditFilterCity,
      energyRecoveryAuditFilterCountry,
      energyRecoveryAuditFilterProjectSource,
      energyRecoveryAuditFilterEnergySource,
    ]
  );

  const handleEnergyRecoveryFilterChange = (column: string, event: any) => {
    if (column === "c4CId") {
      setEnergyRecoveryAuditFilterC4cId(event.target.value);
    } else if (column === "status") {
      // setAirAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setEnergyRecoveryAuditFilterUploadedDate(
          dayjs(event.$d).format("DD/MM/YYYY")
        );
        setEnergyRecoveryFilterLocalDate(event.$d);
      } else {
        setEnergyRecoveryAuditFilterUploadedDate("");
        setEnergyRecoveryFilterLocalDate("");
      }
    } else if (column === "auditId") {
      setEnergyRecoveryAuditFilterAuditId(event.target.value);
    } else if (column === "auditType") {
      setEnergyRecoveryAuditFilterAuditType(event.target.value);
    } else if (column === "responsible") {
      setEnergyRecoveryAuditFilterResponsible(event.target.value);
    } else if (column === "auditName") {
      setEnergyRecoveryAuditFilterAuditName(event.target.value);
    } else if (column === "customerName") {
      setEnergyRecoveryAuditFilterCustomer(event.target.value);
    } else if (column === "city") {
      setEnergyRecoveryAuditFilterCity(event.target.value);
    } else if (column === "country") {
      setEnergyRecoveryAuditFilterCountry(event.target.value);
    } else if (column === "projectSource") {
      setEnergyRecoveryAuditFilterProjectSource(event.target.value);
    } else if (column === "energySource") {
      setEnergyRecoveryAuditFilterEnergySource(event.target.value);
    }
  };

  const loadEnergyRecoveryAuditData = useCallback((): void => {
    let body = {
      page: energyRecoveryAuditPage,
      pageSize: 20,
      sort: energyRecoveryAuditSortingColumn,
      order: energyRecoveryAuditSortingOrder,
      c4CId: energyRecoveryAuditFilterC4cId
        ? energyRecoveryAuditFilterC4cId
        : "",
      auditId: energyRecoveryAuditFilterAuditId
        ? energyRecoveryAuditFilterAuditId
        : "",
      auditType: energyRecoveryAuditFilterAuditType
        ? energyRecoveryAuditFilterAuditType
        : "",
      responsible: energyRecoveryAuditFilterResponsible
        ? energyRecoveryAuditFilterResponsible
        : "",
      uploadedDate: energyRecoveryAuditFilterUploadedDate
        ? energyRecoveryAuditFilterUploadedDate === "clear"
          ? ""
          : energyRecoveryAuditFilterUploadedDate
        : "",
      auditName: energyRecoveryAuditFilterAuditName
        ? energyRecoveryAuditFilterAuditName
        : "",
      customerName: energyRecoveryAuditFilterCustomer
        ? energyRecoveryAuditFilterCustomer
        : "",
      city: energyRecoveryAuditFilterCity ? energyRecoveryAuditFilterCity : "",
      country: energyRecoveryAuditFilterCountry
        ? energyRecoveryAuditFilterCountry
        : "",
      projectSource: energyRecoveryAuditFilterProjectSource
        ? energyRecoveryAuditFilterProjectSource
        : "",
      energySource: energyRecoveryAuditFilterEnergySource
        ? energyRecoveryAuditFilterEnergySource
        : "",
    };

    request({
      url: RequestApi.GET_ENERGY_RECOVERY_AUDIT_FINISHED_REPORT_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setEnergyRecoveryAuditData(resp.data.data);
        setEnergyRecoveryAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        // setAlert("error", "Request Failed", true);
      });
  }, [
    energyRecoveryAuditFilterAuditId,
    energyRecoveryAuditFilterAuditName,
    energyRecoveryAuditFilterAuditType,
    energyRecoveryAuditFilterC4cId,
    energyRecoveryAuditFilterCity,
    energyRecoveryAuditFilterCountry,
    energyRecoveryAuditFilterCustomer,
    energyRecoveryAuditFilterResponsible,
    energyRecoveryAuditFilterUploadedDate,
    energyRecoveryAuditPage,
    energyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    energyRecoveryAuditFilterProjectSource,
    energyRecoveryAuditFilterEnergySource,
    request,
  ]);

  useEffect(() => {
    if (getEnergyRecoveryData == true) {
      loadEnergyRecoveryAuditData();
    }
    return () => {
      SetGetEnergyRecoveryData(false);
    };
  }, [loadEnergyRecoveryAuditData, getEnergyRecoveryData]);

  useEffect(() => {
    loadEnergyRecoveryAuditData();
  }, []);

  const clearAllEnergyRecoveryAuditSorting = useCallback(() => {
    setEnergyRecoveryAuditPage(0);
    setEnergyRecoveryAuditFilterAuditId("");
    setEnergyRecoveryAuditFilterAuditName("");
    setEnergyRecoveryAuditFilterAuditType("");
    setEnergyRecoveryAuditFilterC4cId("");
    setEnergyRecoveryAuditFilterResponsible("");
    // setAirAuditFilterStatus('');
    setEnergyRecoveryAuditSortingColumn("");
    setEnergyRecoveryAuditSortingOrder("");
    setEnergyRecoveryFilterLocalDate("");
    setEnergyRecoveryAuditFilterUploadedDate("");
    setEnergyRecoveryAuditFilterCustomer("");
    setEnergyRecoveryAuditFilterCity("");
    setEnergyRecoveryAuditFilterCountry("");
    setEnergyRecoveryAuditFilterEnergySource("");
    setEnergyRecoveryAuditFilterProjectSource("");
    SetGetEnergyRecoveryData(true);
  }, []);

  return (
    <CustomTable
      isInsideTab={true}
      data={energyRecoveryAuditData}
      filterChange={handleEnergyRecoveryFilterChange}
      actionButtons={actionButtons}
      columns={energyRecoverycolumns}
      filterObject={energyRecoveryAuditfilterObject}
      setPage={setEnergyRecoveryAuditPage}
      sortColumn={energyRecoveryAuditSortingColumn}
      setSortColumn={setEnergyRecoveryAuditSortingColumn}
      sortColumnOrder={energyRecoveryAuditSortingOrder}
      setSortColumnOrder={setEnergyRecoveryAuditSortingOrder}
      page={energyRecoveryAuditPage}
      clearAll={clearAllEnergyRecoveryAuditSorting}
      finishedReportsPage={true}
      stickyActionColumn={true}
      actionColumnWidth={"155px"}
      loadDataFunction={loadEnergyRecoveryAuditData}
      totalRecords={energyRecoveryAuditTotalRecords}
      setGetData={SetGetEnergyRecoveryData}
      typeOfAudit={AuditTypes.EnergyRecovery}
      pageName={PageConstants.FinishedReports}
    />
  );
}
