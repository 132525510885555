import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import { useSearchParams } from "react-router-dom";
import { TableColumn } from "../../common/interface/TableColumn";
import {
  ActualSavings,
  actualSavingsDropDownData,
  getIsTotalQuotedValue,
  IOpportunityAirAuditData,
  salesPhaseDropDownData,
  statusDropDownData,
} from "./GeneratedOpportunities";
import { RequestApi } from "../../common/Constant";
import { useRequest } from "../../services/agent";

interface IOpportunityEnergyRecoveryAuditData
  extends IOpportunityAirAuditData {}

export default function EnergyRecoveryOpportunities({
  energyRecoveryAuditFilters,
  energyRecoverySorting,
}: any) {
  const [pageSpecificData, setPageSpecificData] = useState<{
    opportunityStausUpdatedOn: "string";
  }>();

  const [energyRecoveryAuditTotalRecords, setEnergyRecoveryAuditTotalRecords] =
    useState<number>(0);
  const [energyRecoveryAuditData, setEnergyRecoveryAuditData] = useState<
    Array<IOpportunityEnergyRecoveryAuditData>
  >([]);
  const [getEnergyRecoveryData, setGetEnergyRecoveryData] = useState(false);
  const [salesAirColumns, setSalesAirColumns] = useState<TableColumn[]>([]);

  const {
    energyRecoveryAuditFilterStatus,
    setEnergyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterLeadId,
    setEnergyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterOppurtunityId,
    setEnergyRecoveryAuditFilterOppurtunityId,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditAuditId,
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterSalesPhase,
    setEnergyRecoveryAuditFilterSalesPhase,
    energyRecoveryAuditActualSavingValueInt,
    setEnergyRecoveryAuditActualSavingValueInt,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
  } = energyRecoveryAuditFilters;

  const {
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
  } = energyRecoverySorting;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const request = useRequest();

  const energyRecoveryAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: energyRecoveryAuditFilterAuditId,
      leadId: energyRecoveryAuditFilterLeadId
        ? energyRecoveryAuditFilterLeadId
        : "",
      opportunityId: energyRecoveryAuditFilterOppurtunityId
        ? energyRecoveryAuditFilterOppurtunityId
        : "",
      status: energyRecoveryAuditFilterStatus,
      actualSavings: energyRecoveryAuditActualSavingValueInt,
      customerName: energyRecoveryAuditFilterCustomerName,
      salesPhase: energyRecoveryAuditFilterSalesPhase,
    }),
    [
      energyRecoveryAuditFilterAuditId,
      energyRecoveryAuditFilterLeadId,
      energyRecoveryAuditFilterOppurtunityId,
      energyRecoveryAuditFilterStatus,
      energyRecoveryAuditFilterCustomerName,
      energyRecoveryAuditFilterSalesPhase,
      energyRecoveryAuditActualSavingValueInt,
    ]
  );

  const handleEnergyRecoveryFilterChange = (id: any, event: any) => {
    switch (id) {
      case "auditNumber":
        setEnergyRecoveryAuditAuditId(event.target.value);
        break;
      case "status":
        setEnergyRecoveryAuditFilterStatus(event.target.value);
        break;
      case "actualSavings":
        setEnergyRecoveryAuditActualSavingValueInt(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setEnergyRecoveryAuditFilterLeadId(null);
        } else {
          setEnergyRecoveryAuditFilterLeadId(event.target.value);
        }
        break;
      case "opportunityId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setEnergyRecoveryAuditFilterOppurtunityId(null);
        } else {
          setEnergyRecoveryAuditFilterOppurtunityId(event.target.value);
        }
        break;
      case "customerName":
        setEnergyRecoveryAuditFilterCustomerName(event.target.value);
        break;
      case "salesPhase":
        setEnergyRecoveryAuditFilterSalesPhase(event.target.value);
        break;
    }
  };

  const clearAllEnergyRecoveryAuditSorting = useCallback(() => {
    setEnergyRecoveryAuditPage(0);
    setEnergyRecoveryAuditFilterStatus(0);
    setEnergyRecoveryAuditSortingColumn("");
    setEnergyRecoveryAuditSortingOrder("");
    setEnergyRecoveryAuditAuditId("");
    setEnergyRecoveryAuditFilterLeadId(null);
    setEnergyRecoveryAuditFilterOppurtunityId(null);
    setEnergyRecoveryAuditFilterCustomerName("");
    setGetEnergyRecoveryData(true);
    setEnergyRecoveryAuditFilterSalesPhase(0);
    setEnergyRecoveryAuditActualSavingValueInt(1);
  }, []);

  const loadEnergyRecoveryAuditData = useCallback((): void => {
    let body = {
      page: energyRecoveryAuditPage,
      pageSize: 20,
      sort: energyRecoveryAuditSortingColumn,
      order: energyRecoveryAuditSortingOrder,
      auditId: energyRecoveryAuditFilterAuditId
        ? energyRecoveryAuditFilterAuditId
        : "",
      status: energyRecoveryAuditFilterStatus
        ? statusDropDownData.filter(
            (data) => data.valueInt === energyRecoveryAuditFilterStatus
          )[0].value
        : "",
      LeadId: energyRecoveryAuditFilterLeadId
        ? energyRecoveryAuditFilterLeadId
        : 0,
      OpportunityId: energyRecoveryAuditFilterOppurtunityId
        ? energyRecoveryAuditFilterOppurtunityId
        : 0,
      customerName: energyRecoveryAuditFilterCustomerName
        ? energyRecoveryAuditFilterCustomerName
        : "",
      salesPhase: energyRecoveryAuditFilterSalesPhase
        ? salesPhaseDropDownData.filter(
            (data) => data.valueInt === energyRecoveryAuditFilterSalesPhase
          )[0].value
        : "",
      isTotalQuotedValue: getIsTotalQuotedValue(
        energyRecoveryAuditActualSavingValueInt
      ),
    };

    request({
      url: RequestApi.GET_ENERGY_RECOVERY_AUDIT_GENERATED_OPPORTUNITIES,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setEnergyRecoveryAuditData(resp.data.data);
        setPageSpecificData({
          opportunityStausUpdatedOn: resp.data.opportunityStausUpdatedOn,
        });
        setEnergyRecoveryAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", "Request Failed", true);
      });
  }, [
    energyRecoveryAuditPage,
    energyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    energyRecoveryAuditFilterAuditId,
    energyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterOppurtunityId,
    energyRecoveryAuditActualSavingValueInt,
    energyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterSalesPhase,
  ]);

  useEffect(() => {
    let actualSavings: ActualSavings;

    actualSavings =
      energyRecoveryAuditActualSavingValueInt == 1
        ? ActualSavings.All
        : energyRecoveryAuditActualSavingValueInt == 2
        ? ActualSavings.QuotedValue
        : ActualSavings.ExpectedValue;

    const airColumns: TableColumn[] = [
      {
        id: "opportunityId",
        label: " Opportunity ID",
        align: "left",
        minWidth: 180,
        filterType: "text",
        filter: true,
        sorting: true,
      },
      {
        id: "status",
        label: "Status",
        align: "left",
        minWidth: 140,
        filterType: "dropdown",
        filter: true,
        dropdownData: statusDropDownData,
      },
      {
        id: "salesPhase",
        label: "Sales Phase",
        align: "left",
        minWidth: 220,
        filterType: "dropdown",
        filter: true,
        dropdownData: salesPhaseDropDownData,
      },
      {
        id: "customerName",
        label: "Customer Name",
        align: "left",
        minWidth: 380,
        filter: true,
        filterType: "text",
        sorting: true,
      },

      {
        id: "actualSavings",
        label: actualSavings,
        minWidth: 180,
        align: "left",
        filterType: "dropdown",
        filter: true,
        dropdownData: actualSavingsDropDownData,
      },
      {
        id: "auditNumber",
        label: "Project ID",
        align: "left",
        minWidth: 200,
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "leadId",
        label: "Lead ID",
        minWidth: 200,
        align: "left",
        filter: true,
        filterType: "text",
        sorting: true,
      },
    ];

    setSalesAirColumns(airColumns);
  }, [energyRecoveryAuditActualSavingValueInt]);

  useEffect(() => {
    loadEnergyRecoveryAuditData();
  }, []);

  useEffect(() => {
    if (getEnergyRecoveryData == true) {
      loadEnergyRecoveryAuditData();
    }
    return () => {
      setGetEnergyRecoveryData(false);
    };
  }, [loadEnergyRecoveryAuditData, getEnergyRecoveryData]);

  return (
    <CustomTable
      isInsideTab={true}
      data={energyRecoveryAuditData}
      filterChange={handleEnergyRecoveryFilterChange}
      columns={salesAirColumns}
      stickyActionColumn={true}
      clearAll={clearAllEnergyRecoveryAuditSorting}
      achievedSales={true}
      setPage={setEnergyRecoveryAuditPage}
      page={energyRecoveryAuditPage}
      actionColumnWidth={"155px"}
      loadDataFunction={loadEnergyRecoveryAuditData}
      totalRecords={energyRecoveryAuditTotalRecords}
      filterObject={energyRecoveryAuditfilterObject}
      sortColumn={energyRecoveryAuditSortingColumn}
      setSortColumn={setEnergyRecoveryAuditSortingColumn}
      sortColumnOrder={energyRecoveryAuditSortingOrder}
      setSortColumnOrder={setEnergyRecoveryAuditSortingOrder}
      setGetData={setGetEnergyRecoveryData}
      pageSpecificData={pageSpecificData}
      actionColumnNotNeeded={true}
    />
  );
}
