export enum PageConstants {
  NewProject = "New Project",
  Dashboard = "Dashboard",
  GeneratedLeads = "GeneratedLeads",
  GeneratedOpportunities = "GeneratedOpportunities",
  UserManagement = "UserManagement",
  FinishedReports = "FinishedProject",
  LinkProject = "LinkProject",
  UploadProjectFiles = "UploadProjectFiles",
  Information = "Information",
  OngoingProject = "Ongoing Project",
  AddCustomer = "Add Customer",
  AssignEnergySpecialist = "Assign Energy Specialist",
}
