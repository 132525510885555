import DropdownModel from "./DropdownModel";

export enum DatePickerMode {
  CompleteDate = "completeDate",
  MonthYear = "monthYear",
}

export interface TableColumn {
  id: string | number;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
  filter: true | false;
  filterType?: "text" | "dropdown" | "datepicker";
  dropdownData?: DropdownModel[];
  sortOrder?: any;
  sorting?: true | false;
  datePickerMode?: DatePickerMode;
  htmlElement?: (value?: string, row? : any) => any;
}

// export default TableColumn;

export interface TableActionButtons {
  text?: string;
  icon?: any;
  handleClick: any;
  name: string;
  permission?: any;
  textInsideButton?: any;
}
