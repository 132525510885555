// CustomInput.tsx
import React, { KeyboardEvent } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Grid,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

type CustomInputProps = TextFieldProps & {
  name: string;
  label: string;
  md?: number;
  isRequired: true | false;
  numberOnly?: true | false;
};

const CustomInput: React.FC<CustomInputProps> = ({
  name,
  label,
  md = 4,
  isRequired,
  numberOnly,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    // <Grid item xs={12} sm={12} md={md} xl={md - 2} lg={md - 2} direction="row">
    <Grid item xs={12} md={md} xl={md} direction="row">
      <FormControl fullWidth>
        <FormLabel className="labels">
          {label}
          {isRequired && (
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          )}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextField
              {...props}
              {...field}
              error={!!errors[name]}
              autoComplete="off"
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                if (numberOnly) {
                  const isNumeric = /^[0-9]*$/.test(event.key);
                    const isCtrlCmd = event.ctrlKey || event.metaKey;
                if (
                    (!isNumeric &&
                        event.key !== "Tab" &&
                        event.key !== "." &&
                        event.key !== "Backspace" &&
                        !(isCtrlCmd && (event.key === "c" || event.key === "v"))) ||
                    (event.key === "." &&
                        (event.target as HTMLInputElement).value.includes("."))
                ) 
                {
                    event.preventDefault();
                }
                } else {
                  return;
                }
              }}
            />
          )}
        />
        <Typography
          variant="inherit"
          align="left"
          color="red"
          fontSize={"12px"}
        >
          {errors[name] && errors[name]?.type === "optionality"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "min"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "max"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "required"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "typeError"
            ? "Only numbers are allowed"
            : undefined}
          {errors[name] && errors[name]?.type === "matches"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "server"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "length"
            ? (errors[name]?.message as string)
            : undefined}
        </Typography>
      </FormControl>
    </Grid>
  );
};

export default CustomInput;
