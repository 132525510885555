import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller, UseFormSetValue, useFormContext } from "react-hook-form";
import { Country } from "../../../services/countries";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { CSSProperties } from "@mui/material/styles/createMixins";

type CountriesAutoCompleteProps = {
  setValue: UseFormSetValue<any>;
  data: Country[];
  isGlobalAdminSelected?: boolean;
};

export default function CountriesAutoComplete({
  setValue,
  data,
}: CountriesAutoCompleteProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
    
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Country | null
  ) => {
          if (value === null) {
          setValue("country", "");
          } else {
          setValue("country", value.countryName);
        }
  };

  return (
    <Grid
      item
      xs={12}
      md={4}
      xl={4}
      direction="row"
    >
      <FormControl
        variant="outlined"
        fullWidth
      >
        <FormLabel className="labels">
          Country
          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </FormLabel>
        <Controller
          name="country"
          control={control}
          // rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Autocomplete
              id="country-select"
              {...field}
              value={field.value}
              options={data ? data : []}
              autoHighlight
              sx={{
                "&.MuiAutocomplete-root>.MuiFormControl-root>.MuiInputBase-root":
                  { padding: "unset" },
              }}
              onChange={handleChange}
              getOptionLabel={(option: Country) => option.countryName}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Select country"
                />
              )}
            />
          )}
        />

        <Typography
          variant="inherit"
          align="left"
          fontSize={"12px"}
          color="red"
        >
            {errors["country"]
            ? (errors["country"]?.message as string)
            : undefined}
        </Typography>
      </FormControl>
    </Grid>
  );
}
export function CountriesMultipleAutoComplete({
  setValue,
  data,
  isGlobalAdminSelected,
}: CountriesAutoCompleteProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (event: any, values: Country[]) => {
    setValue(
      "country",
      values.map((value) => value.id)
    );
    // methods.trigger("country");
  };

  const autoCompleteStyle: CSSProperties = {
    "& .MuiInputBase-root": {
      paddingBlock: "unset",
      maxHeight: "150px",
      overflow: "auto",
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: "1px solid #0078a1",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      border: "none !important",
    },
  };

  return (
    <Grid
      item
      xs={12}
      md={4}
      xl={4}
      direction="row"
    >
      <FormControl
        variant="outlined"
        fullWidth
      >
        <FormLabel className="labels">
          Country
          <span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </FormLabel>
        <Controller
          name="country"
          control={control}
          // rules={{ required: 'This field is required' }}
          render={({ field }) => {
            function handlaClick(event: any): void {
              const id = Number(event.target.id);
              if (!field.value || field.value === undefined) {
                setValue("country", [id]);
              } else if (field.value?.includes(id)) {
                setValue(
                  "country",
                  field.value?.filter((value: any) => value !== id)
                );
              } else {
                setValue("country", [...field.value, id]);
              }
            }

            return (
              <Autocomplete
                disabled={isGlobalAdminSelected}
                id="country-select"
                {...field}
                options={data ? data : []}
                multiple
                MuiOutlinedInput-notchedOutline
                sx={autoCompleteStyle}
                disableCloseOnSelect
                autoHighlight
                onChange={handleChange}
                getOptionLabel={(option: Country) => option.countryName}
                value={
                  isGlobalAdminSelected
                    ? []
                    : data?.filter((country) =>
                        field.value?.includes(country.id)
                      )
                }
                renderOption={(props, option: Country) => (
                  <FormControlLabel
                    sx={
                      field.value?.includes(option.id)
                        ? {
                            bgcolor: "rgba(25, 118, 210, 0.08)",
                            paddingInline: "8px",
                          }
                        : { paddingInline: "8px" }
                    }
                    control={
                      <Checkbox
                        checked={field.value?.includes(option.id)}
                        onChange={handlaClick}
                        id={option.id.toString()}
                      />
                    }
                    label={option.countryName}
                  />
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={
                      isGlobalAdminSelected ? "All Countries" : "Select country"
                    }
                  />
                )}
              />
            );
          }}
        />

        <Typography
          variant="inherit"
          align="left"
          fontSize={"12px"}
          color="red"
        >
          {errors["country"]
            ? (errors["country"]?.message as string)
            : undefined}
        </Typography>
      </FormControl>
    </Grid>
  );
}
