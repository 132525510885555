import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../common/components/CustomComponents/CustomTable";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../common/interface/TableColumn";
import userEditIcon from "../assets/icons/edit_user.svg";
import addUserIcon from "../assets/icons/user_management_white.svg";
import classes from "./FinishedReports/FinishedReports.module.css";
import { Button } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import { useRequest } from "../services/agent";
import { RequestApi } from "../common/Constant";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import dayjs from "dayjs";
import DropdownModel from "../common/interface/DropdownModel";
import { PageConstants } from "../common/PageConstants";
import { OperationPermissions } from "../common/models/operationPermission";
import PermissionService from "../services/PermissionService";

interface IUserData {
  userName: string;
  email: string;
  phoneNumber: string;
  role: string;
  famCode: string;
  country: string;
  active: boolean;
  lastLoginOn: string;
}

export default function UserManagement() {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const request = useRequest();
  const userManagementList = useLoaderData() as any;

  const [usersData, setUsersData] = useState<IUserData[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortingColumn, setSortingColumn] = useState<string>("");
  const [sortingOrder, setSortingOrder] = useState<string>("asc");
  const [getData, setGetData] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [role, setRole] = useState<number>(0);
  const [famCode, setFAMCode] = useState<number>(0);
  const [country, setCountry] = useState<string>("");
  const [isActive, setActive] = useState<number>(1);
  const [lastLoginOn, setLastLoginOn] = useState<string>("");
  const [lastLoginLocal, setLastLoginLocal] = useState<string>("");

  const statusValue = useMemo(() => {
    let values = [null, true, false];
    return values[isActive - 1];
  }, [isActive]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const UserPermission: OperationPermissions = PermissionService(
    PageConstants.UserManagement
  );

  const filterObject: any = useMemo(
    () => ({
      userName,
      email,
      phoneNumber,
      role,
      famCode,
      country,
      isActive,
      lastLoginOn: dayjs(lastLoginLocal).isValid() ? dayjs(lastLoginLocal) : "",
    }),
    [
      userName,
      email,
      phoneNumber,
      role,
      famCode,
      country,
      isActive,
      lastLoginLocal,
    ]
  );

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const navigateToCreateUser = () => {
    navigate("/user-management/new-user");
  };
  const navigateToEditUser = (userId: number) => {
    navigate(`/user-management/edit-user/${userId}`);
  };

  const activeStatusData: DropdownModel[] = [
    {
      text: "All",
      value: "all",
      valueInt: 1,
    },
    {
      text: "Active",
      value: "active",
      valueInt: 2,
    },
    {
      text: "Inactive",
      value: "inActive",
      valueInt: 3,
    },
  ];

  const columns: TableColumn[] = [
    {
      id: "userName",
      label: "User Name",
      align: "left",
      minWidth: 150,
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "email",
      label: "Email",
      align: "left",
      minWidth: 160,
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      minWidth: 120,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "role",
      label: "Role",
      minWidth: 120,
      align: "left",
      filter: true,
      filterType: "dropdown",
      dropdownData: userManagementList.rolesList,
    },
    {
      id: "famCode",
      label: "FAM Code",
      minWidth: 150,
      align: "left",
      filter: true,
      filterType: "dropdown",
      dropdownData: userManagementList.famCodesList,
    },
    {
      id: "country",
      label: "Country",
      minWidth: 130,
      align: "left",
      filter: true,
      filterType: "text",
      sorting: true,
    },
    {
      id: "isActive",
      label: "Status",
      minWidth: 100,
      align: "left",
      filter: true,
      filterType: "dropdown",
      dropdownData: activeStatusData,
    },
    {
      id: "lastLoginOn",
      label: "Last Logon Date",
      minWidth: 155,
      align: "left",
      filter: true,
      filterType: "datepicker",
      datePickerMode: DatePickerMode.CompleteDate,
      sorting: true,
    },
  ];

  const actionButtons: TableActionButtons[] = [
    {
      name: "Edit User",
      icon: userEditIcon,
      handleClick: navigateToEditUser,
      permission: UserPermission.update,
    },
  ];

  const loadData = useCallback((): void => {
    let body = {
      page: page,
      pageSize: 20,
      sort: sortingColumn,
      order: sortingOrder,
      userName: userName ? userName : "",
      email: email ? email : "",
      phoneNumber: phoneNumber ? phoneNumber : "",
      role: role ? role : null,
      famCode: famCode ? famCode : null,
      country: country ? country : "",
      isActive: statusValue,
      lastLogin: lastLoginOn
        ? lastLoginOn === "clear"
          ? ""
          : lastLoginOn
        : "",
    };

    request({
      url: RequestApi.GET_USER_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setUsersData(resp.data.data);
        setTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
      });
  }, [
    role,
    page,
    sortingColumn,
    sortingOrder,
    userName,
    email,
    phoneNumber,
    famCode,
    country,
    statusValue,
    lastLoginOn,
    request,
  ]);

  const handleFilterChange = (coulmn: any, event: any) => {
    switch (coulmn) {
      case "userName":
        setUserName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phoneNumber":
        setPhoneNumber(event.target.value);
        break;
      case "role":
        console.log(event.target.value);
        setRole(event.target.value);
        break;
      case "famCode":
        setFAMCode(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "isActive":
        setActive(event.target.value);
        break;
      case "lastLoginOn":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setLastLoginOn(dayjs(event.$d).format("DD/MM/YYYY"));
          setLastLoginLocal(event.$d);
        } else {
          setLastLoginOn("");
          setLastLoginLocal("");
        }
        break;
    }
  };

  const clearAll = useCallback(() => {
    setPage(0);
    setUserName("");
    setEmail("");
    setPhoneNumber("");
    setRole(0);
    setFAMCode(0);
    setCountry("");
    setActive(0);
    setSortingOrder("asc");
    setSortingColumn("");
    setLastLoginLocal("");
    setLastLoginOn("");
    setGetData(true);
  }, []);

  useEffect(() => {
    setGetData(true);
  }, []);

  useEffect(() => {
    if (getData) {
      loadData();
    }

    return () => {
      setGetData(false);
    };
  }, [getData, loadData]);

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />

      <Box
        sx={{
          boxShadow: 0,
          paddingBlock: "10px 12px",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
        px={2}
        mb={1}
      >
        <div className={classes["page-title-container"]}>
          <div className={classes["page-title"]}>User Management</div>
          <Button
            variant="contained"
            disableRipple
            disabled={!UserPermission.create}
            size="small"
            sx={{
              fontSize: "1.125rem",
              lineHeight: "1.25rem",
              width: "8.8125rem",
              justifySelf: "flex-end",
            }}
            startIcon={
              <img
                src={addUserIcon}
                alt="add-user-icon"
                width="20px"
                height="20px"
                loading="lazy"
                decoding="async"
              />
            }
            onClick={navigateToCreateUser}
            className="custom-buttons"
          >
            New User
          </Button>
        </div>
        </Box>
        <div style={{ backgroundColor: "#fff", borderRadius: "8px" }}>
        <CustomTable
        isInsideTab={true}
        data={usersData}
        setPage={setPage}
        page={page}
        actionButtons={actionButtons}
        actionColumnWidth={"60px"}
        stickyActionColumn={true}
        filterChange={handleFilterChange}
        columns={columns}
        loadDataFunction={loadData}
        totalRecords={totalRecords}
        filterObject={filterObject}
        clearAll={clearAll}
        userManagementPage
        sortColumn={sortingColumn}
        setSortColumn={setSortingColumn}
        sortColumnOrder={sortingOrder}
        setSortColumnOrder={setSortingOrder}
        setGetData={setGetData}
        pageName={PageConstants.UserManagement}
              />
          </div>
    </>
  );
}
