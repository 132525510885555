// // CustomAlert.tsx
// import React from 'react';
// import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
// // import { Close as CloseIcon } from '@mui/icons-material';

// interface CustomAlertProps {
//   message: string;
//   severity: 'success' | 'error' | 'info' | 'warning';
//   onClose: () => void;
// }

// const CustomAlert: React.FC<CustomAlertProps> = ({ message, severity, onClose }) => {
//   return (
//     <Snackbar open autoHideDuration={6000} onClose={onClose}>
//       <SnackbarContent
//         message={message}
//         action={
//           <IconButton size="small" color="inherit" onClick={onClose}>
//             {/* <CloseIcon fontSize="small" /> */}
//           </IconButton>
//         }
//         style={{ backgroundColor: getColor(severity) }}
//       />
//     </Snackbar>
//   );
// };

// const getColor = (severity: string) => {
//   switch (severity) {
//     case 'success':
//       return '#4CAF50';
//     case 'error':
//       return '#F44336';
//     case 'info':
//       return '#2196F3';
//     case 'warning':
//       return '#FFC107';
//     default:
//       return '#333';
//   }
// };

// export default CustomAlert;

import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from '@mui/icons-material/Close';

interface CustomAlertProps {
  open: boolean;
  message: string;
  severity: "success" | "warning" | "info" | "error";
  onClose: () => void;
  hideTime?: number;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  open,
  message,
  severity,
  onClose,
  hideTime,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={hideTime ? hideTime : 2000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={severity} variant="filled" onClose={onClose}>
        {/* <AlertTitle>Alert</AlertTitle> */}
        {message}
        <IconButton
          size="small"
          aria-label="close"
          // color="inherit"
          onClick={onClose}
          sx={{ marginLeft: "auto" }}
        ></IconButton>
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
