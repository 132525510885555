import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { Box, Button, Grid } from "@mui/material";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import classes from "./AddRecommendations.module.css";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import DropdownModel from "../common/interface/DropdownModel";
import CustomDatePicker from "../common/components/CustomComponents/CustumDatePicker";
import { useEffect, useMemo, useState } from "react";
import { RequestApi } from "../common/Constant";
import {
  addRecommendations,
  AuditRecommendations,
  updateRecommendations,
} from "../common/store/addRecommendationsDataSlice";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import React from "react";
import { useAppDispatch } from "../common/hooks/hooks";
import dayjs from "dayjs";
import { CustomConfirmationDialogProps } from "../common/components/CustomComponents/ConfirmationDialog";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { fdatasync } from "fs";
import { useRequest } from "../services/agent";

interface Props {
  CloseDailog: () => any;
  recommendationToEdit?: AuditRecommendations;
  isEdit: boolean;
  fromOpportunity: boolean;
  auditRecommendationId?: number;
  setOpenSuccessDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  currency?: DropdownModel[];
  auditId?: number;
  isEditApiCall?: boolean;
  setIsApiSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  hideFields?: any;
}

export interface RecommendationDropdownModel {
  value: string;
  text: string;
  valueInt: number;
  investmentId: number;
}

export interface RecommendationsSelectList {
  typeOfInvestment: DropdownModel[];
  typeOfRecommendation: RecommendationDropdownModel[];
  energySources: DropdownModel[];
  energySourceUnits: DropdownModel[];
  currency: DropdownModel[];
  co2SourceFactors: DropdownModel[];
  co2Units: DropdownModel[];
}

const AddRecommendations = ({
  CloseDailog,
  recommendationToEdit,
  isEdit,
  fromOpportunity,
  auditRecommendationId,
  setOpenSuccessDialog,
  currency,
  auditId,
  isEditApiCall,
  setIsApiSuccess,
  hideFields,
}: Props) => {
  const [selectList, setSelectList] = useState<RecommendationsSelectList>();
  const [isEnergyRecovery, setIsEnergyRecovery] = useState(false);
  const [isElectricity, setIsElectricity] = useState(false);
  const dispatch = useAppDispatch();
  const request = useRequest();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const currencyText = useMemo(() => {
    if (currency) {
      return currency[0]?.text;
    } else {
      return selectList?.currency[0]?.text;
    }
  }, [currency, selectList]);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    let url = RequestApi.GET_RECOMMENDATIONS_SELECT_LIST;
    if (auditId) {
      url += `?auditId=${auditId}`;
    }
    request(url)
      .then((resp) => {
        setSelectList(resp.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const validationSchema = Yup.object().shape({
    investmentId: Yup.number().min(1).required("This field is required."),
    recommendationId: Yup.number()
      .min(1, "This field is required.")
      .required("This field is required."),
    description: Yup.string()
      .required("This field is required.")
      .max(200, "Maximum 200 Characters are allowed")
      .min(2, "Must include minumum 2 characters"),
    aproxInvestment: Yup.string(),
    minimumEstimatedSaving: Yup.string().required("This field is required."),
    paybackMonths: Yup.string(),
    followUpDate: Yup.string().nullable(),
    energySourceId: isEnergyRecovery
      ? Yup.number().required("This field is required.")
      : Yup.number().nullable(),
    energyCost:
      isEnergyRecovery && !isElectricity
        ? Yup.number().required("This field is required.")
        : Yup.number().nullable(),
    energySourceUnitId:
      isEnergyRecovery && !isElectricity
        ? Yup.number().required("This field is required.")
        : Yup.number().nullable(),
    comments: Yup.string()
      .max(500, "Maximum 500 Characters are allowed")
      .nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: {
      description: "",
      aproxInvestment: undefined,
      comments: undefined,
    },
  });

  useEffect(() => {
    if (recommendationToEdit && isEdit) {
      methods.setValue("description", recommendationToEdit?.description);
      methods.setValue(
        "aproxInvestment",
        recommendationToEdit?.aproxInvestment?.toString()
      );
      methods.setValue("investmentId", recommendationToEdit?.investmentId);
      methods.setValue(
        "minimumEstimatedSaving",
        recommendationToEdit?.minimumEstimatedSaving.toString()
      );
      methods.setValue(
        "paybackMonths",
        recommendationToEdit?.paybackMonths?.toString()
      );
      methods.setValue(
        "recommendationId",
        recommendationToEdit?.recommendationId
      );
      methods.setValue("followUpDate", recommendationToEdit?.followUpDate);
      if (recommendationToEdit?.investmentId === 7) {
        methods.setValue(
          "energySourceId",
          recommendationToEdit?.energySourceId
        );
        methods.setValue(
          "energySourceUnitId",
          recommendationToEdit?.energySourceUnitId
        );
        methods.setValue("energyCost", recommendationToEdit?.energyCost);
      }
      methods.setValue("comments", recommendationToEdit?.comments);
    }
  }, []);

  function onSubmit(data: any) {
    // display form data on success
    console.log(data);
    let selectedInvestmentName = selectList?.typeOfInvestment.find(
      (e) => e.valueInt === data.investmentId
    );

    let selectedRecommendationName = selectList?.typeOfRecommendation.find(
      (e) => e.valueInt === data.recommendationId
    );

    let rData: AuditRecommendations = {
      rowNo: recommendationToEdit?.rowNo ? recommendationToEdit.rowNo : 0,
      id: recommendationToEdit?.id ? recommendationToEdit.id : 0,
      investmentId: data.investmentId,
      investmentName: selectedInvestmentName ? selectedInvestmentName.text : "",
      recommendationId: data.recommendationId,
      recommendationName: selectedRecommendationName
        ? selectedRecommendationName.text
        : "",
      description: data.description,
      aproxInvestment: data.aproxInvestment ? data.aproxInvestment : null,
      minimumEstimatedSaving: data.minimumEstimatedSaving
        ? data.minimumEstimatedSaving
        : 0,
      paybackMonths: Number(data.paybackMonths)
        ? Number(data.paybackMonths)
        : null,
      followUpDate:
        data.followUpDate == null || data.followUpDate == ""
          ? null
          : dayjs(data.followUpDate).format("YYYY-MM-DD"),
      sentToC4C: false,
      energySourceId: data.energySourceId,
      energyCost:
        data.energyCost === 0 ? 0 : data.energyCost ? data.energyCost : null,
      energySourceUnitId: data.energySourceUnitId
        ? data.energySourceUnitId
        : null,
      sourceCO2FactorId: data.sourceCO2FactorId ? data.sourceCO2FactorId : null,
      cO2FactorUnitId: data.cO2FactorUnitId ? data.cO2FactorUnitId : null,
      comments: data?.comments,
    };

    if (fromOpportunity) {
      if (auditRecommendationId) {
        let url = RequestApi.ADD_OPPORTUNITY_RECOMMENDATION;
        if (isEdit) {
          url = RequestApi.UPDATE_OPPORTUNITY_RECOMMENDATION;
        }
        request({
          url: url,
          method: "post",
          data: {
            ...rData,
            auditRecommendationId,
            currencyId: selectList?.currency[0]?.valueInt,
            fromOpportunity: isEdit
              ? recommendationToEdit?.fromOpportunity
              : true,
          },
        })
          .then(() => {
            if (setOpenSuccessDialog) {
              setOpenSuccessDialog(true);
              CloseDailog();
            }
          })
          .catch((err) => {
            setAlert("error", "Request Failed", true);
            console.log(err);
          });
      }
    } else {
      if (isEdit) {
        if (isEditApiCall) {
          request({
            url: RequestApi.UPDATE_AUDIT_RECOMMENDATION,
            method: "post",
            data: {
              ...rData,
            },
          })
            .then(() => {
              if (setOpenSuccessDialog) {
                setIsApiSuccess && setIsApiSuccess(true);
                CloseDailog();
                setOpenSuccessDialog(true);
              }
            })
            .catch((err) => {
              setAlert("error", "Request Failed", true);
              console.log(err);
            });
        } else {
          dispatch(updateRecommendations(rData));
        }
      } else {
        dispatch(addRecommendations(rData));
      }
      CloseDailog();
    }
  }

  function onCloseCancelSave() {
    dispatch(hideLoader());
  }

  function onConfirmCancelSave() {
    dispatch(hideLoader());
    CloseDailog();
  }

  const handleClickOpen = (event: any) => {
    debugger;
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatch(showConfirmation(customConfirmationDialogProps));
    dispatch(showLoader());
  };
  const investmentId = methods.watch("investmentId");
  const energySourceId = methods.watch("energySourceId");

  const selectedRecommendation = useMemo(() => {
    let investmentId = methods.getValues("investmentId");
    let filteredData = selectList?.typeOfRecommendation.filter(
      (recommendation) => recommendation.investmentId == investmentId
    );

    if (investmentId == 7) {
      setIsEnergyRecovery(true);
    } else {
      setIsEnergyRecovery(false);
    }
    if (energySourceId == 3) {
      setIsElectricity(true);
    } else {
      setIsElectricity(false);
    }

    return filteredData;
  }, [
    selectList,
    methods.getValues("investmentId"),
    methods.getValues("energySourceId"),
  ]);

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />

      <Box
        sx={{ boxShadow: 0, borderBottom: 0 }}
        minWidth={"600px"}
        px={2}
        py={1}
        className={classes["form-container"]}
      >
        <FormProvider {...methods}>
          <form
            className={classes["add-recommendation-form"]}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <Grid item xs={12} md={12} xl={12}>
                <div className={classes["dialog-info"]}>
                  Please add recommendations. It will be valuable in guiding
                  someone towards a positive experience or outcome. Share your
                  thoughts & experiences. Providing helpful and accurate
                  recommendations can build trust between customers.
                </div>
              </Grid>
              <CustomSelect
                isRequired={true}
                md={6}
                name="investmentId"
                setValue={methods.setValue}
                label="Category of Product"
                options={
                  selectList
                    ? (selectList.typeOfInvestment as DropdownModel[])
                    : ([] as DropdownModel[])
                }
              />

              <CustomSelect
                md={6}
                isRequired={true}
                name="recommendationId"
                setValue={methods.setValue}
                label="Type of Product"
                options={
                  selectedRecommendation
                    ? selectedRecommendation
                    : ([] as RecommendationDropdownModel[])
                }
              />
              <CustomInput
                md={6}
                isRequired={true}
                label="Description of Product"
                name="description"
                variant="outlined"
                fullWidth
              />
              {!hideFields?.aproxInvestment && (
                <CustomInput
                  md={6}
                  isRequired={false}
                  label={
                    fromOpportunity
                      ? `Approx. Investment (${currencyText})`
                      : `Approx. Investment (${currencyText})`
                  }
                  name="aproxInvestment"
                  variant="outlined"
                  numberOnly={true}
                  fullWidth
                />
              )}
              <CustomInput
                md={6}
                isRequired={true}
                label="Minimum Estimated Savings(kWh/annum)"
                name="minimumEstimatedSaving"
                variant="outlined"
                numberOnly={true}
                fullWidth
              />
            </Grid>
            {isEnergyRecovery && (
              <Grid
                className="grid-container"
                container
                marginBottom={4}
                spacing={2}
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <CustomSelect
                  md={6}
                  isRequired={true}
                  name="energySourceId"
                  setValue={methods.setValue}
                  label="Energy source replaced by ER"
                  options={selectList ? selectList.energySources : []}
                />
                {!isElectricity && (
                  <>
                    <CustomSelect
                      md={6}
                      isRequired={true}
                      name="energySourceUnitId"
                      setValue={methods.setValue}
                      label="Energy Source Unit"
                      options={selectList ? selectList.energySourceUnits : []}
                    />
                    <CustomInput
                      md={6}
                      isRequired={true}
                      label={`Amount per year (${currencyText})`}
                      name="energyCost"
                      variant="outlined"
                      numberOnly={true}
                      fullWidth
                    />
                  </>
                )}
              </Grid>
            )}

            <CustomInput
              md={12}
              isRequired={false}
              label="Comments"
              name="comments"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={{
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  outline: "none !important",
                  border: "none !important",

                  height: "100% !important",
                },
                "& .MuiInputBase-root": {
                  maxHeight: "100px",
                  padding: "0px !important",
                },
              }}
            />
            <p className={classes["maxlettertext"]}>
              Explain in 500 character max.
            </p>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
              mt={1}
            >
              <Button
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                variant="outlined"
                onClick={handleClickOpen}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                type="submit"
                className="custom-buttons"
                sx={{ textTransform: "none" }}
                variant="contained"
                disableRipple
                size="medium"
              >
                Save Recommendation
              </Button>
            </Grid>
            {/* here add text field */}
          </form>
        </FormProvider>
      </Box>
    </>
  );
};

export default AddRecommendations;
