import { Button, Grid } from "@mui/material";
import classes from "./CustomRange.module.css";
import LowIcon from "./../../../assets/icons/low_icon.svg";
import MediumIcon from "./../../../assets/icons/medium_icon.svg";
import CriticalIcon from "./../../../assets/icons/critical_icon.svg";
import MinusIcon from "./../../../assets/icons/minus_icon.svg";
import AdditionIcon from "./../../../assets/icons/addition_icon.svg";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
interface CustomRangeProps {
  name: string;
  label: string;
  max?: number;
  min: number;
  md?: number;
  type: "medium" | "low" | "critical";
}

const CustomRange: React.FC<CustomRangeProps> = ({
  name,
  label,
  max,
  min,
  md = 6,
  type,
}) => {
  const { register, trigger, setValue, getValues } = useFormContext();
  let [val, setVal] = useState(getValues(name) || 0);

  function handleIncrement() {
      if (typeof val !== "number") {
          val = parseInt(val);
      }
    const total = val + 1;
    if (max ? total >= min && total <= max : total >= min) {
      setVal(val + 1);
      setValue(name, val + 1);
      trigger(name);
    }
  }
  function handleDecrement() {
    if (max ? val - 1 >= min && val - 1 <= max : val - 1 >= min) {
      setVal(val - 1);
      setValue(name, val - 1);
      trigger(name);
    }
  }

  return (
    <>
      <Grid
        // className={classes["grid-top-padding"]}
        // paddingTop={"0px"}
        item
        xs={12}
        sm={12}
        md={md}
        xl={md - 2}
        lg={md - 2}
        direction="row"
      >
        <div className={classes.container}>
          <div
            className={`${classes.title} 
              ${
                type === "medium"
                  ? classes.medium
                  : type === "low"
                  ? classes.low
                  : type === "critical"
                  ? classes.critical
                  : undefined
              }`}
          >
            <img
              style={{ paddingRight: "5px" }}
              src={
                type === "medium"
                  ? MediumIcon
                  : type === "low"
                  ? LowIcon
                  : type === "critical"
                  ? CriticalIcon
                  : undefined
              }
              loading="lazy"
              decoding="async"
              alt={label}
            ></img>
            {"  "}
            {label}
          </div>
          <div className={classes.incrementor}>
            <div>
              <Button
                disableRipple
                style={{ minWidth: "100%" }}
                onClick={handleDecrement}
                color="secondary"
              >
                <img
                  src={MinusIcon}
                  height="24"
                  alt="Decrement"
                  loading="lazy"
                  decoding="async"
                ></img>
              </Button>
            </div>
            <div style={{ height: "100%" }}>
              <input
                className={classes["input-align"]}
                style={{ width: "100%" }}
                type="text"
                {...register(name)}
                value={val}
                name={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (isNaN(+event.target.value)) {
                    console.log(isNaN(+event.target.value));
                    return;
                  } else {
                    if (
                      +event.target.value >= min && max
                        ? +event.target.value <= max
                        : true
                    ) {
                      setVal(+event.target.value);
                      setValue(name, +event.target.value);
                      trigger(name);
                    } else {
                      return;
                    }
                  }
                }}
              ></input>
            </div>
            <div>
              <Button
                disableRipple
                style={{ minWidth: "100%" }}
                onClick={handleIncrement}
                color="secondary"
              >
                <img
                  src={AdditionIcon}
                  height="24"
                  alt="Increment"
                  loading="lazy"
                  decoding="async"
                ></img>
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default CustomRange;
