import { createSlice } from "@reduxjs/toolkit";

export interface Loader {
  show: boolean;
}

const initialState: Loader = {
  show: false,
};

const loaderDataSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    showLoader: (state) => {
      state.show = true;
    },
    hideLoader: (state) => {
      state.show = false;
    },
  },
});

export const { showLoader, hideLoader } = loaderDataSlice.actions;

export default loaderDataSlice;
