import * as React from "react";
import logo from "../../../../assets/images/ac_logo.svg";
import classes from "./Header.module.css";
import userIcon from "../../../../assets/icons/user-icon.svg";
import bellIcon from "../../../../assets/icons/bell-icon.svg";
import separatorIcon from "../../../../assets/icons/separator.svg";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import Axios from "axios";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../../RoutingConstants";
import { PageConstants } from "../../../PageConstants";

const Header = () => {
  const msal = useMsal();
  const imgFromSession = sessionStorage.getItem("imgUrl");
  const [imageUrl, setImageUrl] = useState(
    imgFromSession ? imgFromSession : ""
  );
  const [userName, setUserName] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const fontStyle = { textDecoration: "none", color: "#0078A1" };
  var decodedData = atob(sessionStorage.getItem("ud") || "");
  var data = decodedData ? JSON.parse(decodedData) : "";
  var pageName = window.location.pathname.replace(/^\/([^\/]*).*$/, "$1");

  function getPageName() {
    if (data?.navigationDetailList?.length > 0) {
      if (pageName === RoutingConstants.NewProject) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.NewProject
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.NewProject}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.OngoingProject) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.OngoingProject
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.OngoingProject}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.FinishedReports) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.FinishedReports
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.FinishedReports}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.GeneratedLeads) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.GeneratedLeads
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.GeneratedLeads}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.GeneratedOpportunities) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.GeneratedOpportunities
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.GeneratedOpportunities}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.UserManagement) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.UserManagement
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.UserManagement}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === RoutingConstants.Information) {
        const menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.Information
        ).menuName;
        return (
          <Link
            to={"/" + RoutingConstants.Information}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
      if (pageName === "") {
        let menuName = data.navigationDetailList.find(
          (item: { description: string }) =>
            item.description === PageConstants.Dashboard
        )?.menuName;
        if (!menuName)
          menuName = data.navigationDetailList.find(
            (item: { description: string }) =>
              item.description === PageConstants.NewProject
          ).menuName;
        return (
          <Link
            to={"/"}
            className={classes.title}
            style={fontStyle}
          >
            {menuName}
          </Link>
        );
      }
    }
  }
  const handleClose = (event: Event | React.SyntheticEvent, option: string) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    if (option === "logout") {
      try {
        navigate("/");
        sessionStorage.clear();
        msal.instance.logoutRedirect();
      } catch (e) {
        console.log("tokan  error:" + e);
      }
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const getUserRoleFromSession = React.useMemo(() => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data?.role?.value;
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

    const accessToken = sessionStorage.getItem("accessTokenP");
  useEffect(() => {
    const timeout = setTimeout(() => {
      Axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: { Authorization: accessToken },
        responseType: "blob",
      })
        .then((o) => {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(o.data);
          sessionStorage.setItem("imgUrl", blobUrl);
          setImageUrl(blobUrl);
        })
        .catch(() => "");
    }, 1000);

    if (msal.accounts != null && msal.accounts?.length > 0) {
      setUserName(msal.accounts[0].name ? msal.accounts[0].name : "");
    }

    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout);
    };
  }, [accessToken,msal.accounts]);

  // useEffect(() => {
  //   const tokenRequest = {
  //     loginHint: msal.instance.getAllAccounts()[0].username,
  //     scopes: ["User.Read"],
  //   };

  //   // const tokenRequest = {
  //   //   scopes: ['user.read'],
  //   //   loginHint: userName,
  //   // };
  //   debugger;
  //   let token = "";

  //   const getToken = async () => {
  //     token = (await msal.instance.acquireTokenSilent(tokenRequest))
  //       .accessToken;
  //   };

  //   getToken();
  //   console.log("token :" + token);
  //   Axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", {
  //     headers: { Authorization: token },
  //     responseType: "blob",
  //   }).then((o) => {
  //     const url = window.URL || window.webkitURL;
  //     const blobUrl = url.createObjectURL(o.data);
  //     setImageUrl(blobUrl);
  //   });
  // }, []);

  return (
    <div className={classes["header-container"]}>
      <div className={classes["image-container"]}>
        <img
          className={classes.logo}
          src={logo}
          alt="Atlas Copco"
        ></img>
        <span
          className={classes.title}
          style={{ display: "-webkit-box" }}
        >
          Optimization Platform / {getPageName()}
        </span>
      </div>
      <div className={classes.border}></div>

      <div className={classes["user-details-container"]}>
        <div>
          <img
            src={bellIcon}
            height="30"
            alt="Notifications"
          ></img>
        </div>
        <div>
          {/* <i className={classes.separator}></i> */}
          <img
            className={classes.separator}
            src={separatorIcon}
            height="50"
            alt=""
          ></img>
        </div>
        <div className={classes["user-info"]}>
          <div className={classes["avatar-circle"]}>
            <img
              className={classes["profile-image"]}
              src={imageUrl ? imageUrl : userIcon}
              height="50"
              alt="Profile"
            ></img>
          </div>
          <div className={classes["user-details"]}>
            <span className={classes["user-name"]}>{userName}</span>
            {/* <div className={classes["user-role"]}>Service Engineer</div> */}
            <div>
              <Button
                disableRipple
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                sx={{
                  textTransform: "none",
                  paddingRight: "2rem !important",
                }}
                onClick={handleToggle}
                className={classes["user-role"]}
              >
                {getUserRoleFromSession} &nbsp;{" "}
                <i className={classes["arrow-down"]}></i>
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 1 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper
                      sx={{
                        "& .MuiList-root": {
                          padding: "0px  !important",
                          width: "135px",
                        },
                      }}
                    >
                      <ClickAwayListener
                        onClickAway={(event) => handleClose(event, "")}
                      >
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          {/* <MenuItem onClick={ (event) => handleClose(event,'logout')}>Profile</MenuItem>
                          <MenuItem onClick={handleClose}>My account</MenuItem> */}
                          <MenuItem
                            onClick={(event) => handleClose(event, "logout")}
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UnAuthorizedHeader = () => {
  return (
    <div
      className={classes["header-container"]}
      style={{ margin: "unset" }}
    >
      <div className={classes["image-container"]}>
        <img
          className={classes.logo}
          src={logo}
          alt="Atlas Copco"
        ></img>
        <span className={classes.title}> Optimization Platform</span>
      </div>
      <div className={classes.border}></div>
    </div>
  );
};

export default Header;
