import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import TextBox from "../common/components/CustomComponents/Textbox";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import { Box, Grid, Select } from "@mui/material";
import { Fragment, useState } from "react";
import classes from "./UploadFinishedReports.module.css";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import { useParams } from "react-router-dom";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import CustomMultiSelect from "../common/components/CustomComponents/CustomMultiSelect";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import ConfirmationDialog from "../common/components/CustomComponents/ConfirmationDialog";
import DropdownModel from "../common/interface/DropdownModel";

const TestPage = () => {
  const { customerName } = useParams();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("First Name is required"),
    age: Yup.string().required("Age is required"),
    multiSelect: Yup.array()
      .of(Yup.string())
      .nullable()
      .required("Please select at least one item"),
    // multiSelect: Yup.array()
    //   .of(Yup.string().required("Name is required"))
    //   .required("required"),
    //.required("required"),
  });

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showAlert = () => {
    setAlertOpen(true);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmAction = () => {
    // Handle your confirmation action here.
    // For example, delete an item, submit a form, etc.
    // Then close the dialog.
    setAlertSeverity("error");
    setAlertMessage("Confirmed");
    setAlertOpen(true);
    handleCloseDialog();
  };

  // functions to build form returned by useForm() hook
  //   const {
  //     register,
  //     handleSubmit,
  //     reset,
  //     formState: { errors },
  //     control,
  //   } = useForm({
  //     resolver: yupResolver(validationSchema),
  //   });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const selectedAT = methods.watch("multiSelect");
  //   const {
  //     handleSubmit,
  //     watch,
  //     formState: { errors },
  //     control,
  //   } = useForm<Inputs>();

  function onSubmit(data: any) {
    // display form data on success
    alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
  }

  function updateDoctorProfile(e: any, values: any) {
    console.log(`e: ${e}`);
    console.log(`values: ${values}`);
  }

  function CheckError() {
    debugger;
    let a = methods.formState.errors;
  }

  const options = [
    { valueInt: 1, text: "Option 1" },
    { valueInt: 2, text: "Option 2" },
    { valueInt: 3, text: "Option 3" },
  ] as DropdownModel[];

  function handleChange(selectedValues: string[]) {
    methods.setValue("multiSelect", selectedValues);
  }

  return (
    <Fragment>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      {/* <ConfirmationDialog
        icon=""
        title="Confirmation"
        content="Are you sure you want to perform this action?"
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
      /> */}
      <Box px={2} py={1} mb={1}>
        <div className={classes["page-title"]}>
          <img
            src={BackArrowIcon}
            height="25"
            alt="Back"
            loading="lazy"
            decoding="async"
          ></img>
          Upload finished reports Audit vs Information - {selectedAT} First Name{" "}
          {methods.watch("firstName")}
          <div className={classes["customer-name"]}>{customerName}</div>
        </div>
      </Box>
      <Box px={2} py={1} className={classes["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <CustomInput
                isRequired={true}
                name="firstName"
                label="Text Field"
                variant="outlined"
                fullWidth
              />
              <CustomInput
                isRequired={true}
                name="title"
                label="Text Field"
                variant="outlined"
                fullWidth
              />
              <CustomSelect
                isRequired={true}
                setValue={methods.setValue}
                name="age"
                label="Select Field"
                options={options}
              />
              <CustomMultiSelect
                // {...methods.register("multiSelect")}
                name="multiSelect"
                label="Select Options"
                options={options}
              />
            </Grid>

            <div className="form-group">
              <button type="submit" className="btn btn-primary mr-1">
                Register {methods.watch("multiSelect")}
              </button>
              <button
                // onClick={showAlert}
                onClick={CheckError}
                className="btn btn-secondary"
                type="reset"
              >
                Reset
              </button>
            </div>
          </form>
        </FormProvider>
      </Box>
    </Fragment>
  );
};

export default TestPage;
