const Dashboard = () => {
  return (
      <div>
      <iframe
        style={{ height: "calc(100vh - 130px)", width: "100%", borderRadius: "8px" }}
        allowFullScreen={true}
        frameBorder={0}
        src="https://app.powerbi.com/reportEmbed?reportId=8402dddf-26cf-4fac-a36c-85124bee9ad6&autoAuth=true&ctid=556e6b1f-b49d-4278-8baf-db06eeefc8e9"
      ></iframe>
    </div>
  );
};

export default Dashboard;
