import { Box, CircularProgress, TableRow, TableCell, Typography } from "@mui/material";
import classes from './Loader.module.css'

export default function Loader() {
  return (
      <div className={classes["loading-indicator"]}>    
        <Box 
          display="flex"
          minWidth="100%"
          padding="2rem" 
          alignItems="center"
          bgcolor="#fff" 
          sx={{boxShadow: '0px 2px 10px 5px rgba(0,0,0,0.4)'}} 
          gap="1rem"
        >
          <CircularProgress sx={{ color: "#0099CC"}} />
          <Typography color="#0099CC" variant="h6">Please wait...</Typography>
        </Box>
      </div>
  )
};

