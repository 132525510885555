import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
// import Success from "./common/components/Layout/modal/Success";
// import UploadFinishedReports from "./features/UploadFinishedReports";
// import Axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './common/store/index';
import CustomConfirmationDialog from './common/components/CustomComponents/ConfirmationDialog';
import { userloginDto } from './common/models/userDetails';
import Router from './Router';
import axios from 'axios';
import { RequestApi } from './common/Constant';
import UnAuthorized from './features/UnAuthorized';

const theme = createTheme({
  typography: {
    fontFamily: `"Source Sans Pro", "helvetica", "arial", "sans-serif",
   "Material Icons"`,
  },
});

function App() {
  useMsalAuthentication(InteractionType.Redirect);
  const msal = useMsal();
  const [userDetails, setUserDetails] = useState<string>(
    msal.accounts[0]?.username || ''
  );
  const [authenticated, setAuthenicated] = useState<boolean>(
    Boolean(sessionStorage.getItem('ud'))
  );

  const Render = () => {
    try {
      const userName = msal.accounts[0].username;
      msal.instance.setActiveAccount(msal.accounts[0]);

      const login = async () => {
        await axios
          .post(RequestApi.USER_LOGIN, { email: userName })
          .then((res) => {
            sessionStorage.setItem('ud', btoa(JSON.stringify(res.data)));
            setAuthenicated(true);
          })
          .catch((err) => console.log(err));
      };
      if (!authenticated) {
        login();
      }
      const tokenRequest = {
        loginHint: msal.instance.getAllAccounts()[0].username,
        scopes: ['User.Read'],
      };
      const getProfileToken = async () => {
        const token = (await msal.instance.acquireTokenSilent(tokenRequest))
          .accessToken;
        sessionStorage.setItem('accessTokenP', token);
        // var dto = new userloginDto();
        // dto.EmailId = userName;
        // dto.Token = "Bearer " + token;
        // userLogin(dto);
      };
      const tokenADRequest = {
        loginHint: msal.instance.getAllAccounts()[0].username,
        scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/Read_Write`],
      };
      const getADToken = async () => {
        const token = (await msal.instance.acquireTokenSilent(tokenADRequest))
          .accessToken;
        sessionStorage.setItem('accessTokenAD', token);
      };
      setTimeout(() => {
        getProfileToken();
        getADToken();
        setUserDetails(userName);
      }, 3000);
    } catch (e) {
      console.log('tokan  error:' + e);
    }
  };

  const [successAlertIsShown, setSuccessAlertIsShown] = useState(true);

  const showSuccessAlertHandler = () => {
    setSuccessAlertIsShown(true);
  };

  const hideSuccessAlertHandler = () => {
    setSuccessAlertIsShown(false);
  };

  if (userDetails !== '') {
    return (
      <div>
        {/* {show && <Success onClose={hideSuccessAlertHandler} />} */}
        {authenticated ? (
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <CustomConfirmationDialog></CustomConfirmationDialog>
              <Router />
            </Provider>
          </ThemeProvider>
        ) : (
          <UnAuthorized />
        )}
      </div>
    );
  } else {
    return (
      <>
        {[Render()]} <div>Please wait....</div>{' '}
      </>
    );
  }
}

export default App;
