import * as Yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Dialog, Grid, SvgIcon } from "@mui/material";
import classes from "./UploadFinishedReports.module.css";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { RoutingConstants } from "../common/RoutingConstants";
import { useEffect, useMemo, useRef, useState } from "react";
import CustomUpload from "../common/components/CustomComponents/CustomUpload";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import VectorIcon from "./../assets/icons/Vector.svg";
import { ReactComponent as UploadReportIcon } from "./../assets/icons/upload_report.svg";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";
import DropdownModel from "../common/interface/DropdownModel";
import CustomInputSelect from "../common/components/CustomComponents/CustomInputSelect";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import { useAppDispatch } from "../common/hooks/hooks";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { useMsal } from "@azure/msal-react";
import { LinkProjectData } from "./LinkProject";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import ConfirmDelete from "../common/components/CustomComponents/ConfirmDelete";
import SavingsSaved from "./../assets/images/savings_saved.svg";
import State from "../common/interface/DragDrop";
import {
  addAllRecommendations,
  deleteAllRecommendations,
} from "../common/store/addRecommendationsDataSlice";
import AddRecommendationsdailog from "../common/components/CustomComponents/AddRecommendationsdailog";
import { useSelector } from "react-redux";
import { RootState } from "../common/store";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import {
  AuditAirScanTypes,
  ShowAuditTypesOnTabConstants,
} from "../common/AuditTypeConstants";
import { AddProductRecommendation } from "../common/components/CustomComponents/AddProductRecommendation";
import { ProjectSourceConstants } from "../common/ProjectSourceConstants";

interface IAuditType {
  auditGroup: string;
  subGroups: DropdownModel[];
}

interface IAuditClassification {
  auditGroup: string;
  classification: DropdownModel[];
}
export interface CustomConfirmationDialogProps {
  icon: string;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: (id?: number) => void;
  isConfirmForDelete?: boolean;
  id?: number;
}

const defaultIsSubCategory = { isAirQuality: false, isEnergyPotential: false };
export default function LinkProjectUpload() {
  const [selectedProject, setSelectedProject] = useState<string | undefined>();
  const [multiSelectOptions, setMultiSelectOptions] = useState<any[]>([]);
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);
  const selectList = useLoaderData() as any;
  const msal = useMsal();
  const airnetConstant = "Airnet";
  const dispatchCancelPopup = useAppDispatch();
  const request = useRequest();
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubCategory, setIsSubCategory] = useState(defaultIsSubCategory);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any[]>([]);
  const dispatchRecommendations = useAppDispatch();
  const params = useParams();
  const { auditId } = params;
  const [savedFiles, setSavedFiles] = useState([]);
  const isEditReportPage = auditId && !isNaN(+auditId) && +auditId > 0;
  const [famCode, setFamCode] = useState("");
  const [famCodeId, setFamCodeId] = useState(0);
  let recommendationData = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.recommendations
  );

  let AuditGroupTypes = useMemo(
    () =>
      selectList?.typeOfAuditList
        .filter((auditType: any) => {
          if (
            auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Vibration_Audit.toLowerCase()
          ) {
            return;
          }

          return auditType;
        })
        .map((auditType: any) => {
          if (
            auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase()
          ) {
            return {
              ...auditType,
              auditGroup: ShowAuditTypesOnTabConstants.Air_Scan,
            };
          }
          if (
            auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Airnet.toLocaleLowerCase()
          ) {
            return {
              ...auditType,
              auditGroup: ShowAuditTypesOnTabConstants.Airnet,
            };
          }
          return auditType;
        }) as IAuditType[],
    [selectList?.typeOfAuditList as IAuditType[]]
  );
  const AuditClassificationTypes = useMemo(
    () => selectList?.auditClassificationList as IAuditClassification[],
    [selectList?.auditClassificationList as IAuditClassification[]]
  );
  const currency = useMemo(
    () => selectList?.currencyList as DropdownModel[],
    [selectList?.currencyList as DropdownModel[]]
  )[0]?.text;

  const getCO2UnitList = useMemo(
    () => selectList?.cO2UnitList as DropdownModel[],
    [selectList?.cO2UnitList as DropdownModel[]]
  );

  const isAirScan = useMemo(() => {
    if (!selectedProject) return false;
    return (
      selectedProject?.toLocaleLowerCase().trim() ===
      ShowAuditTypesOnTabConstants.Air_Scan.toLocaleLowerCase().trim()
    );
  }, [selectedProject]);

  const isAirnet = useMemo(() => {
    if (!selectedProject) return false;
    return (
      selectedProject?.toLocaleLowerCase().trim() ===
      ShowAuditTypesOnTabConstants.Airnet.toLocaleLowerCase().trim()
    );
  }, [selectedProject]);

  const isEnergyRecovery = useMemo(() => {
    if (!selectedProject) return false;
    return (
      selectedProject?.toLocaleLowerCase().trim() ===
      ShowAuditTypesOnTabConstants.Enery_Recovery.toLocaleLowerCase().trim()
    );
  }, [selectedProject]);

  const c02UnitList: string[] = [];

  getCO2UnitList.forEach((item) => {
    if (item.text) {
      c02UnitList.push(item.text);
    }
  });

  const getEnergyUnitList = useMemo(
    () => selectList?.energyUnitList as DropdownModel[],
    [selectList?.energyUnitList as DropdownModel[]]
  );

  const eneryUnitList: string[] = [];

  // Iterate over the array and extract the "text" value
  getEnergyUnitList.forEach((item) => {
    if (item.text) {
      eneryUnitList.push(item.text + "/annum");
    }
  });

  let mappedData: DropdownModel[] = AuditGroupTypes?.map(
    (item: IAuditType, index) => {
      return { text: item.auditGroup, valueInt: index, value: "" };
    }
  );
  interface DeleteSuccessDailogProps {
    open: boolean;
    onClose: () => any;
  }
  function DeleteSuccessDailog(props: DeleteSuccessDailogProps) {
    const { onClose, open } = props;

    const close = () => {
      onClose();
    };

    return (
      <>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
          onClose={close}
          open={open}
        >
          <div className="iconimg" style={{ paddingTop: "1rem" }}>
            <img
              onClick={close}
              src={SavingsSaved}
              height="35"
              alt="saved-icon"
              loading="lazy"
              decoding="async"
            />
          </div>

          <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
            <ConfirmDelete
              message={
                isEditReportPage
                  ? `Project updated successfully!`
                  : `Project linked successfully!`
              }
              textCss="bold-message-report"
              CloseDeleteDailog={close}
              CloseRecommendationDailog={close}
              btnText="Okay"
              btnCss="cancel-button custom-buttons"
              hideCancel={false}
              variantCSS="outlined"
            ></ConfirmDelete>
          </div>
        </Dialog>
      </>
    );
  }

  useEffect(() => {
    dispatchRecommendations(deleteAllRecommendations());
    if (isEditReportPage) {
      LoadAuditDetailsById();
    }
  }, []);

  let classification = AuditClassificationTypes.find(
    (e) =>
      e.auditGroup.toLowerCase().trim() === airnetConstant.toLowerCase().trim()
  )?.classification;

  let ClassificationProject: DropdownModel[] =
    classification?.map((item: any) => {
      return { text: item?.text, valueInt: item.valueInt, value: "" };
    }) ?? [];

  const multiselectRef = useRef<any | undefined>();

  const resetSelection = () => {
    multiselectRef?.current?.resetSelectedValues();
  };
  const navigate = useNavigate();

  const linkProjectDetails: LinkProjectData = {
    customerName: window.sessionStorage.getItem("cn") || "",
    c4CId: window.sessionStorage.getItem("ci") || "",
    opportunityId: parseInt(window.sessionStorage.getItem("oid") || "0"),
    leadId: parseInt(window.sessionStorage.getItem("lid") || "0"),
    totalAmount: parseFloat(window.sessionStorage.getItem("tqv") || "0"),
    totalExpectedValue: parseFloat(window.sessionStorage.getItem("tev") || "0"),
    country: window.sessionStorage.getItem("con") || "",
    leadStatus: window.sessionStorage.getItem("ls") || "",
    opportunityStatus: window.sessionStorage.getItem("os") || "",
    city: window.sessionStorage.getItem("ct") || "",
    address: window.sessionStorage.getItem("ad") || "",
    soldTo: window.sessionStorage.getItem("st") || "",
    famCode: window.sessionStorage.getItem("fam") || "",
  };

  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  const IsOpportunity =
    linkProjectDetails.opportunityId > 0 ||
    (recommendationData[0]?.opportunityId ?? 0) > 0;

  const customInputSelections = [
    {
      function: handleMinSaveInputChange,
      label: "Minimum Estimated Savings",
      option: [getEnergyUnitList && getEnergyUnitList[0]?.text + "/annum"],
      name: "minimumEstimatedSaving",
      numberOnly: true,
      hide: isSubCategory.isAirQuality,
    },
    {
      function: () => {},
      label: "Source of Existing Energy",
      name: "energySourceId",
      hide: isAirnet || isAirScan || !selectedProject,
    },
    {
      function: handleCo2InputChange,
      label: "CO2 Factor",
      option: getCO2UnitList,
      name: "co2Factor",
      numberOnly: true,
      unitName: "co2Unit",
      hide: isSubCategory.isAirQuality,
    },
    {
      function: handleElectricCostInputChange,
      label: "Electricity Cost",
      option: [currency + "/kWh"],
      name: "electricityCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isSubCategory.isAirQuality || isEnergyRecovery,
    },
    {
      function: (data: any) => {
        console.log(data?.target.value);
        methods.setValue("energyCost", data.target.value);
      },
      label: "Energy Cost",
      option: [currency + "/kg"],
      name: "energyCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isAirScan || isAirnet,
    },
  ];
  const [openSuccess, setOpenSuccessDialog] = useState(false);
  const navigateToCustomer = () => {
    navigate("/" + RoutingConstants.NewProject);
  };

  const validationSchema = Yup.object().shape({
    categoryOfProject: Yup.string()
      .min(1, "This field is required")
      .required("This field is required"),
    auditName: Yup.string()
      .required("This field is required.")
      .max(80, "Maximum 80 Characters are allowed")
      .min(2, "Must include minumum 2 characters")
      .matches(/^[a-z\d\-_\s]+$/i, "Special Characters are not allowed"),
    uploadedFiles:
      savedFiles?.length === 0
        ? Yup.array().min(1, "This field is required")
        : Yup.array(),
    subCategory: isAirScan
      ? Yup.array().min(1, "This field is required")
      : Yup.array().notRequired(),
    subCategorySingleSelect:
      isAirnet || isEnergyRecovery
        ? Yup.number().required("This field is required")
        : Yup.number().nullable(),
    classificationOfProject: isAirnet
      ? Yup.number().required("This field is required")
      : Yup.number().nullable(),
    electricityCost:
      isAirScan && !isSubCategory.isAirQuality
        ? Yup.number()
            .min(0, "The value must be 0 or more.")
            .required("This field is required.")
        : Yup.string().nullable(),
    co2Factor:
      isAirScan && !isSubCategory.isAirQuality
        ? Yup.string().required("This field is required")
        : Yup.string().nullable(),
    co2Unit:
      isAirScan && !isSubCategory.isAirQuality
        ? Yup.number()
            .min(1, "This field is required")
            .required("This field is required")
        : Yup.number().nullable(),
    projectDescription: Yup.string()
      .max(500, "Maximum 500 Characters are allowed")
      .nullable(),
    energySourceId: isEnergyRecovery
      ? Yup.number().notRequired()
      : Yup.number().nullable(),
    energyCost: isEnergyRecovery
      ? Yup.number().positive().notRequired()
      : Yup.number().nullable(),
    energySourceUnitId: isEnergyRecovery
      ? Yup.number().min(1, "This field is required").notRequired()
      : Yup.number().nullable(),
  });

  const methods = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subCategory: [] as string[],
      co2Unit: 1,
      energySourceUnitId:
        selectList?.energySourceUnits &&
        selectList.energySourceUnits[0].valueInt,
    },
  });

  const LoadAuditDetailsById = (): void => {
    request({
      url:
        `${RequestApi.GET_LINK_PROJECT_FINISHED_REPORT_DETAILS_BY_ID}?id=` +
        auditId,
      method: "get",
    })
      .then((resp) => {
        window.sessionStorage.setItem("cn", resp.data.customerName);
        window.sessionStorage.setItem("ci", resp.data.c4CId);
        methods.setValue("projectDescription", resp.data.projectDescription);

        setSavedFiles(resp.data.reports);
        if (resp.data.city == null) {
          window.sessionStorage.setItem("ct", "");
        } else {
          window.sessionStorage.setItem("ct", resp.data.city);
        }

        window.sessionStorage.setItem("con", resp.data.country);
        if (resp.data.soldTo == null) {
          window.sessionStorage.setItem("st", "");
        } else {
          window.sessionStorage.setItem("st", resp.data.soldTo);
        }

        window.sessionStorage.setItem("ad", resp.data.address);
        setFamCode(resp.data.famCode);
        setFamCodeId(resp.data.famCodeId);
        methods.setValue(
          "minimumEstimatedSaving",
          resp.data.recomendations[0]?.minimumEstimatedSaving
        );
        methods.setValue("id", resp.data.id);
        methods.setValue("c4cId", resp.data.c4CId);
        methods.setValue("auditNumber", resp.data.auditNumber);
        methods.setValue("customerName", resp.data.customerName);
        methods.setValue("auditName", resp.data.auditName);
        methods.setValue("brandId", +resp.data.brandId);
        methods.setValue("co2Factor", resp.data.cO2Factor);

        const co2UnitIdVal = getCO2UnitList.find(
          (x) => x.valueInt === resp.data.cO2UnitId
        )?.text;

        methods.setValue("co2FactorSelect", co2UnitIdVal);
        methods.setValue("co2Unit", resp.data.cO2UnitId);
        methods.setValue("criticalResult", resp.data.criticalResult);

        methods.setValue("electricityCost", resp.data.electricityCost);
        methods.setValue("electricityCostSelect", resp.data.energyUnitId);
        methods.setValue("loadedHours", resp.data.loadedHours);
        methods.setValue("lowResult", resp.data.lowResult);
        methods.setValue("machineModel", resp.data.machineModel);
        methods.setValue("mediumResult", resp.data.mediumResult);
        methods.setValue("runningHours", resp.data.runningHours);
        methods.setValue("serialNumber", resp.data.serialNumber);
        methods.setValue(
          "classificationOfProject",
          resp.data.auditClassificationId
        );
        methods.setValue("subCategory", resp.data.typeOfAuditId);

        AuditGroupTypes.map((item: IAuditType) => {
          var filtered = item.subGroups?.filter((item) => {
            return item.valueInt === resp.data.typeOfAuditId[0];
          });

          if (filtered?.length > 0) {
            let selectedGroup = mappedData?.find(
              (e) => e.text === item.auditGroup
            );
            methods.setValue(
              "categoryOfProject",
              selectedGroup?.valueInt.toString()
            );
            handleChange(selectedGroup?.valueInt.toString());
            var filteredValue = item.subGroups?.filter((item1) =>
              resp.data.typeOfAuditId.some(
                (item2: any) => item1.valueInt === item2
              )
            );
            let val = filteredValue?.map((item: any, index) => {
              return { cat: item.valueInt, key: item.text };
            });

            if (
              selectedGroup?.text.toLowerCase() !==
                ShowAuditTypesOnTabConstants.Airnet.toLowerCase() &&
              filteredValue !== undefined
            )
              setMultiSelectOptions(filteredValue);
            if (
              selectedGroup?.text.toLowerCase() ==
                ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase() &&
              val !== undefined
            ) {
              if (
                val?.length == 1 &&
                val[0]?.cat == AuditAirScanTypes.Air_Quality
              ) {
                setIsSubCategory({
                  ...defaultIsSubCategory,
                  isAirQuality: true,
                });
              }
              setSelectedSubCategory(val);
            }

            if (
              selectedGroup?.text.toLowerCase() ==
                ShowAuditTypesOnTabConstants.Enery_Recovery.toLowerCase() &&
              val !== undefined
            ) {
              if (resp.data.energySourceId > 0) {
                methods.setValue("energySourceId", resp.data.energySourceId);
              }
              methods.setValue("energyCost", resp.data.energyCost);
            }
          }
        });

        methods.setValue("subCategorySingleSelect", resp.data.typeOfAuditId[0]);

        methods.setValue(
          "typesOfMeasurements",
          resp.data.typeOfMeasurementIds.map((id: number) => id.toString())
        );
        methods.trigger("typesOfMeasurements");
        methods.setValue(
          "measurementsWithQuantity",
          resp.data.typeOfMeasurementDetails
        );
        methods.trigger("measurementsWithQuantity");
        dispatchRecommendations(
          addAllRecommendations(resp.data.recomendations)
        );

        methods.setValue("responsiblePersonId", resp.data.responsible);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          setAlert("error", "Request Failed", true);
        } else {
          setAlert("error", "Request Failed", true);
        }
      });
  };

  const uploadReportIcon = (
    <div className={classes["upload-icon"]}>
      <SvgIcon component={UploadReportIcon}></SvgIcon>
    </div>
  );

  function submitData(data: any) {
    if (methods.getValues("projectDescription")?.length > 500) {
      return;
    }
    if (recommendationData?.length === 0 && isAirScan) {
      setAlert("error", "Atleast one recommendation is required", true);
      return;
    }

    if (isEditReportPage) linkProjectDetails.famCode = famCode;

    let leadOpportunityDetails = {
      leadId: linkProjectDetails.leadId ?? 0,
      opportunityId: linkProjectDetails.opportunityId ?? 0,
    };

    let recommendations = recommendationData;
    if (isEditReportPage) {
      leadOpportunityDetails = {
        leadId: recommendationData[0]?.leadId ?? 0,
        opportunityId: recommendationData[0]?.opportunityId ?? 0,
      };
      if (isAirScan) {
        data.minimumEstimatedSaving =
          recommendationData[0].minimumEstimatedSaving;
      }
      if (isAirnet) {
        recommendations = [
          {
            ...recommendationData[0],
            minimumEstimatedSaving: data?.minimumEstimatedSaving,
          },
        ];
      }
      if (isEnergyRecovery) {
        recommendations = [
          {
            ...recommendationData[0],
            minimumEstimatedSaving: data?.minimumEstimatedSaving,
            energyCost: data?.energyCost,
            energySourceId: data?.energySourceId,
            energySourceUnitId: data?.energySourceUnitId,
          },
        ];
      }
    }
    let obj = {
      id: auditId ?? 0,
      c4CId: linkProjectDetails.c4CId ?? "",
      typeOfAuditId: isAirScan
        ? data?.subCategory
        : [data?.subCategorySingleSelect],
      auditClassificationId: data?.classificationOfProject ?? 0,
      electricityCost: data?.electricityCost ?? 0,
      co2Factor: data?.co2Factor ?? 0,
      auditName: data?.auditName ?? "",
      minimumEstimatedSaving: data?.minimumEstimatedSaving ?? 0,
      projectDescription: methods.getValues("projectDescription"),
      reports: data?.uploadedFiles,
      address: linkProjectDetails.address ?? "",
      soldTo: linkProjectDetails.soldTo ?? "",
      currencyId: selectList?.currencyList[0]?.valueInt ?? 0,
      famCodeId: isEditReportPage
        ? famCodeId
        : selectList?.famCodeList?.find(
            (x: any) => x.text == linkProjectDetails?.famCode
          )?.valueInt ?? 0,
      famCode: linkProjectDetails?.famCode ?? "",
      cO2UnitId: data?.co2Unit,
      energyUnitId: 1,
      DeletedReportIds: deletedFileIds,
      customer: {
        country: linkProjectDetails.country ?? "",
        city: linkProjectDetails.city ?? "",
        customerName: linkProjectDetails.customerName ?? "",
        address: linkProjectDetails?.address ?? "",
        soldTo: linkProjectDetails?.soldTo ?? "",
      },
      energySourceId: data?.energySourceId,
      energyCost: data?.energyCost,
      energySourceUnitId: data?.energySourceUnitId,
      ...leadOpportunityDetails,
      recomendations: recommendations,
      projectSource: ProjectSourceConstants.LinkProject,
    };

    const formData = new FormData();
    for (let i = 0; i < data.uploadedFiles?.length; i++) {
      formData.append("reports", data.uploadedFiles[i]);
    }

    let url = RequestApi.ADD_Create_Project;
    if (isEditReportPage) {
      url = RequestApi.UPDATE_LINK_Project;
    }

    request({
      url: url,
      method: "post",
      data: { request: obj, Data: formData },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024,
    })
      .then((resp) => {
        setOpenSuccessDialog(true);
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
        console.log(err);
      });
  }

  function handleMultiSelectChange(subCategoryList: any, addSubCategory: any) {
    let result: any[] = [];
    let IsSubCategory = defaultIsSubCategory;

    if (addSubCategory.cat == AuditAirScanTypes.Energy_Potential_PreSales) {
      if (
        selectedSubCategory?.length > 0 &&
        selectedSubCategory?.length > subCategoryList?.length
      ) {
        result = [];
        setSelectedSubCategory([]);
      } else {
        result = [addSubCategory.cat];
        setSelectedSubCategory([addSubCategory]);
      }
    } else {
      if (
        subCategoryList[0]?.cat == AuditAirScanTypes.Energy_Potential_PreSales
      ) {
        result = [addSubCategory.cat];
        if (addSubCategory.cat == AuditAirScanTypes.Air_Quality) {
          IsSubCategory = { ...IsSubCategory, isAirQuality: true };
        } else {
          IsSubCategory = { ...IsSubCategory };
        }
        setSelectedSubCategory([addSubCategory]);
      } else {
        if (
          subCategoryList.length == 1 &&
          subCategoryList[0]?.cat == AuditAirScanTypes.Air_Quality
        ) {
          IsSubCategory = { ...IsSubCategory, isAirQuality: true };
        } else {
          IsSubCategory = { ...IsSubCategory };
        }
        result = subCategoryList.map((item: any) => item.cat);
        setSelectedSubCategory([...subCategoryList]);
      }
    }
    setIsSubCategory(IsSubCategory);
    methods.setValue("subCategory", result);

    methods.trigger("subCategory");
  }

  function handleChange(data: any) {
    resetSelection();

    let selectedText = mappedData?.find((e) => e.valueInt === +data);
    setSelectedProject(selectedText?.text);

    if (selectedText !== undefined) {
      let subCategory = AuditGroupTypes?.find(
        (e) => e.auditGroup === selectedText?.text
      )?.subGroups;
      let val = subCategory?.map((item: any, index) => {
        return { cat: item.valueInt, key: item.text };
      });

      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Airnet.toLowerCase()
      ) {
        subCategory && setMultiSelectOptions(subCategory);
      }
      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase()
      ) {
        val && setMultiSelectOptions(val);
      }
      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Enery_Recovery.toLowerCase()
      ) {
        subCategory && setMultiSelectOptions(subCategory);
      }
    }
  }

  function handleMinSaveInputChange(data: any) {
    methods.setValue("minimumEstimatedSaving", data.target.value);
  }
  function handleCo2InputChange(data: any) {
    methods.setValue("co2Factor", data.target.value);
  }
  function handleElectricCostInputChange(data: any) {
    methods.setValue("electricityCost", data.target.value);
  }

  function handleClassificationChange(data: any) {
    console.log(methods.getValues("classificationOfProject"));
  }

  function handleFileDelete(id: number) {
    setDeletedFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);
  }

  async function mapFiles(fileData: State) {
    let filecollection: File[] = [];
    methods.setValue("uploadedFiles", []);

    if (fileData.fileList && fileData.fileList?.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }

      methods.setValue("uploadedFiles", filecollection);

      methods.trigger("uploadedFiles");
    }
  }

  function handleCommentChange(e: any) {
    methods.setValue("projectDescription", e.target.value);
  }

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  function onConfirmCancelSave() {
    if (isEditReportPage) {
      navigation("/" + RoutingConstants.FinishedReports);
    } else {
      navigation("/" + RoutingConstants.NewProject);
    }
    onCloseCancelSave();
  }

  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  };

  const getActualSaving = () => {
    if (isEditReportPage) {
      if ((recommendationData[0]?.totalQuotedValue ?? 0) > 0) {
        return recommendationData[0]?.totalQuotedValue;
      }
      return recommendationData[0]?.totalExpectedValue;
    } else {
      return linkProjectDetails?.totalAmount > 0
        ? linkProjectDetails?.totalAmount
        : linkProjectDetails?.totalExpectedValue;
    }
  };

  return (
    <>
      <Grid md={12}>
        <Box
          sx={{
            boxShadow: 0,
            paddingBlock: "10px 12px",
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
          px={2}
          mb={1}
        >
          <div className={classes["page-title-container"]}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={BackArrowIcon}
                height="20"
                alt="Back"
                style={{ cursor: "pointer" }}
                loading="lazy"
                decoding="async"
                onClick={navigateToCustomer}
              />
              <div className={classes["page-title"]}>Link Project</div>
            </div>
            {isEditReportPage && (
              <div className={classes["name-auditId-container"]}>
                <div
                  className={classes["customer-name"]}
                  style={{ padding: "8px" }}
                >
                  <span className={classes["fw-600"]}>Project ID:</span>{" "}
                  {methods.getValues("auditNumber")}
                </div>
              </div>
            )}
          </div>
        </Box>
      </Grid>
      <Grid
        md={12}
        style={{
          height: "4px",
          backgroundColor: "#F3F3F3",
          paddingLeft: "20px",
        }}
      ></Grid>
      <Box
        style={{
          border: "none",
          height: "calc(100vh - 195px)",
          overflowY: "scroll",
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <Grid md={12} spacing={2} p={1}>
          <Grid style={{ fontSize: "18px", fontWeight: "600" }}>
            Customer Details
          </Grid>
        </Grid>
        <Grid
          container
          p={1}
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "7px",
          }}
        >
          <Grid md={1.2} style={{ borderRight: "1px solid #9F9F9F" }}>
            <div>
              <div>
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  {IsOpportunity ? "Opportunity ID" : "Lead ID"}
                </p>
                <p style={{ marginTop: "16px", fontWeight: "400" }}>
                  {IsOpportunity
                    ? linkProjectDetails?.opportunityId ||
                      recommendationData[0]?.opportunityId
                    : linkProjectDetails?.leadId ||
                      recommendationData[0]?.leadId}
                </p>
              </div>
            </div>
          </Grid>
          <Grid md={0.2}></Grid>
          <Grid md={0.8} style={{ borderRight: "1px solid #9F9F9F" }}>
            <div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>FAM Code</p>
              <p style={{ marginTop: "16px", fontWeight: "400" }}>
                {isEditReportPage ? famCode : linkProjectDetails?.famCode}
              </p>
            </div>
          </Grid>
          <Grid md={0.28}></Grid>
          <Grid
            md={IsOpportunity ? 3 : 4}
            style={{ borderRight: "1px solid #9F9F9F" }}
          >
            <div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                Customer Name
              </p>
              <p style={{ marginTop: "16px", fontWeight: "400" }}>
                {linkProjectDetails?.customerName}
              </p>
            </div>
          </Grid>
          <Grid md={0.22}></Grid>
          <Grid md={1.25} style={{ borderRight: "1px solid #9F9F9F" }}>
            <div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>Country</p>
              <p style={{ marginTop: "16px", fontWeight: "400" }}>
                {linkProjectDetails?.country}
              </p>
            </div>
          </Grid>
          <Grid md={0.22}></Grid>
          <Grid md={0.9} style={{ borderRight: "1px solid #9F9F9F" }}>
            <div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>Account ID</p>
              <p style={{ marginTop: "16px", fontWeight: "400" }}>
                {linkProjectDetails?.c4CId}
              </p>
            </div>
          </Grid>
          <Grid md={0.25}></Grid>
          <Grid
            md={IsOpportunity ? 1.75 : 2}
            style={{ borderRight: IsOpportunity ? "1px solid #9F9F9F" : "" }}
          >
            <div>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                Responsible Person
              </p>
              <p style={{ marginTop: "16px", fontWeight: "400" }}>
                {isEditReportPage
                  ? methods.getValues("responsiblePersonId")
                  : msal.accounts[0]?.name === undefined
                  ? ""
                  : msal.accounts[0]?.name}
              </p>
            </div>
          </Grid>

          {IsOpportunity && (
            <>
              <Grid md={0.1}></Grid>
              <Grid md={1.5}>
                <div>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    {linkProjectDetails?.totalAmount ||
                    (recommendationData[0]?.totalQuotedValue ?? 0) > 0
                      ? "Total Quoted Value"
                      : "Total Expected Value"}
                  </p>
                  <p style={{ marginTop: "16px", fontWeight: "400" }}>
                    {getActualSaving()}
                  </p>
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <div style={{ paddingTop: "15px" }}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitData)}>
              <Grid container spacing={2} p={1}>
                <CustomSelect
                  name={"categoryOfProject"}
                  label={"Category of Project"}
                  setValue={methods.setValue}
                  isRequired
                  options={mappedData}
                  disabled={isEditReportPage ? true : false}
                  md={4}
                  customHandleChange={handleChange}
                />
                {isAirScan ? (
                  <CustomMultiSelectNew
                    name={"subCategory"}
                    label={"Sub Category Of Project"}
                    md={4}
                    options={multiSelectOptions}
                    disabled={isEditReportPage ? true : false}
                    icon={VectorIcon}
                    multiselectRef={multiselectRef}
                    handleChange={handleMultiSelectChange}
                    placeholder={"Select Sub Category Of Project"}
                    selectedValues={selectedSubCategory}
                  />
                ) : (
                  <CustomSelect
                    name={"subCategorySingleSelect"}
                    label={"Sub Category Of Project"}
                    setValue={methods.setValue}
                    isRequired
                    options={multiSelectOptions}
                    md={4}
                    customHandleChange={() => {}}
                    disabled={isEditReportPage ? true : false}
                  />
                )}

                {!isAirnet ? (
                  <CustomInput
                    isRequired={true}
                    name={"auditName"}
                    numberOnly={false}
                    label={"Project Name"}
                    variant="outlined"
                    md={4}
                    fullWidth
                  />
                ) : (
                  <CustomSelect
                    name={"classificationOfProject"}
                    label={"Classification of Project"}
                    setValue={methods.setValue}
                    isRequired
                    options={ClassificationProject}
                    md={4}
                    customHandleChange={handleClassificationChange}
                  />
                )}
                {isAirnet && (
                  <>
                    <CustomInput
                      isRequired={true}
                      name={"auditName"}
                      numberOnly={false}
                      label={"Project Name"}
                      variant="outlined"
                      md={4}
                      fullWidth
                    />
                    {customInputSelections?.map(
                      (item, index) =>
                        index === 0 && (
                          <CustomInputSelect
                            label={"Minimum Estimated Savings"}
                            options={item.option ?? []}
                            md={4}
                            handleInputChange={handleMinSaveInputChange}
                            name="minimumEstimatedSaving"
                            numberOnly={item.numberOnly}
                            isRequired={false}
                          />
                        )
                    )}
                    <Grid md={4}></Grid>
                  </>
                )}
                {customInputSelections?.map((item, index) => {
                  if ((index === 0 && (isAirnet || isAirScan)) || item.hide) {
                    return null;
                  } else {
                    if (isEnergyRecovery && index == 1) {
                      return (
                        <>
                          <Grid md={8}></Grid>
                          <CustomSelect
                            name={item.name}
                            label={item.label}
                            setValue={methods.setValue}
                            isRequired={false}
                            options={selectList?.energySources}
                            md={4}
                            customHandleChange={() => {
                              console.log(methods.getValues("energySourceId"));
                            }}
                            disabled={false}
                          />
                        </>
                      );
                    } else {
                      return (
                        <CustomInputSelect
                          label={item.label}
                          options={item.option ?? []}
                          md={4}
                          name={item.name}
                          handleInputChange={item.function}
                          numberOnly={item.numberOnly}
                          isRequired={
                            isAirnet || isEnergyRecovery ? false : true
                          }
                          unitName={item.unitName && item.unitName}
                        />
                      );
                    }
                  }
                })}
                {isAirScan && (
                  <AddProductRecommendation
                    open={open}
                    setOpen={setOpen}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    isEditReportPage={isEditReportPage}
                  />
                )}
                <Grid md={12}></Grid>
                <CustomUpload
                  handleFileChange={mapFiles}
                  commentHandler={handleCommentChange}
                  handleFileDelete={handleFileDelete}
                  uploadFilesError={
                    savedFiles?.length === 0
                      ? methods.formState.errors.uploadedFiles?.message
                      : ""
                  }
                  commentsError={
                    methods.getValues("projectDescription")?.length > 500
                      ? "Maximum 500 Characters are allowed"
                      : ""
                  }
                  filesDataVal={savedFiles}
                  selectedProject={selectedProject}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                direction="row"
                justifyContent="flex-end"
                p={1}
              >
                <Button
                  sx={{ textTransform: "none" }}
                  className="cancel-button custom-buttons"
                  disableRipple
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginRight: "15px",
                  }}
                  variant="outlined"
                  onClick={handleCancelClickOpen}
                >
                  Cancel
                </Button>
                <Button
                  style={{ fontSize: "18px", fontWeight: "400" }}
                  type="submit"
                  className="custom-buttons"
                  startIcon={uploadReportIcon}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  disableRipple
                  size="medium"
                  // onClick={CreateNewCustomer}
                >
                  {isEditReportPage ? "Update Project" : "Create Project"}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </div>
      </Box>
      <AddRecommendationsdailog
        open={open}
        onClose={() => setOpen(false)}
        isEdit={isEdit}
        selectedRecommendation={recommendationData[0]}
        fromOpportunity={false}
        auditRecommendationId={0}
        currency={selectList?.currencyList}
        hideFields={{ aproxInvestment: true }}
      />

      <DeleteSuccessDailog
        open={openSuccess}
        onClose={() => {
          setOpenSuccessDialog(false);
          navigate("/" + RoutingConstants.FinishedReports);
        }}
      />
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
    </>
  );
}
