import { Grid, Tooltip, Typography } from "@mui/material";
import classes from "../../../features/UploadFinishedReports.module.css";
import ExcelFileIcon from "./../../../assets/icons/excel.svg";
import PDFFileIcon from "./../../../assets/icons/pdf_icon.svg";
import WordFileIcon from "./../../../assets/icons/word_doc.svg";
import PPTFileIcon from "./../../../assets/icons/ppt_icon.svg";
import DragDrop from "./DragDrop";
import CloseIcon from "./../../../assets/icons/close.svg";
import { useEffect, useState } from "react";
import React from "react";
import State from "../../interface/DragDrop";
import { Controller, useFormContext } from "react-hook-form";
import { ShowAuditTypesOnTabConstants } from "../../AuditTypeConstants";

interface UploadProp {
  handleFileChange: (fileData: State) => Promise<void>;
  commentHandler: (e: any) => void;
  handleFileDelete: (id: number) => void;
  uploadFilesError: any;
  commentsError: any;
  selectedProject?: string;
  filesDataVal: any;
}
export default function CustomUpload({
  handleFileChange,
  commentHandler,
  handleFileDelete,
  uploadFilesError,
  commentsError,
  selectedProject,
  filesDataVal
}: UploadProp) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  type Action =
    | { type: "SET_DROP_DEPTH"; dropDepth: number }
    | { type: "SET_IN_DROP_ZONE"; inDropZone: boolean }
    | { type: "REMOVED_FILE_TO_LIST"; fileName: string }
    | { type: "ADD_FILE_TO_LIST"; files: File[] };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "REMOVED_FILE_TO_LIST":
        return {
          ...state,
          fileList: state.fileList.filter((f) => f.name !== action.fileName),
        };
      default:
        return state;
    }
  };

  const [fileData, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  const removeSavedFiles = (id: number) => {
    let tempSavedFiles = filesDataVal;
    const index = tempSavedFiles.findIndex((item: any) => {
      return item?.id === id;
    });

    if (index > -1) {
      tempSavedFiles.splice(index, 1);
      filesDataVal = tempSavedFiles;
    }
    handleFileDelete(id);
  };

  useEffect(() => {
    handleFileChange(fileData);
  }, [fileData]);

  return (
    <>
      <Grid item xs={12} sm={12} md={4} xl={4} lg={4} direction="row">
        <div style={{ display: "flex", width: "100%" }}>
          <Typography
            style={{ fontSize: "15px", fontStyle: "normal", fontWeight: "600" }}
            variant="inherit"
            align="left"
            color="#000"
          >
            Upload Files{" "}
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          </Typography>
          <span className="example-spacer"> </span>
          <p className="messageuplodtxt"> Maximum file size 20MB</p>
        </div>

        <DragDrop
          data={fileData}
          availableFileCount={filesDataVal.length}
          dispatch={dispatch}
          fileTypes=".pdf,.xls,.xlsx, .doc, .docx, .ppt, .pptx"
          message="Supported formats DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX"
          maxFileCount={5}
          savedFiles={filesDataVal}
        ></DragDrop>

        <Typography
          variant="inherit"
          align="left"
          color="red"
          fontSize={"12px"}
        >
          {uploadFilesError}
        </Typography>
        {filesDataVal.map((f: any) => {
          let icon = null;

          icon = ExcelFileIcon;

          if (f.attachedFileName.includes(".pdf")) {
            icon = PDFFileIcon;
          }
          if (
            f.attachedFileName.includes(".doc") ||
            f.attachedFileName.includes(".docx")
          ) {
            icon = WordFileIcon;
          }
          if (
            f.attachedFileName.includes(".ppt") ||
            f.attachedFileName.includes(".pptx")
          ) {
            icon = PPTFileIcon;
          }

          return (
            <div className={classes["dropped-files"]}>
              <div className="display-data-left">
                <div className={classes["img-container"]}>
                  <img
                    src={icon}
                    height="30"
                    width="30"
                    alt="Excel"
                    loading="lazy"
                    decoding="async"
                  ></img>
                </div>

                <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                  <Tooltip
                    title={f.attachedFileName}
                    placement="right"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      },
                      tooltip: {
                        sx: {
                          fontSize: "13px",
                        },
                      },
                    }}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {f.attachedFileName}
                    </div>
                  </Tooltip>
                  <div>{f.fileSize}</div>
                </div>

                {/* <CloseIcon /> */}
              </div>
              <div className="display-data-right">
                <img
                  src={CloseIcon}
                  className={classes["remove-file"]}
                  height="30"
                  width="30"
                  alt="Close"
                  loading="lazy"
                  decoding="async"
                  onClick={(e) => removeSavedFiles(f.id)}
                ></img>
              </div>
            </div>
          );
        })}
      </Grid>
      <Grid item xs={12} sm={12} md={6.98} xl={6.98} lg={6.98}>
        <div className={classes["wrapper-txtarea"]}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "115%",
            }}
          >
            <p className="labels">Additional Description</p>
            <p
              className={"messageuplodtxt"}
              style={{
                color: "#09c",
                width: "100%",
                textAlign: "right",
              }}
            >
              500 character max.
            </p>
          </div>
          <Controller
            name={"projectDescription"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <textarea
                {...field}
                style={{
                  width: "115%",
                  height: "117px",
                  borderRadius: "3px",
                  borderColor: error ? "red" : "#9F9F9F",
                  fontWeight: 400,
                  fontSize: "1rem",
                  lineHeight: "1.4375em",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontFamily: "Source Sans Pro",
                }}
              ></textarea>
            )}
          />

          {selectedProject?.toLocaleLowerCase().trim() ===
            ShowAuditTypesOnTabConstants.Airnet.toLocaleLowerCase().trim() && (
            <Typography
              variant="inherit"
              align="left"
              color="#818689"
              fontSize={"12px"}
            >
              Note - Please add details on equipment model included:
              Compressors, Dryers, Others...
            </Typography>
          )}
          <Typography
            variant="inherit"
            align="left"
            color="red"
            fontSize={"12px"}
          >
            {commentsError}
          </Typography>
        </div>
      </Grid>
    </>
  );
}
