import { Dialog, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import RecommendationsList from '../../../features/RecommendationsList';
import CloseIcon from '../../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import classes from './CustomTable.module.css';

interface SimpleDialogProps {
  open: boolean;
  //selectedValue: string;
  onClose: () => any;
  auditId: number;
  auditNumber: string;
  customerName: string;
}

export default function RecommendationsListDialog(props: SimpleDialogProps) {
  const { onClose, open, auditId, auditNumber, customerName } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const handleClose = () => {
    //onClose(selectedValue);
  };

  const handleSearchValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = inputRef.current ? inputRef.current.value : '';
    if (event.key === 'Tab' || event.key === 'Enter') {
      setSearchValue(value);
    }
  };

  const Close = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '90% !important',
          borderRadius: '20px',
        },
      }}
      // className={classes["recommendation-model"]}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>
        <div className={classes['dialog-title-container']}>
          <div className={classes['title-container']}>
            <div>
              <label>Product Recommendation List</label>
              <TextField
                style={{
                  maxWidth: '250px',
                  marginLeft: '10px',
                }}
                id="outlined-basic"
                className={classes['search-box']}
                variant="outlined"
                placeholder="Search"
                inputRef={inputRef}
                onKeyDown={handleSearchValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes['title-container-right']}>
              <span className={classes['customer-name']}>
                {' '}
                [{auditNumber}] - {customerName}
              </span>
              <img
                style={{ cursor: 'pointer', float: 'right' }}
                onClick={Close}
                src={CloseIcon}
                height="35px"
                alt="Back"
                loading="lazy"
                decoding="async"
              ></img>
            </div>
          </div>
        </div>
      </DialogTitle>
      <RecommendationsList
        filterValue={searchValue}
        CloseDailog={Close}
        auditId={auditId}
        customerName={customerName}
        auditNumber={auditNumber}
      ></RecommendationsList>
    </Dialog>
  );
}
