import React, { Fragment, useEffect, useState } from "react";
import { TableColumn } from "../common/interface/TableColumn";
import RecommendationListTable from "../common/components/CustomComponents/RecommendationListTable";
import classes from "./RecommendationsList.module.css";
import { useRequest } from "../services/agent";
import { RequestApi } from "../common/Constant";

interface Props {
  CloseDailog: () => any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  filterValue: number;
}

export interface IRecommendationData {
  id: number;
  typeOfInsestment: string;
  typeOfRecommendation: string;
  description: string;
  approxInvestment: number;
  minimumEstimatedSaving: number;
  paybackMonths: number;
  followUpDate: string;
  comments: string;
}

function createRecommendationListData(
  id: number,
  typeOfInsestment: string,
  typeOfRecommendation: string,
  description: string,
  approxInvestment: number,
  minimumEstimatedSaving: number,
  paybackMonths: number,
  followUpDate: string,
  comments: string
): IRecommendationData {
  return {
    id,
    typeOfInsestment,
    typeOfRecommendation,
    description,
    approxInvestment,
    minimumEstimatedSaving,
    paybackMonths,
    followUpDate,
    comments,
  };
}

interface SimpleDialogProps {
  CloseDailog: () => any;
  auditId: number;
  filterValue: string;
  auditNumber: string;
  customerName: string;
}

const RecommendationsList = (props: SimpleDialogProps) => {
  const { CloseDailog, auditId, filterValue, auditNumber, customerName } =
    props;
  const request = useRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const [recommendationList, setRecommendationList] = useState<
    Array<IRecommendationData>
  >([]);
  const [filterList, setFilterList] = useState<Array<IRecommendationData>>([]);
  const [currency, setCurrency] = useState<string>("");
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [isApiSuccess, setIsApiSuccess] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }

  const columns: TableColumn[] = [
    {
      id: "typeOfInsestment",
      label: "Category of Product",
      align: "left",
      minWidth: 160,
      filter: false,
    },
    {
      id: "typeOfRecommendation",
      label: "Type of Product",
      align: "left",
      minWidth: 150,
      filter: false,
    },
    {
      id: "description",
      label: "Description of Product",
      minWidth: 150,
      align: "left",
      filter: false,
    },
    {
      id: "approxInvestment",
      label: `Approx. Investment(${currency})`,
      minWidth: 130,
      align: "left",
      filter: false,
    },
    {
      id: "minimumEstimatedSaving",
      label: "Minimum Estimated Savings (kWh/annum)",
      minWidth: 150,
      align: "left",
      filter: false,
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 250,
      align: "left",
      filter: false,
    },
    {
      id: "C4CStatus",
      label: "Lead Status",
      align: "left",
      minWidth: 90,
      filter: false,
    },
    // {
    //   id: 'paybackMonths',
    //   label: 'Simple Payback Months',
    //   minWidth: 140,
    //   align: 'left',
    //   filter: false,
    // },
    // {
    //   id: 'followupDate',
    //   label: 'Follow Up Date',
    //   align: 'left',
    //   minWidth: 130,
    //   filter: false,
    // },
  ];

  useEffect(() => {
    loadData();
  }, [auditId]);

  useEffect(() => {
    if (isApiSuccess == true) {
      loadData();
      setIsApiSuccess(false);
    }
  }, [isApiSuccess]);

  const loadData = (): void => {
    setLoading(true);
    request({
      url: `${RequestApi.GET_ADDED_RECOMMENDATION_LIST}?AuditId=${auditId}`,
      method: "get",
    })
      .then((resp) => {
        setRecommendationList(resp.data.data);
        setCurrency(resp.data.currency);
        setFilterList(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.code == "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const search = filterValue.toLowerCase();
    const newData = filterList.filter((data) => {
      if (
        data.typeOfRecommendation.toLowerCase().includes(search) ||
        data.description.toLowerCase().includes(search) ||
        data.typeOfInsestment.toLowerCase().includes(search)
      ) {
        return data;
      }
    });
    setRecommendationList(newData);
  }, [filterValue]);

  return (
    <Fragment>
      <div className={classes["tablemarginlr"]}>
        <RecommendationListTable
          data={recommendationList}
          columns={columns}
          CloseDailog={CloseDailog}
          auditId={auditId}
          customerName={customerName}
          setOpenSuccessDialog={setOpenSuccessDialog}
          openSuccessDialog={openSuccessDialog}
          setIsApiSuccess={setIsApiSuccess}
        ></RecommendationListTable>
      </div>
    </Fragment>
  );
};

export default RecommendationsList;
