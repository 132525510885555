import { useEffect, useState } from 'react';
import { RequestApi } from '../common/Constant';
import { useRequest } from './agent';

export type Country = {
  id: number;
  countryName: string;
  countryCode: string;
};

export const useCountries = () => {
  const request = useRequest();
  const [countriesData, setCountriesData] = useState<Country[]>([]);

  useEffect(() => {
    request(RequestApi.Get_ALLCOUNTRIES)
      .then((resp) => setCountriesData(resp.data))
      .catch((error) => console.error(error));
  }, []);

  const getCountryName = (value: string) => {
    if (value) {
      const requiredCountry = countriesData?.filter(
        (country) => country.countryCode === value
      );
      return requiredCountry[0] ? requiredCountry[0].countryName : value;
    }
    return '';
  };

  const getCountryCode = (value: string) => {
    if (value) {
      const requiredCountry = countriesData?.filter(
        (country) => country.countryName.toLowerCase() === value.toLowerCase()
      );
      return requiredCountry[0] ? requiredCountry[0].countryCode : value;
    }
    return '';
  };

  const countries = {
    countriesData,
    getCountryCode,
    getCountryName,
  };

  return countries;
};
