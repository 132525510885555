export const tab_Style = {
    "& .MuiTabs-scroller": {
        background: "#fff !important",
        borderRadius: "8px"
    },
    "& .MuiButtonBase-root": {
        color: "#373D41",
        fontFamily: "Source Sans Pro",
        fontSize: "20px",
        fontWeight: 400,
    },
    "& .MuiBox-root": {
        padding: "0px !important",
        border: "none"
    },
    "& .MuiTab-root.Mui-selected": {
        color: "var(--primary-color)",
        fontWeight: 600,
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "var(--primary-color)",
        height: "3px",
        marginLeft: "4px"
    },
};