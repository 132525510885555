import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SmartLinkLogo from '../assets/images/SMARTLINK_logo.svg';
import CloseIcon from '../assets/icons/close.svg';
import BackIcon from '../assets/icons/back_arrow-icon.svg';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../common/components/CustomComponents/CustomInput';
import { Box, Button, Dialog, Grid, Stack, Typography } from '@mui/material';
import SendToSmartLinkImg from '../assets/images/send-to-smartlink.svg';
import classes from './SendReportToSmartLink.module.css';

export interface SendReportToSmartLinkData {
  c4CID: number;
  serialNumber: number;
  auditName: string;
  customerName: string;
}

interface File {
  label: string;
  name: string;
  id: string;
  isChecked: boolean;
  fileSize: string;
}

type SendReportToSmartLinkProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type SuccessPopUpProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeParentDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SuccessPopUp({
  open,
  setOpen,
  closeParentDialog,
}: SuccessPopUpProps) {
  const date = new Date();

  const handleClose = () => {
    setOpen(false);
    closeParentDialog(false);
  };

  return (
    <Dialog
      open={open}
      sx={{
        '&.MuiModal-root>.MuiDialog-container>.MuiPaper-root': {
          borderRadius: '1.25rem',
        },
      }}
    >
      <div className={classes['success-dialog-container']}>
        <Stack
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
        >
          <img
            src={SendToSmartLinkImg}
            alt='successfully sent'
            loading='lazy'
            decoding='async'
            className={classes['send-to-samrtlink-img']}
          />
          <img
            className={classes['close-icon']}
            src={CloseIcon}
            alt="close-icon"
            onClick={handleClose}
            loading='lazy'
            decoding='async'
          />
        </Stack>
        <Typography
          fontSize={'2rem'}
          fontWeight={600}
        >
          You’re a rockstar!
        </Typography>
        <div className={classes['success-text-para']}>
          Report
          <span className={classes['sent-to-text']}> sent to</span>
          <span className={classes['smart-link-text']}> Smart link</span>
          <span className={classes['sent-to-text']}> successfully!</span>
        </div>
        <div className={classes['comments-date-container']}>
          <p>Comments: Lorem Imps</p>
          <p>Date: {date.toLocaleDateString()}</p>
        </div>
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: '700',
            alignSelf: 'center',
            border: '1px solid #09C',
            color: '#0099CC',
          }}
          disableRipple
          variant="outlined"
          onClick={handleClose}
        >
          Okay
        </Button>
      </div>
    </Dialog>
  );
}

export default function SendReportToSmartLink({
  open,
  setOpen,
}: SendReportToSmartLinkProps) {
  const [filesData, setFilesData] = useState<File[]>();
  const [successPopUpOpen, setSuccessPopUpOpen] = useState(false);

  const sendSmartLinkReportInputFields = [
    {
      name: 'c4CID',
      label: 'C4C ID',
    },
    {
      name: 'serialNumber',
      label: 'Serial Number',
    },
    {
      name: 'auditName',
      label: 'Audit Name',
    },
    {
      name: 'customerName',
      label: 'Customer Name',
    },
  ];

  const methods = useForm<SendReportToSmartLinkData>({
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const files: File[] = useMemo(
    () => [
      {
        label: 'DummyFile1',
        name: 'dummyFile1',
        isChecked: false,
        id: 'dummy-file1',
        fileSize: '12MB',
      },
      {
        label: 'DummyFile2',
        name: 'dummyFile2',
        isChecked: false,
        id: 'dummy-file2',
        fileSize: '14MB',
      },
      {
        label: 'DummyFile3',
        name: 'dummyFile3',
        isChecked: false,
        id: 'dummy-file3',
        fileSize: '16MB',
      },
      {
        label: 'DummyFile4',
        name: 'dummyFile4',
        isChecked: false,
        id: 'dummy-file4',
        fileSize: '19MB',
      },
      {
        label: 'DummyFile5',
        name: 'dummyFile5',
        isChecked: false,
        id: 'dummy-file5',
        fileSize: '13MB',
      },
    ],
    []
  );

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const checked = event.target.checked;
      setFilesData((prev) =>
        prev?.map((file) => ({ ...file, isChecked: checked }))
      );
    },
    []
  );

  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;
    const id = event.target.id;
    setFilesData((prev) =>
      prev?.map((file) => {
        if (id === file.id) {
          return { ...file, isChecked: checked };
        }
        return file;
      })
    );
  };

  useEffect(() => setFilesData(files), [files, handleChange]);
  return (
    <Dialog
      open={open}
      sx={{
        right: '0',
        '&.MuiModal-root>.MuiDialog-container>.MuiPaper-root': {
          marginTop: '0',
          marginInline: '0',
          height: 'calc(100% - 32px)',
          maxHeight: 'unset',
          borderRadius: '1.25rem 0 0 1.25rem',
        },
        '&.MuiModal-root>.MuiDialog-container': {
          justifyContent: 'end',
        },
      }}
    >
      <div className={classes['smart-link-dialog-cotainer']}>
        <div
          className={`${classes['flex-row-center']}
                      ${classes['logo-with-top-statement']}`}
        >
          <div
            className={`${classes['flex-row-center']}
                      ${classes['bg-lightblue']}`}
          >
            <Box
              padding={'1.375rem .625rem'}
              boxShadow={0}
              border={0}
            >
              <img
                className={classes['smart-link-logo']}
                src={SmartLinkLogo}
                alt='smartlink-logo'
                loading='lazy'
                decoding='async'
              />
            </Box>
            <Typography
              fontSize={'0.9375rem'}
              padding={'.375rem .625rem'}
              borderLeft={'1px solid #BBBDC0'}
              color={'#0078A1'}
            >
              For some time now, the Global Diagnostics Center is keeping track
              of all the repair suggestions the SMARTLINK solution creates to
              support ur customer centers worldwide.
            </Typography>
          </div>
          <Box
            alignSelf="start"
            boxShadow={0}
            border={0}
          >
            <img
              className={classes['close-icon']}
              src={CloseIcon}
              alt="close-icon"
              onClick={handleClose}
              loading='lazy'
              decoding='async'
            />
          </Box>
        </div>
        <div
          className={`${classes['flex-row-center']} ${classes['header-with-icon']}`}
        >
          <img
            className={classes['back-icon']}
            src={BackIcon}
            alt="back-icon"
            onClick={handleClose}
            loading='lazy'
            decoding='async'
          />
          <Typography className={`${classes['smart-link-heading']}`}>
            Send Report to Smart link
          </Typography>
        </div>
        <div>
          <FormProvider {...methods}>
            <form>
              <Grid
                container
                spacing={2}
                sx={{ marginBlock: ' 1rem .5rem' }}
              >
                {sendSmartLinkReportInputFields.map(
                  ({ name, label }, index) => {
                    return (
                      <CustomInput
                        isRequired={false}
                        name={name}
                        label={label}
                        md={6}
                        variant="outlined"
                        fullWidth
                      />
                    );
                  }
                )}
              </Grid>

              <Box
                border={'1px solid #B9B9B9'}
                margin={'14px 0'}
              >
                <div
                  className={`${classes['flex-row-center']} ${classes['bg-lightblue']} ${classes['checkbox-columns']}`}
                >
                  <input
                    className="customcheckbox"
                    type="checkbox"
                    name="allfiles"
                    id="all-files"
                    onChange={handleChange}
                  />
                  <label htmlFor="all-files">
                    <div className={`${classes['flex-row-center']}`}>
                      <Typography
                        fontSize={'1rem'}
                        fontWeight={700}
                        lineHeight={'1.5rem'}
                        marginRight={'.625rem'}
                      >
                        File Names
                      </Typography>
                      <span className={classes['files-selected']}>
                        2 File Selected
                      </span>
                    </div>
                  </label>
                </div>
                <div className={classes['files-container']}>
                  {filesData?.map((file) => (
                    <div
                      className={`${classes['flex-row-center']} ${classes['checkbox-columns-data']}`}
                    >
                      <input
                        className="customcheckbox"
                        type="checkbox"
                        onChange={handleCheckBoxChange}
                        checked={file.isChecked}
                        name={file.name}
                        id={file.id}
                      />
                      <label htmlFor={file.id}>
                        <div
                          className={`${classes['flex-row-center']} ${classes['name-with-filesize']}`}
                        >
                          <Typography
                            fontSize={'1rem'}
                            lineHeight={'1.5rem'}
                            letterSpacing={0}
                            color={'#000000'}
                          >
                            {file.label}
                          </Typography>
                          <span className={classes['file-size']}>
                            File size: {file.fileSize}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </Box>
              <div className={classes['wrapper-txtarea']}>
                <p className="labels">Comments</p>
                <textarea
                  className={classes['custtextcontrol']}
                  placeholder="Write your remarks here..."
                ></textarea>
              </div>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  sx={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontWeight: '400',
                    marginRight: '1rem',
                  }}
                  className="cancel-button custom-buttons"
                  disableRipple
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  // type="submit"
                  className="custom-buttons"
                  sx={{
                    textTransform: 'none',
                    fontSize: '1.125rem',
                    fontWeight: '400',
                  }}
                  variant="contained"
                  disableRipple
                  size="medium"
                  onClick={() => setSuccessPopUpOpen(true)}
                >
                  Send to Smart Link
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </div>
      </div>
      <SuccessPopUp
        open={successPopUpOpen}
        setOpen={setSuccessPopUpOpen}
        closeParentDialog={setOpen}
      />
    </Dialog>
  );
}
