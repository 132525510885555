import { createSlice } from "@reduxjs/toolkit";

export interface FetchingLoader {
  showLoader: boolean;
  serverError: boolean;
}

const initialState: FetchingLoader = {
  showLoader: false,
  serverError: false,
};

const fetchingLoaderDataSlice = createSlice({
  name: "fetchingLoader",
  initialState: initialState,
  reducers: {
    showFetchingLoader: (state) => {
      state.showLoader = true;
    },
    hideFetchingLoader: (state) => {
      state.showLoader = false;
    },
    showServerError: (state) => {
      state.serverError = true;
    },
    hideServerError: (state) => {
      state.serverError = false;
    },
  },
});

export const { showFetchingLoader, hideFetchingLoader, showServerError, hideServerError } = fetchingLoaderDataSlice.actions;

export default fetchingLoaderDataSlice;
