import { FormControl } from "@mui/base";
import { Box, Button, FormLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
// import Box from "../common/components/Layout/box/Box";
import classes from "./CreateCustomerPage.module.css";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RoutingConstants } from "../common/RoutingConstants";

const CreateCustomerPage = () => {
  const navigation = useNavigate();

    const back = () => {
        navigation("/" + RoutingConstants.NewProject);
  };

  const validationSchema = Yup.object().shape({
    c4cid: Yup.string()
      .required("C4C Id is required")
      .min(4, "C4C Id must be atleast 4 characters")
      .max(5, "Maximum 5 characters are allowed"),
    companyName: Yup.string()
      .required("Company Name is required")
      .min(4, "Company Name must be atleast 4 characters")
      .max(50, "Maximum 50 characters are allowed"),
    country: Yup.string()
      .required("Country is required")
      .min(4, "Country must be atleast 4 characters")
      .max(50, "Maximum 50 characters are allowed"),
    city: Yup.string()
      .required("City is required")
      .min(4, "City must be atleast 4 characters")
      .max(50, "Maximum 50 characters are allowed"),
    address: Yup.string()
      .required("Address is required")
      .min(4, "Address must be atleast 4 characters")
      .max(50, "Maximum 50 characters are allowed"),
    // dob: Yup.string()
    //      .required('Date of Birth is required')
    //      .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
  });

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data: any) {
    // display form data on success
    alert("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
  }

  return (
    <Fragment>
      <Box px={2} py={1} mb={1}>
        <div className={classes["page-title"]}>
          <img
            src={BackArrowIcon}
            height="25"
            alt="Back"
            loading="lazy"
            decoding="async"
          ></img>
          Create New Customer
        </div>
      </Box>
      {/* <Box> */}
      <Box px={2} py={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            className="grid-container"
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} xl={4} lg={4} md={6} direction="row">
              <FormControl>
                <FormLabel className={classes["labels"]}>C4C Id</FormLabel>
                <TextField
                  type="text"
                  error={errors.c4cid ? true : false}
                  {...register("c4cid")}
                ></TextField>
                <Typography variant="inherit" align="left" color="red">
                  {errors.c4cid?.message}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={4} lg={4} direction="row">
              <FormControl>
                <FormLabel className={classes["labels"]}>
                  Company Name
                </FormLabel>
                <TextField
                  type="text"
                  error={errors.companyName ? true : false}
                  {...register("companyName")}
                />
                <Typography variant="inherit" align="left" color="red">
                  {errors.companyName?.message}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={4} lg={4} direction="row">
              <FormControl>
                <FormLabel className={classes["labels"]}>Country</FormLabel>
                <TextField
                  type="text"
                  error={errors.country ? true : false}
                  {...register("country")}
                />
                <Typography variant="inherit" align="left" color="red">
                  {errors.country?.message}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={4} lg={4} direction="row">
              <FormControl>
                <FormLabel className={classes["labels"]}>City</FormLabel>
                <TextField
                  type="text"
                  error={errors.city ? true : false}
                  {...register("city")}
                />
                <Typography variant="inherit" align="left" color="red">
                  {errors.city?.message}
                </Typography>
              </FormControl>
            </Grid>
            <Grid
              className="teat"
              item
              xs={12}
              sm={12}
              md={6}
              xl={4}
              lg={4}
              direction="row"
            >
              <FormControl>
                <FormLabel className={classes["labels"]}>Address</FormLabel>
                <TextField
                  type="text"
                  error={errors.address ? true : false}
                  {...register("address")}
                />
                <Typography variant="inherit" align="left" color="red">
                  {errors.address?.message}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} xl={4} lg={4}></Grid>
            <Grid
              direction="row"
              justifyContent="flex-end"
              container
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
            >
              <Button sx={{ mr: 2 }} variant="outlined" onClick={back}>
                Cancel
              </Button>

              <Button type="submit" variant="contained">
                Save Customer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {/* </Box> */}
    </Fragment>
  );
};

export default CreateCustomerPage;
