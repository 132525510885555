import React from "react";
import { useFormContext, Controller, UseFormSetValue } from "react-hook-form";
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
  SelectChangeEvent,
  Grid,
  FormLabel,
} from "@mui/material";
import DropdownModel from "../../interface/DropdownModel";

interface CustomSelectProps {
  name: string;
  label: string;
  setValue: UseFormSetValue<any>;
  md?: number;
  isRequired: true | false;
  options: DropdownModel[];
  disabled?: true | false;
  customHandleChange?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      // marginRight: 10,
    },
  },
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  label,
  md = 4,
  setValue,
  isRequired,
  options,
  disabled = false,
  customHandleChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (event: SelectChangeEvent) => {
    setValue(name, event.target.value);
    if (customHandleChange !== undefined) {
      customHandleChange(event.target.value);
    }
    if (name === "investmentId") {
      setValue("recommendationId", 0);
    }
  };

  return (
    <Grid item xs={12} md={md} xl={md} direction="row">
      <FormControl variant="outlined" fullWidth error={!!errors[name]}>
        <FormLabel className="labels">
          {label}
          {isRequired && (
            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
          )}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              fullWidth
              disabled={disabled}
              value={field.value ? String(field.value) : ""}
              MenuProps={MenuProps}
              name={name}
              displayEmpty
              onBlur={field.onBlur}
              // {...field}
              onChange={handleChange}
              labelId={`${name}-label`}
              id={name}
            >
              <MenuItem key="0" value="" disabled={true}>
                <p className="grey-text">Select {label}</p>
              </MenuItem>
              {options?.map((option) => (
                <MenuItem
                  key={option.valueInt}
                  value={option.valueInt?.toString()}
                  disableRipple
                  sx={{
                    whiteSpace: "normal",
                  }}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Typography
          variant="inherit"
          align="left"
          fontSize={"12px"}
          color="red"
        >
          {errors[name] ? (errors[name]?.message as string) : undefined}
          {/* {errors[name] && errors[name]?.type === "server"
            ? (errors[name]?.message as string)
            : undefined} */}
        </Typography>
      </FormControl>
    </Grid>
  );
};

export default CustomSelect;
