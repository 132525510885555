// CustomConfirmationDialog.tsx
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  styled,
  ButtonProps,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import CloseIcon from "../../../assets/icons/close.svg";
import classes from "./ConfirmationDialog.module.css";

export interface CustomConfirmationDialogProps {
  icon: string;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: (id?: number) => void;
  isConfirmForDelete?: boolean;
  id?: number;
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#C8102E"),
  backgroundColor: "#C8102E",
  "&:hover": {
    backgroundColor: "#C8102E",
  },
}));

const CustomConfirmationDialog = () => {
  let show = useSelector((state: RootState) => state.loaderReducer.show);
  let content = useSelector(
    (state: RootState) => state.confirmationBoxReducer.content
  );
  let title = useSelector(
    (state: RootState) => state.confirmationBoxReducer.title
  );
  let icon = useSelector(
    (state: RootState) => state.confirmationBoxReducer.icon
  );
  let onClose = useSelector(
    (state: RootState) => state.confirmationBoxReducer.onClose
  );
  let onConfirm = useSelector(
    (state: RootState) => state.confirmationBoxReducer.onConfirm
  );
  let isConfirmForDelete = useSelector(
    (state: RootState) => state.confirmationBoxReducer.isConfirmForDelete
  );
  let id = useSelector((state: RootState) => state.confirmationBoxReducer.id);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          backgroundColor: "unset",
          boxShadow: "none",
        },
        "& .MuiDialogTitle-root": {
          padding: "16px",
        },
        "& .MuiDialogContent-root": {
          padding: "50px 12px 18px ",
        },
      }}
      className="wrapper-dialog"
      open={show}
      // onClose={onClose}
    >
      <div style={{ background: "transparent", height: "335px" }}>
        <div className={classes["icon-alignment"]}>
          <div>
            <img
              src={icon}
              height="35"
              alt="Back"
              loading="lazy"
              decoding="async"
            ></img>
          </div>
        </div>
        <div style={{ background: "#fff", borderRadius: "15px" }}>
          <DialogContent>
            <div className={classes["close-button"]}>
              <img
                onClick={() => onClose()}
                src={CloseIcon}
                height="35"
                alt="Back"
                loading="lazy"
                decoding="async"
              ></img>
            </div>

            <div className={classes["upload-report-popup-title"]}>{title}</div>
            <p className="text-message">{content}</p>
          </DialogContent>
          <DialogActions className={classes["action-buttons"]}>
            <Button
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginRight: "15px",
                minWidth: "115px",
              }}
              sx={{ textTransform: "none" }}
              className="cancel-button custom-buttons"
              disableRipple
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancel
            </Button>

            {!isConfirmForDelete && (
              <Button
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  minWidth: "115px",
                }}
                sx={{ textTransform: "none" }}
                className="custom-buttons"
                variant="contained"
                onClick={() => onConfirm(id)}
              >
                Confirm
              </Button>
            )}
            {isConfirmForDelete && (
              <ColorButton
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  minWidth: "115px",
                }}
                sx={{ textTransform: "none" }}
                className="custom-buttons"
                onClick={() => onConfirm()}
              >
                Delete
              </ColorButton>
            )}
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomConfirmationDialog;
