import { UnAuthorizedHeader } from "../common/components/Layout/header/Header";
import { Box, Typography } from "@mui/material";
import unAuthorizedImage from "../assets/images/unauthorized.png";
import Footer from "../common/components/Layout/footer/Footer";

export default function UnAuthorized() {
  return (
    <Box>
      <UnAuthorizedHeader />
      <Box
        marginX={"5px"}
        sx={{
          textAlign: "center",
          fontFamily: "Source Sans Pro",
          display: "grid",
          height: "calc(100vh - 125px)",
          width: "100%",
          border: "none",
        }}
      >
        <div style={{ placeSelf: "center" }}>
          <img
            src={unAuthorizedImage}
            alt="unauthoried"
          />
          <Typography
            fontSize={"2.5rem"}
            fontWeight={"600"}
            color={"#0099CC"}
          >
            Oh No!
          </Typography>
          <Typography
            fontSize={"1.5rem"}
            fontWeight={"600"}
          >
            It seems like you don’t have access. <br />
            Please contact admin to get access to the application.
          </Typography>
        </div>
      </Box>
      <Footer />
    </Box>
  );
}
