import React, { useEffect, useRef, useState } from "react";

import { useFormContext, Controller, FieldError } from "react-hook-form";

import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Grid,
  FormLabel,
  Typography,
} from "@mui/material";

import DropdownModel from "../../interface/DropdownModel";

import Multiselect from "multiselect-react-dropdown";

import classes from "../CustomComponents/CustomMultiSelectNew.module.css";

import ArrowDown from "./../../../assets/icons/arrow_down.svg";

interface CustomMultiSelectProps {
  name: string;

  label: string;

  md?: number;

  options: any[];

  selectedValues: any[];
  error?: FieldError;

  icon?: any;

  handleChange: any;

  multiselectRef: any;

  placeholder?: string;

  disabled?: boolean;
}

// const useStyles = makeStyles((theme) => ({

//   formControl: {

//     minWidth: 120,

//     maxWidth: 300,

//   },

// }));

const CustomMultiSelectNew: React.FC<CustomMultiSelectProps> = ({
  name,

  label,

  md,

  options,

  error,

  icon,

  handleChange,

  multiselectRef,

  placeholder,

  disabled,

  selectedValues,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [scrollDimensions, setScrollDimensions] = useState({
    scrollHeight: 0,
    scrollWidth: 0,
  });
  const [clientDimensions, setClientDimensions] = useState({
    clientHeight: 0,
    clientWidth: 0,
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScrollDimensions = () => {
      if (scrollContainerRef.current && clientDimensions.clientHeight > 0) {
        const searchBox = scrollContainerRef.current.querySelector(
          "div.search-wrapper.searchWrapper"
        );
        const chips = searchBox?.getElementsByClassName("chip");

        if (searchBox != null) {
          if (chips) {
            const chipsArray = Array.from(chips);

            chipsArray.forEach((chip) => {
              if (chip instanceof HTMLElement) {
                chip.style.display = "inline-flex";
              }
            });

            setScrollDimensions({
              scrollHeight: searchBox?.scrollHeight ?? 0,
              scrollWidth: searchBox?.scrollWidth ?? 0,
            });
            if (searchBox?.scrollHeight + 1 >= clientDimensions.clientHeight) {
              chipsArray.forEach((chip) => {
                if (chip instanceof HTMLElement) {
                  chip.style.display = "none";
                }
              });
            }
          }
        }
      }
    };

    updateScrollDimensions();

    window.addEventListener("resize", updateScrollDimensions);
    return () => window.removeEventListener("resize", updateScrollDimensions);
  }, [clientDimensions]);

  useEffect(() => {
    const setScreenDimension = () => {
      const scrollContainer = scrollContainerRef.current?.querySelector(".multiSelectContainer");
      if (scrollContainer) {
        const { clientHeight, clientWidth } = scrollContainer;
        setClientDimensions({ clientHeight, clientWidth });
        const images = document.getElementsByClassName("icon_down_dir");
        const image = images && (images[0] as HTMLImageElement);
        image.src = ArrowDown;
        if (clientHeight == 0) {
          setScreenDimension();
        }
      }
    };
    setScreenDimension();
  }, [selectedValues]);
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={md}
      xl={md}
      lg={md}
      container
      direction="row"
    >
      <FormControl
        variant="outlined"
        fullWidth
        error={!!errors[name]}
      >
        <FormLabel className="labels">
          {label}

          {<span style={{ color: "red", marginLeft: "3px" }}>*</span>}
        </FormLabel>

        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <div
                ref={scrollContainerRef}
                style={{ position: "relative", display: "inline-block" }}
              >
                <Multiselect
                  ref={multiselectRef}
                  customCloseIcon={
                    <img
                      style={{ marginLeft: "10px" }}
                      src={icon}
                      alt="arrow-down"
                    />
                  }
                  selectedValues={selectedValues}
                  displayValue="key"
                  placeholder={placeholder}
                  avoidHighlightFirstOption={true}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={handleChange}
                  disable={disabled}
                  onSearch={function noRefCheck() {}}
                  onSelect={handleChange}
                  showArrow={true}
                  className={classes["multiSelectContainer"]}
                  style={{
                    chips: {
                      padding: "2px 4px 2px 4px",
                      border: "1px solid #0078A1",
                      background: "white",
                      color: "#0078A1",
                      borderRadius: "0px",
                    },
                    inputField: {
                      width: field?.value?.length > 0 ? "10%" : "85%",
                    },
                  }}
                  options={options !== undefined ? options : "Select"}
                  showCheckbox
                  hidePlaceholder={field?.value?.length == 0 ? false : true}
                />
                {field?.value?.length > 0 &&
                  scrollDimensions?.scrollHeight + 1 >= clientDimensions?.clientHeight && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "20px",
                        transform: "translate(-50%, -50%)",
                        padding: "4px 8px",
                        backgroundColor: disabled ? "#A3C1CC" : "#0078A1",
                        color: "#fff",
                        borderRadius: "3px",
                        zIndex: 2,
                        fontFamily: "Source Sans Pro",
                        fontSize: "16px",
                      }}
                    >
                      {field?.value?.length}
                    </div>
                  )}
              </div>
            );
          }}
        />

        <Typography
          variant="inherit"
          align="left"
          color="red"
          fontSize={"12px"}
        >
          {errors[name] && errors[name]?.type === "optionality"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "min"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "max"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "required"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "typeError"
            ? "Only numbers are allowed"
            : undefined}
          {errors[name] && errors[name]?.type === "matches"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "server"
            ? (errors[name]?.message as string)
            : undefined}
          {errors[name] && errors[name]?.type === "length"
            ? (errors[name]?.message as string)
            : undefined}
        </Typography>
      </FormControl>
    </Grid>
  );
};

export default CustomMultiSelectNew;
