import React, { useState } from "react";
import { useFormContext, Controller, FieldError } from "react-hook-form";
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Grid,
  FormLabel,
  Typography,
} from "@mui/material";
import DropdownModel from "../../interface/DropdownModel";

interface CustomMultiSelectProps {
  name: string;
  label: string;
  md?: number;
  options: DropdownModel[];
  error?: FieldError;
}

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     minWidth: 120,
//     maxWidth: 300,
//   },
// }));

const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
  name,
  label,
  md = 6,
  options,
  error,
}) => {
  //   const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["0"]);

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {

    const {
      target: { value },
    } = event;
    let a = typeof value === "string" ? value.split(",") : value;
    var index = a.indexOf("0");
    if (index >= 0) {
      a.splice(index, 1);
    }
    // setSelectedOptions(typeof value === "string" ? value.split(",") : value);
    setSelectedOptions(a);
  };

  const getSelectedText = (id: string): string => {
    let selectedText = options.find((e) => e.valueInt === +id);

    if (selectedText) {
      return selectedText.text;
    } else {
      return "";
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={md}
      xl={md - 2}
      lg={md - 2}
      container
      direction="row"
    >
      <FormControl
        // variant="outlined"
        //   className={classes.formControl}
        fullWidth
        error={!!error}
      >
        <FormLabel className="labels">{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={name}
              {...field}
              defaultValue={[]}
              multiple
              value={selectedOptions}
              onChange={handleChange}
              //   label={label}
              //   renderValue={(selected) => (selected as string[]).join(", ")}
              renderValue={(selected) =>
                selected.map((p) => getSelectedText(p)).join(", ")
              }
            >
              <MenuItem key="0" value="0">
                Select {label}
              </MenuItem>
              {options.map((option) => (
                <MenuItem
                  key={option.valueInt}
                  value={option.valueInt.toString()}
                >
                  <Checkbox
                    checked={selectedOptions.includes(
                      option.valueInt.toString()
                    )}
                  />
                  <ListItemText primary={option.text} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <Typography
          variant="inherit"
          align="left"
          color="red"
          fontSize={"12px"}
        >
          {errors[name] ? (errors[name]?.message as string) : undefined}
          {/* {errors[name] ? "Required" : undefined} */}
        </Typography>
      </FormControl>
    </Grid>
  );
};

export default CustomMultiSelect;
