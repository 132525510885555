import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomConfirmationDialogProps } from "../components/CustomComponents/ConfirmationDialog";

const onClose = () => {};

const initialDataState: CustomConfirmationDialogProps = {
  title: "",
  content: "",
  icon: "",
  onClose: onClose,
  onConfirm: onClose,
  isConfirmForDelete: false,
  id: 0,
};

const confirmationBoxDataSlice = createSlice({
  name: "confirmationBox",
  initialState: initialDataState,
  reducers: {
    showConfirmation(
      state,
      action: PayloadAction<CustomConfirmationDialogProps>
    ) {
      state.content = action.payload.content;
      state.title = action.payload.title;
      state.icon = action.payload.icon;
      state.onClose = action.payload.onClose;
      state.onConfirm = action.payload.onConfirm;
      state.isConfirmForDelete = action.payload.isConfirmForDelete;
      state.id = action.payload.id;
    },
  },
});

export const { showConfirmation } = confirmationBoxDataSlice.actions;

export default confirmationBoxDataSlice;
