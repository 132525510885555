import React, {
  Fragment,
  useEffect,
  useState,
  KeyboardEvent,
  BaseSyntheticEvent,
} from "react";
import classes from "./CustomerPage.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import UploadIcon from "../assets/icons/upload.svg";
import search_icon from "../assets/icons/search_icon.svg";
import addProjectDataIcon from "../assets/icons/add_project_data_icon.svg";
import cloud_off from "../assets/icons/cloud_off.svg";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../common/hooks/hooks";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import { UploadFinishedReportDataModel } from "../common/store/uploadFinishedReportsDataSlice";
import { setUploadFinishedReportsData } from "../common/store/uploadFinishedReportsDataSlice";
import { Box, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import { deleteAllRecommendations } from "../common/store/addRecommendationsDataSlice";
import ContriesAutoComplete from "../common/components/CustomComponents/CountriesAutoComplete";
import { useDispatch } from "react-redux";
import { useCountries } from "../services/countries";
import { OperationPermissions } from "../common/models/operationPermission";
import { PageConstants } from "../common/PageConstants";
import PermissionService from "../services/PermissionService";
import LinkProject from "./LinkProject";
import { RoutingConstants } from "../common/RoutingConstants";
import CustomRibbon from "../common/components/CustomComponents/CustomRibbon";
import search_customer_graphic from "../assets/images/search_customer_graphic.svg";
import noDataFound from "../assets/images/NoDataFound.png";
import { tab_Style } from "../common/TabStyleConstants";

// import NoDataFound from "../assets/images/NoDataFound.png";
export type tableColumns =
  | "c4CId"
  | "customerName"
  | "country"
  | "city"
  | "address"
  | "soldTo";

interface Column {
  id: tableColumns; //keyof typeof tableColumns;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
  filter: true | false;
}

export interface CustomerSearch {
  customerName?: string;
  city?: string;
  country: string;
  externalId?: number;
  c4CId?: number;
}

const paraMessges: any[] = [
  <p>
    {" "}
    <b>ERROR</b> Server Down
  </p>,
  <p>
    Intelligence Hub server is currently down. Please try again after some time.{" "}
  </p>,
];

const customerSearchfields = [
  {
    name: "customerName",
    label: "Name",
  },
  {
    name: "city",
    label: "City",
  },
  {
    name: "country",
    label: "Country",
  },
  {
    name: "c4CId",
    label: "Account ID",
  },
  {
    name: "externalId",
    label: "External Id (Sold To)",
  },
];

const columns: readonly Column[] = [
  {
    id: "c4CId" as tableColumns,
    label: "Account ID",
    align: "left",
    minWidth: 90,
    filter: false,
  },
  {
    id: "customerName",
    label: "Customer Name",
    align: "left",
    minWidth: 250,
    filter: false,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: false,
  },
  {
    id: "city",
    label: "City",
    minWidth: 120,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: false,
  },
  {
    id: "address",
    label: "Address",
    minWidth: 220,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: false,
  },
  {
    id: "soldTo" as tableColumns,
    label: "External Id (Sold To)",
    align: "left",
    minWidth: 150,
    filter: false,
  },
];

interface CustomerData {
  c4CId: string;
  customerName: string;
  country: string;
  city: string;
  address: string;
  action: string;
  soldTo: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 0, border: "none", boxShadow: "none" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CustomerList() {
  const [notInitialRender, setNotInitialRender] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRcords, setTotalRcords] = useState(0);
  const [customerList, setCustomerList] = useState<Array<CustomerData>>([]);

  const request = useRequest();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const countries = useCountries();

  const [filterC4cId, setFilterC4cId] = useState<string>("");
  const [filterCustomerName, setFilterCustomerName] = useState<string>("");
  const [filterCountry, setFilterCountry] = useState<string>("");
  const [filterCity, setFilterCity] = useState<string>("");
  const [filterAddress, setFilterAddress] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [value, setValue] = React.useState(0);
  const [showErrorRibbon, setShowErrorRibbon] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const customerPagePermission: OperationPermissions = PermissionService(PageConstants.NewProject);

  function loadData() {
    setNotInitialRender(true);

    methods.trigger();
    let body = {
      page: filterC4cId ? setPage(0) : page,
      pageSize: rowsPerPage,
      c4CId: methods.getValues("c4CId") ? methods.getValues("c4CId") : "",
      customerName: methods.getValues("customerName")
        ? methods.getValues("customerName")?.toUpperCase()
        : "",
      country: methods.getValues("country") ? methods.getValues("country") : "",
      city: methods.getValues("city")
        ? methods.getValues("city")?.toUpperCase()
        : "",
      soldTo: methods.getValues("externalId")
        ? methods.getValues("externalId")
        : "",
      // address: filterAddress ? filterAddress : "",
    };

    request({
      url: RequestApi.GET_UPLOAD_FINISHED_REPORT_LIST,
      method: 'post',
      data: body,
    })
      .then((resp) => {
        let customerData = resp.data.data.map((customer: CustomerData) => ({
          ...customer,
          country: countries.getCountryName(customer.country),
        }));
        setCustomerList(customerData);
        setTotalRcords(resp.data.totalRecord);
        setShowErrorRibbon(false);
        //setAlert("success", "Success!", true);
        // setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 503) {
          setShowErrorRibbon(true);
        } else if (err.response.status === 409) {
          console.log(err);
          setShowErrorRibbon(false);
          // setAlert('error', 'Request Failed', true);
        } else {
          console.log(err);
          setShowErrorRibbon(false);
          setAlert("error", "Request Failed", true);
        }
      });
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  useEffect(() => {
    if (notInitialRender) {
      loadData();
    }
  }, [page]);

  function handleFilterChange(column: string, event: any) {
    if (column === "c4CId") {
      setFilterC4cId(event.target.value);
    }
    // } else if (column === "name") {
    //   setFilterCustomerName(event.target.value);
    // } else if (column === "address_country_iso2") {
    //   setFilterCountry(event.target.value);
    // } else if (column === "address_city") {
    //   setFilterCity(event.target.value);
    // } else if (column === "address_full") {
    //   setFilterAddress(event.target.value);
    // }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const uploadFinishedReport = (
    event: any,
    customerName: string,
    c4cId: string,
    city: string,
    country: string,
    address: string,
    soldTo: string,
    toUploadProject: boolean
  ) => {
    //event.preventDefault();
    dispatch(deleteAllRecommendations());
    var data: UploadFinishedReportDataModel = {
      customerName: customerName,
      c4cId: c4cId,
      auditId: 0,
    };
    dispatch(setUploadFinishedReportsData(data));

    window.sessionStorage.setItem("cn", customerName);
    window.sessionStorage.setItem("ci", c4cId);
    window.sessionStorage.setItem("ct", city);
    window.sessionStorage.setItem("con", country);
    window.sessionStorage.setItem("st", soldTo);
    window.sessionStorage.setItem("ad", address);

    navigation(
      "/" +
        RoutingConstants.NewProject +
        "/" +
        (toUploadProject
          ? RoutingConstants.UploadFinishedReport
          : RoutingConstants.UploadProjectData)
    );
    // alert("Click");
    // return <UploadFinishedReports></UploadFinishedReports>;
  };

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("This field is required"),
  });

  const methods = useForm<CustomerSearch>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    reValidateMode: "onBlur",
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      methods.reset();
    } else if (newValue === 1) {
    }
  };

  function loadSearchData() {
    page === 0 ? loadData() : !notInitialRender ? loadData() : setPage(0);
  }

  return (
    <Fragment>
      <Box
        sx={{
          paddingBottom: "0px",
          border: "none",
          marginBottom: "13px",
        }}
      >
        <Tabs
          sx={tab_Style}
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "none" }}
            label="Create Project"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: "none" }}
            label="Link Project"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={value}
        index={0}
      >
        <Box
          sx={{
            boxShadow: 0,
            borderBlock: 0,
            paddingTop: "15px !important",
            "&.MuiBox-root": {
              border: "none",
              backgroundColor: "#fff",
              borderRadius: "8px",
              paddingBottom: "8px",
              borderBottomLeftRadius: "0px",
            },
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(loadSearchData)}>
              {showErrorRibbon && (
                <Box
                  sx={{ boxShadow: 0, borderBottom: 0 }}
                  minWidth={"600px"}
                  px={1}
                  py={1}
                  className={classes["form-container"]}
                >
                  <CustomRibbon
                    messagesInHtmlArray={paraMessges}
                    md={12}
                    style={{
                      height: "auto",
                      border: "1px solid #C8102E",
                      marginTop: "8px",
                      backgroundColor: "rgba(242,101,34,5%)",
                      borderRadius: "5px",
                    }}
                    icon={cloud_off}
                    iconHeight={18}
                    paraColor={"#C8102E"}
                  />
                </Box>
              )}
              <Grid
                container
                spacing={2}
                px={1}
              >
                {customerSearchfields.map(({ name, label }, index) => {
                  if (name === "country") {
                    return (
                      <ContriesAutoComplete
                        setValue={methods.setValue}
                        data={countries.countriesData}
                      />
                    );
                  } else {
                    return (
                      <CustomInput
                        isRequired={false}
                        name={name}
                        label={label}
                        variant="outlined"
                        fullWidth
                      />
                    );
                  }
                })}

                <Grid
                  item
                  alignSelf="flex-end"
                >
                  <Button
                    disabled={!customerPagePermission.view}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={
                      <img
                        src={search_icon}
                        alt="search-icon"
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                        loading="lazy"
                        decoding="async"
                      />
                    }
                    sx={{
                      padding: "10px 12px !important",
                      color: "#FFF",
                      fontSize: 18,
                      fontWeight: 400,
                      height: "35px !important",
                      lineHeight: "20px",
                      textTransform: "none",
                      boxShadow: "none !important",
                      gap: "6px !important",
                    }}
                    disableRipple
                  >
                    Search
                  </Button>
                </Grid>
                {/* <Grid item alignSelf='flex-end'>
                  <Button
                    sx={{
                      padding: '10px 12px !important',
                      color: '#FFF',
                      fontSize: 18,
                      fontWeight: 400,
                      height: '35px !important',
                      lineHeight: '20px',
                      textTransform: 'none',
                      boxShadow: 'none !important',
                      gap: '6px !important',
                    }}
                    className='cancel-button custom-buttons'
                    disableRipple
                    style={{
                      fontSize: '18px',
                      fontWeight: '400',
                      marginRight: '15px',
                    }}
                    variant='outlined'
                  >
                    Create Without Customer
                  </Button>
                </Grid> */}
              </Grid>
            </form>
          </FormProvider>
        </Box>

        <Box
          sx={{
            boxShadow: 0,
            borderTop: 0,
            background: "#F9F9F9",
            border: "none",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[]}
            sx={{
              "& .MuiIconButton-root": {
                backgroundColor: "#f0f0f0",
                borderRadius: "5px  !important",
                padding: "2px  !important",
                margin: "2px  !important",
                height: "24px",
                width: "24px",
              },
              "& .MuiIconButton-root .Mui-disabled": {
                backgroundColor: "transparent",
              },
              "& .MuiTablePagination-toolbar": {
                minHeight: "30px",
                paddingRight: "10px",
              },
            }}
            component="div"
            count={totalRcords}
            rowsPerPage={rowsPerPage}
            showFirstButton
            showLastButton
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <Box style={{ border: "none" }}>
          <div className={classes["table-container"]}>
            {/* <div className={classes["pagination-container"]}  >
          
          </div> */}

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                sx={[
                  customerList.length === 0 && {
                    "& .MuiTable-root": {
                      height: "100% !important",
                    },
                  },
                ]}
                className={classes["table-container"]}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={`${classes["table-header"]} ${
                            classes["tabel-cell-border"]
                          } ${
                            column.label === "Name"
                              ? classes["stickyHeader-left"]
                              : ""
                          } ${
                            column.label === "Density"
                              ? classes["stickyHeader-right"]
                              : ""
                          }`}
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.minWidth }}
                        >
                          <span
                            style={{ width: column.minWidth }}
                            className={`${classes["table-title"]} ${classes["heading-cell-container"]}`}
                          >
                            {column.label}
                          </span>
                          {/* <TextField></TextField> */}
                          {column.filter && (
                            // <CustomInput
                            //   name={column.id}
                            //   label=""
                            //   isRequired={false}
                            // ></CustomInput>
                            <TextField
                              // onChange={(event) => {
                              //   setFilterC4cId(event.target.value);
                              // }}
                              onKeyDown={(
                                event: KeyboardEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.key === "Tab" ||
                                  event.key === "Enter"
                                ) {
                                  if (
                                    filterC4cId.length >= 3 ||
                                    filterC4cId.length === 0
                                  ) {
                                    loadData();
                                  }
                                } else {
                                  return;
                                }
                              }}
                              onChange={(event) =>
                                handleFilterChange(column.id, event)
                              }
                              sx={{ backgroundColor: "#FFF" }}
                            ></TextField>
                          )}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{ maxWidth: "130px" }}
                        className={`${classes["table-header"]} ${classes["tabel-cell-border"]}`}
                      >
                        <span
                          style={{ width: "110px" }}
                          className={classes["table-title"]}
                        >
                          Action
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerList.length === 0 && (
                      <>
                        <TableRow>
                          {!notInitialRender ? (
                            <TableCell
                              style={{
                                width: "100%",
                                textAlign: "center",
                              }}
                              colSpan={7}
                            >
                              {/*<div className={classes['search-image-container']}>*/}
                              {/*    <div></div>*/}
                              {/*    <p>Please search customer to view data</p>*/}
                              {/*</div>*/}
                              <div>
                                <img
                                  src={search_customer_graphic}
                                  alt="no-data-found"
                                  width="170px"
                                  height="170px"
                                  loading="lazy"
                                  decoding="async"
                                />
                                <p
                                  style={{
                                    fontSize: "1.5em",
                                    fontWeight: "700",
                                  }}
                                >
                                  Please search customer to view data
                                </p>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                width: "100%",
                                textAlign: "center",
                              }}
                              colSpan={9}
                            >
                              <img
                                src={noDataFound}
                                alt="no-data-found"
                                width="180px"
                                height="180px"
                                loading="lazy"
                                decoding="async"
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    )}
                    {customerList.length > 0 &&
                      customerList
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.c4CId}
                            >
                              {columns.map((column) => {
                                const columnName = column.id as tableColumns;
                                const value = row[columnName];
                                return (
                                  <TableCell
                                    className={classes["tabel-cell-border"]}
                                    // className={`${
                                    //   column.id === "account_id"
                                    //     ? classes["sticky-left"]
                                    //     : ""
                                    // }${
                                    //   column.label === "Density"
                                    //     ? classes["sticky-right"]
                                    //     : ""
                                    // }`}
                                  >
                                    <Tooltip
                                      title={value}
                                      placement="right"
                                      slotProps={{
                                        popper: {
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -25],
                                              },
                                            },
                                          ],
                                        },
                                      }}
                                    >
                                      <span
                                        style={{ width: column.minWidth }}
                                        className={`${classes["cell-container"]}`}
                                      >
                                        {column.format &&
                                        typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                );
                              })}
                              <TableCell
                                key={row.c4CId}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  // justifyContent: "space-around",
                                  // gap: ".625rem",
                                }}
                              >
                                {/* <Button
                                  disableRipple
                                  disabled={!customerPagePermission.create}
                                  style={{
                                    color: 'var(--primary-color)',
                                    paddingLeft: '0',
                                    paddingRight: '0',
                                  }}
                                  sx={{ textTransform: 'none' }}
                                  variant='text'
                                  onClick={(event) => {
                                    uploadFinishedReport(
                                      event,
                                      row.customerName,
                                      row.c4CId,
                                      row.city,
                                      row.country,
                                      row.address,
                                      row.soldTo,
                                      false
                                    );
                                  }}
                                >
                                  <img
                                    src={addProjectDataIcon}
                                    height={24}
                                    alt="add-project-data"
                                    loading="lazy"
                                    decoding="async"
                                  />
                                  Add Project Data
                                </Button> */}
                                <Button
                                  disableRipple
                                  disabled={!customerPagePermission.create}
                                  style={{
                                    color: "var(--primary-color)",
                                    paddingLeft: "0",
                                    paddingRight: "0",
                                  }}
                                  sx={{ textTransform: "none" }}
                                  variant="text"
                                  onClick={(event) => {
                                    uploadFinishedReport(
                                      event,
                                      row.customerName,
                                      row.c4CId,
                                      row.city,
                                      row.country,
                                      row.address,
                                      row.soldTo,
                                      true
                                    );
                                  }}
                                >
                                  <img
                                    src={UploadIcon}
                                    height={24}
                                    alt="Upload Project"
                                    loading="lazy"
                                    decoding="async"
                                  />
                                  Upload Project
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={1}
      >
        <LinkProject countries={countries} />
      </CustomTabPanel>
    </Fragment>
  );
}

export default CustomerList;
