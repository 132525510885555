import * as Yup from 'yup';
import { Box, Button, Dialog, FormLabel, Grid } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from '../common/components/CustomComponents/CustomInput';
import CustomTable from '../common/components/CustomComponents/CustomTable';
import { useLoaderData, useNavigate } from 'react-router';
import search_icon from '../assets/icons/search_icon.svg';
import classesAddCustomer from './AddCustomer.module.css';
import { RequestApi } from '../common/Constant';
import { useRequest } from '../services/agent';
import { PageConstants } from '../common/PageConstants';
import CloseIcon from "../assets/icons/close.svg";
import addCustomerIcon from "../assets/icons/add_Customer.svg";
import { UserRoleConstants } from '../common/UserRoleConstants';
import CustomAlert from '../common/components/CustomComponents/CustomAlert';
import CustomSelect from '../common/components/CustomComponents/CustomSelect';
import { TableColumn } from './AddCustomer';
import { TableActionButtons } from '../common/interface/TableColumn';
interface IUserData {
    userName: string;
    email?: string;
    phoneNumber?: string;
    role?: string;
    famCode?: string;
    country?: string;
    active?: boolean;
    lastLoginOn?: string;
}
interface AssignEnergySpecialistProps {
    open: boolean;
    onClose: () => any;
}

export type tableColumns =
    | "famCode"
    | "userName"
    | "country"
    | "isActive"
    | "phoneNumber"
    | "lastLoginOn"
    ;

function AssignEnergySpecialist(props: AssignEnergySpecialistProps) {
    const {
        open,
        onClose,
    } = props;
    const [getData, setData] = useState(true);
    const navigation = useNavigate();
    const [notInitialRender, setNotInitialRender] = useState(false);
    const request = useRequest();
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [alertMessage, setAlertMessage] = useState("");
    const userManagementList = useLoaderData() as any;
    const [famCode, setFAMCode] = useState<number>(0);

    const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
        "success"
    );
    const [alertOpen, setAlertOpen] = useState(false);
    const [userList, setUserList] = useState<Array<IUserData>>([]);
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required('This field is required')
    });

    const methods = useForm<IUserData>({
        resolver: yupResolver(validationSchema),
        mode: 'all',
        reValidateMode: 'onBlur',
    });
  
    const columns: TableColumn[] = [
        {
            id: "famCode" as tableColumns,
            label: "FAM Code",
            align: "left",
            minWidth: 230,
            filter: false,
            sorting: false
        },
        {
            id: "userName" as tableColumns,
            label: "User Name",
            align: "left",
            minWidth: 120,
            filter: false,
            sorting: false
        },
        {
            id: "country" as tableColumns,
            label: " Country",
            align: "left",
            minWidth: 150,
            filter: false,
            sorting: false
        },
        {
            id: "isActive" as tableColumns,
            label: "Status",
            align: "left",
            filter: false,
            sorting: false
        },
        {
            id: "phoneNumber" as tableColumns,
            label: "Contact Details",
            align: "left",
            filter: false,
            sorting: false
        },
        {
            id: "lastLoginOn" as tableColumns,
            label: "Last Logon Date",
            align: "left",
            filter: false,
            sorting: false
        }
    ];
    function handleClick() {
       
    }
    function setAlert(severity: any, message: string, OCflag: boolean) {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(OCflag);
    }

    const close = () => {
        onClose();
    };

    function loadData() {
        let body = {
            page: page,
            pageSize: 20,
            userName: methods.getValues('userName') ? methods.getValues('userName') : '',
            famCode: famCode ? famCode : null,
            role: UserRoleConstants.LOCAL_USER,
            sort: "",
            order: "asc",
            email: "",
            phoneNumber: "",
            country: "",
            isActive: null,
            lastLogin: "",
        };

        request({
            url: RequestApi.GET_USER_LIST,
            method: "post",
            data: body,
        })
            .then((resp) => {
                setUserList(resp.data.data);
                setTotalRecords(resp.data.totalRecord);
            })
            .catch((err) => {
                if (err.code === "ERR_BAD_RESPONSE") {
                    console.log(err);
                    setAlert("error", "Request Failed", true);
                } else {
                    console.log(err);
                    setAlert("error", "Request Failed", true);
                }
            });
    }
    function loadSearchData() {
        page === 0 ? loadData() : !notInitialRender ? loadData() : setPage(0);
    }

    const handleChange = (event: any) => {
        setFAMCode(event);
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const actionButtons: TableActionButtons[] = [
        {
            name: "",
            handleClick: handleClick,
            permission: true,
            icon: addCustomerIcon,
            textInsideButton: <span style={{ marginLeft: "5px" }}>Assign</span>
        }
    ];

    return (
        <React.Fragment>
            <CustomAlert
                severity={alertSeverity}
                open={alertOpen}
                message={alertMessage}
                onClose={handleAlertClose}
            />
            <Dialog
                sx={{
                    right: '0',
                    '&.MuiModal-root>.MuiDialog-container>.MuiPaper-root': {
                        marginTop: '0',
                        marginInline: '0',
                        height: 'calc(90% - 32px)',
                        width: "1083px",
                        maxHeight: 'unset',
                        maxWidth: 'unset',
                        borderRadius: '1.25rem',
                        overflow: "hidden"
                    },
                    '&.MuiModal-root>.MuiDialog-container': {
                        justifyContent: 'center',
                    }
                }}
                open={open}

            >
                <div className={classesAddCustomer['title-container-right']}>
                    <img
                        style={{ cursor: 'pointer', float: 'right' }}
                        onClick={close}
                        src={CloseIcon}
                        height="35px"
                        alt="Back"
                        loading="lazy"
                        decoding="async"
                    ></img>
                </div>
                <Box sx={{
                    boxShadow: 0, borderBlock: 0, "&.MuiBox-root": {
                        border: "none",
                        backgroundColor: "#fff",
                        paddingLeft: "21px",
                        paddingRight: "29.27px",
                        borderRadius: "8px"
                    }
                }}>
                    <Grid item md={12} style={{ marginBottom: "13px" }} className={classesAddCustomer["page-title"]} >Assign Energy Specialist to the Project
                    </Grid>
                    <Grid container
                        style={{
                            marginBottom: "13px",
                        }}
                    >
                        <Grid md={2} style={{ borderRight: "1px solid #9F9F9F" }}>
                            <p style={{ fontWeight: 600, color: '#000000' }} >Customer Name</p>
                            <p style={{ marginTop: "16px" }}>Nestle Brazil</p>
                        </Grid>
                        <Grid md={0.28}></Grid>
                        <Grid md={2} style={{ borderRight: "1px solid #9F9F9F" }}>
                            <p style={{ fontWeight: 600, color: '#000000' }}>City</p>
                            <p style={{ marginTop: "16px" }}>Brazil</p>
                        </Grid>
                        <Grid md={0.28}></Grid>
                        <Grid md={2}>
                            <p style={{ fontWeight: 600, color: '#000000' }}>FAM Code</p>
                            <p style={{ marginTop: "16px" }}>BOQ</p>
                        </Grid>
                    </Grid>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(loadSearchData)}>
                            <Grid
                                container
                                spacing={2.2}
                            >
                                <CustomInput
                                    isRequired={true}
                                    name={"userName"}
                                    label={"Name"}
                                    variant="outlined"
                                    fullWidth
                                />
                                <CustomSelect
                                    name={"famCode"}
                                    label={"FAM Code"}
                                    setValue={methods.setValue}
                                    options={userManagementList.famCodesList}
                                    isRequired={false}
                                    md={4}
                                    customHandleChange={handleChange}
                                />

                                <Grid
                                    item
                                    style={{marginTop: "20px"}}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        startIcon={
                                            <img
                                                src={search_icon}
                                                alt="search-icon"
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                                loading="lazy"
                                                decoding="async"
                                            />
                                        }
                                        sx={{
                                            padding: '10px 12px !important',
                                            color: '#FFF',
                                            fontSize: 18,
                                            fontWeight: 400,
                                            height: '35px !important',
                                            lineHeight: '20px',
                                            textTransform: 'none',
                                            boxShadow: 'none !important',
                                            gap: '6px !important',
                                        }}
                                        disableRipple
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>
                <Box style={{ border: "none", marginTop: "12px"}}>
                    <CustomTable
                        data={userList}
                        isInsideTab={true}
                        columns={columns}
                        actionColumnWidth={"300px"}
                        setGetData={setData}
                        showPagination={false}
                        stickyActionColumn={false}
                        showFilters={false}
                        actionButtons={actionButtons}
                        pageName={PageConstants.AssignEnergySpecialist}
                    />
                    </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default AssignEnergySpecialist;