import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../src/common/interface/TableColumn";
import DropdownModel from "../../src/common/interface/DropdownModel";
import CustomTable from "../../src/common/components/CustomComponents/CustomTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageConstants } from "../../src/common/PageConstants";
import { AuditTypes } from "../../src/common/AuditTypeConstants";
import dayjs from "dayjs";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import classes from "../features/FinishedReports/FinishedReports.module.css";
import userEditIcon from "../assets/icons/edit_user.svg";
import { ShowAuditTypesOnTabConstants } from "../common/AuditTypeConstants";
import { tab_Style } from "../../src/common/TabStyleConstants";
import AddCustomer from "./AddCustomer";
import AssignEnergySpecialist from "./AssignEnergySpecialist";
import { CustomTabPanel, a11yProps } from "./FinishedReports/FinishedReports";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";

export type tableColumns =
  | "auditId"
  | "status"
  | "uploadedDate"
  | "initiatedBy"
  | "customerName"
  | "famCode"
  | "responsible"
  | "country"
  | "city";

const anchorStyle = {
  textDecoration: "none",
  color: "#0099CC",
};

interface IAirAuditData {
  id: number;
  auditId: number;
  status: string;
  uploadedDate: string;
  initiatedBy: string;
  customerName: string;
  famCode: string;
  responsible: string;
  country: string;
  city: string;
  c4CId: number;
  customerId: number;
}

const OngoingProject = () => {
  const [value, setValue] = useState(0);

  const [airAuditData, setAirAuditData] = useState<Array<IAirAuditData>>([]);
  const [airAuditFilterUploadedDate, setAirAuditFilterUploadedDate] =
    useState<string>("");
  const [airFilterLocalDate, setAirFilterLocalDate] = useState<string>("");
  const [airAuditFilterAuditId, setAirAuditFilterAuditId] =
    useState<string>("");
  const [airAuditFilterInitiator, setAirAuditFilterInitiator] =
    useState<string>("");
  const [airAuditFilterResponsible, setAirAuditFilterResponsible] =
    useState<string>("");

  const [airAuditFilterCustomer, setAirAuditFilterCustomer] =
    useState<string>("");
  const [airAuditFilterCity, setAirAuditFilterCity] = useState<string>("");
  const [airAuditFilterCountry, setAirAuditFilterCountry] =
    useState<string>("");
  const [airAuditPage, setAirAuditPage] = useState(0);
  const [airAuditSortingColumn, setAirAuditSortingColumn] =
    useState<string>("");
  const [airAuditFilterStatus, setAirAuditFilterStatus] = useState("");
  const [airAuditFilterFamCode, setAirAuditFilterFamCode] = useState("");
  const [airAuditSortingOrder, setAirAuditSortingOrder] = useState<string>("");
  const [airAuditTotalRecords, setAirAuditTotalRecords] = useState<number>(0);
  const [getAirData, setGetAirData] = useState(false);
  const [auditId, setAuditId] = useState(0);
  const [airColumns, setAirColum] = useState<TableColumn[]>([]);
  const request = useRequest();

  const [open, setOpen] = useState(false);
  const [openAssignEnergySpecialist, setOpenAssignEnergySpecialist] =
    useState(false);

  const handleAirFilterChange = (column: string, event: any) => {
    if (column === "status") {
      setAirAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setAirAuditFilterUploadedDate(dayjs(event.$d).format("DD/MM/YYYY"));
        setAirFilterLocalDate(event.$d);
      } else {
        setAirAuditFilterUploadedDate("");
        setAirFilterLocalDate("");
      }
    } else if (column === "auditId") {
      setAirAuditFilterAuditId(event.target.value);
    } else if (column === "initiatedBy") {
      setAirAuditFilterInitiator(event.target.value);
    } else if (column === "responsible") {
      setAirAuditFilterResponsible(event.target.value);
    } else if (column === "customerName") {
      setAirAuditFilterCustomer(event.target.value);
    } else if (column === "city") {
      setAirAuditFilterCity(event.target.value);
    } else if (column === "country") {
      setAirAuditFilterCountry(event.target.value);
    } else if (column === "famCode") {
      setAirAuditFilterFamCode(event.target.value);
    }
  };

  const handleEditClick = (event: React.SyntheticEvent) => {};

  const actionButtons: TableActionButtons[] = [
    {
      name: "Edit User",
      icon: userEditIcon,
      handleClick: handleEditClick,
      permission: true,
    },
  ];

  const airAuditFilterObject: any = useMemo(
    () => ({
      famCode: airAuditFilterFamCode,
      auditId: airAuditFilterAuditId,
      uploadedDate: dayjs(airFilterLocalDate).isValid()
        ? dayjs(airFilterLocalDate)
        : "",
      initiatedBy: airAuditFilterInitiator,
      responsible: airAuditFilterResponsible,
      status: airAuditFilterStatus,
      customerName: airAuditFilterCustomer,
      city: airAuditFilterCity,
      country: airAuditFilterCountry,
    }),
    [
      airAuditFilterAuditId,
      airAuditFilterStatus,
      airAuditFilterInitiator,
      airAuditFilterFamCode,
      airAuditFilterResponsible,
      airFilterLocalDate,
      airAuditFilterCustomer,
      airAuditFilterCity,
      airAuditFilterCountry,
    ]
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
    } else if (newValue === 1) {
    }
  };
  function handleDialogClose() {
    setOpen(false);
    setOpenAssignEnergySpecialist(false);
    loadAirAuditData();
  }

  const clearAllAirAuditSorting = useCallback(() => {
    setAirAuditPage(0);
    setAirAuditFilterAuditId("");
    setAirAuditFilterStatus("");
    setAirAuditFilterInitiator("");
    setAirAuditFilterFamCode("");
    setAirAuditFilterResponsible("");
    setAirAuditFilterStatus("");
    setAirAuditSortingColumn("");
    setAirAuditSortingOrder("");
    setAirFilterLocalDate("");
    setAirAuditFilterUploadedDate("");
    setAirAuditFilterCustomer("");
    setAirAuditFilterCity("");
    setAirAuditFilterCountry("");
    setGetAirData(true);
  }, []);

  const loadAirAuditData = useCallback((): void => {
    let body = {
      page: airAuditPage,
      pageSize: 20,
      sort: airAuditSortingColumn,
      order: airAuditSortingOrder,
      famCode: airAuditFilterFamCode ? airAuditFilterFamCode : "",
      auditId: airAuditFilterAuditId ? airAuditFilterAuditId : "",
      initiatedBy: airAuditFilterInitiator ? airAuditFilterInitiator : "",
      responsible: airAuditFilterResponsible ? airAuditFilterResponsible : "",
      uploadedDate: airAuditFilterUploadedDate
        ? airAuditFilterUploadedDate === "clear"
          ? ""
          : airAuditFilterUploadedDate
        : "",
      status: airAuditFilterStatus ? airAuditFilterStatus : "",
      customerName: airAuditFilterCustomer ? airAuditFilterCustomer : "",
      city: airAuditFilterCity ? airAuditFilterCity : "",
      country: airAuditFilterCountry ? airAuditFilterCountry : "",
    };

    request({
      url: RequestApi.GET_AIR_AUDIT_ONGOING_REPORT_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setAirAuditData(resp.data.data);
        setAirAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        // setAlert("error", "Request Failed", true);
      });
  }, [
    airAuditFilterAuditId,
    airAuditFilterStatus,
    airAuditFilterFamCode,
    airAuditFilterInitiator,
    airAuditFilterCity,
    airAuditFilterCountry,
    airAuditFilterCustomer,
    airAuditFilterResponsible,
    airAuditFilterUploadedDate,
    airAuditPage,
    airAuditSortingColumn,
    airAuditSortingOrder,
    request,
  ]);

  useEffect(() => {
    loadAirAuditData();
  }, []);

  useEffect(() => {
    if (getAirData == true) {
      loadAirAuditData();
    }
    return () => {
      setGetAirData(false);
    };
  }, [loadAirAuditData, getAirData]);

  useEffect(() => {
    const airColumns: TableColumn[] = [
      {
        id: "auditId" as tableColumns,
        label: "Project ID",
        align: "left",
        minWidth: 92,
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "status" as tableColumns,
        label: "Status",
        minWidth: 160,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
        filter: true,
      },
      {
        id: "uploadedDate" as tableColumns,
        label: "Creation Date",
        minWidth: 145,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
        filter: true,
        filterType: "datepicker",
        sorting: true,
        datePickerMode: DatePickerMode.MonthYear,
      },
      {
        id: "initiatedBy" as tableColumns,
        label: "Initiator",
        minWidth: 127,
        align: "left",
        filter: true,
        sorting: true,
      },
      {
        id: "customerName" as tableColumns,
        label: "Customer",
        minWidth: 138,
        align: "left",
        format: (value: number) => value.toFixed(2),
        filter: true,
        sorting: true,
        htmlElement: (value: string = "Add Customer", row: any) => (
          <a
            style={anchorStyle}
            href="#"
            onClick={(e) => {
            e.preventDefault();
            setAuditId(row.id);
            setOpen(true);
            }}
          >
            {value}
          </a>
        ),
      },
      {
        id: "famCode" as tableColumns,
        label: "FAM Code",
        minWidth: 119,
        align: "left",
        filter: true,
      },
      {
        id: "responsible" as tableColumns,
        label: "Responsible Person",
        minWidth: 158,
        align: "left",
        filter: true,
        sorting: true,
        htmlElement: (value: string = "Assign") => (
          <a
            style={anchorStyle}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpenAssignEnergySpecialist(true);
            }}
          >
            {value}
          </a>
        ),
      },
      {
        id: "country" as tableColumns,
        label: "Country",
        minWidth: 113,
        align: "left",
        format: (value: number) => value.toFixed(2),
        filter: true,
        sorting: true,
      },
      {
        id: "city" as tableColumns,
        label: "City",
        minWidth: 124,
        align: "left",
        format: (value: number) => value.toFixed(2),
        filter: true,
        sorting: true,
      },
    ];
    setAirColum(airColumns);
  }, [airAuditData]);
  
    return (
        <>
            <Tabs
                sx={tab_Style}
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
            >
                <Tab
                    sx={{ textTransform: "none" }}
                    label={ShowAuditTypesOnTabConstants.Air_Scan}
                    {...a11yProps(0)}
                />
            </Tabs>

      <Grid
        md={12}
        style={{ height: "13px", backgroundColor: "#F3F3F3" }}
      ></Grid>
      <CustomTabPanel value={value} index={0}>
        <CustomTable
          isInsideTab={true}
          data={airAuditData}
          filterChange={handleAirFilterChange}
          actionButtons={actionButtons}
          columns={airColumns}
          filterObject={airAuditFilterObject}
          setPage={setAirAuditPage}
          sortColumn={airAuditSortingColumn}
          setSortColumn={setAirAuditSortingColumn}
          sortColumnOrder={airAuditSortingOrder}
          setSortColumnOrder={setAirAuditSortingOrder}
          page={airAuditPage}
          clearAll={clearAllAirAuditSorting}
          finishedReportsPage={true}
          stickyActionColumn={true}
          actionColumnWidth={"155px"}
          loadDataFunction={loadAirAuditData}
          totalRecords={airAuditTotalRecords}
          setGetData={setGetAirData}
          typeOfAudit={AuditTypes.Air_Audit}
          pageName={PageConstants.OngoingProject}
        />
       </CustomTabPanel>
       <AddCustomer open={open} auditId={auditId} onClose={handleDialogClose} />
      <AssignEnergySpecialist
        open={openAssignEnergySpecialist}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default OngoingProject;
