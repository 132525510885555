import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
  Button,
  Dialog,
  Typography,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SavingsSaved from "../assets/images/savings_saved.svg";
import CloseIcon from "../assets/icons/close.svg";
import EditIcon from "../assets/icons/edit-icon.svg";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import DeleteIcon from "../assets/icons/delete-icon.svg";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import BackIcon from "../assets/icons/back_arrow-icon.svg";
import noDataFound from "../assets/images/NoDataFound.png";
import classes from "./EditSavingsInformation.module.css";
import { TableColumn } from "../common/interface/TableColumn";
import ConfirmDelete from "../common/components/CustomComponents/ConfirmDelete";
import AddRecommendationsdailog from "../common/components/CustomComponents/AddRecommendationsdailog";
import { useRequest } from "../services/agent";
import { RequestApi } from "../common/Constant";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../common/hooks/hooks";
import { hideLoader, showLoader } from "../common/store/loaderDataSlice";
import { CustomConfirmationDialogProps } from "../common/components/CustomComponents/ConfirmationDialog";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";

import { AuditRecommendations } from "../common/store/addRecommendationsDataSlice";
import { IRecommendationData } from "./RecommendationsList";
import { OperationPermissions } from "../common/models/operationPermission";
import { PageConstants } from "../common/PageConstants";
import PermissionService from "../services/PermissionService";

type EditSavingsProps = {
  open: boolean;
  handleClose: () => void;
  auditRecommendationId: number | null;
  totalQuotedValue: number;
  auditId: number;
  auditNumber: string;
};

interface IActualSavings {
  totalQuotedValue: number;
  totalExpectedValue: number;
}
export default function EditSavingsInformation({
  open,
  handleClose,
  auditRecommendationId,
  totalQuotedValue,
  auditId,
  auditNumber,
}: EditSavingsProps) {
  const [data, setData] = useState<Array<IRecommendationData>>([]);
  const [currency, setCurrency] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editData, setEditData] = useState<AuditRecommendations>();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [actualSavings, setActualSavings] = useState<IActualSavings>();

  const [recommendationDialogOpen, setRecommendationDialogOpen] =
    useState(false);
  const dispatchCancelPopup = useAppDispatch();

  const request = useRequest();

  const columns: TableColumn[] = [
    {
      id: "typeOfInsestment",
      label: "Category of Product",
      align: "left",
      minWidth: 160,
      filter: false,
    },
    {
      id: "typeOfRecommendation",
      label: "Type of Product",
      align: "left",
      minWidth: 150,
      filter: false,
    },
    {
      id: "description",
      label: "Description of Product",
      minWidth: 150,
      align: "left",
      filter: false,
    },
    {
      id: "approxInvestment",
      label: `Approx. Investment(${currency})`,
      minWidth: 100,
      align: "left",
      filter: false,
    },
    {
      id: "minimumEstimatedSaving",
      label: "Minimum Estimated Savings (kWh/annum)",
      minWidth: 120,
      align: "left",
      filter: false,
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 200,
      align: "left",
      filter: false,
    },
    // {
    //   id: 'paybackMonths',
    //   label: 'Simple Payback Months',
    //   minWidth: 140,
    //   align: 'left',
    //   filter: false,
    // },
  ];

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const GeneratedOpportunitiesPermission: OperationPermissions =
    PermissionService(PageConstants.GeneratedOpportunities);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const totalEstimate = useMemo(
    () =>
      data?.reduce(
        (total, { minimumEstimatedSaving }) =>
          total + Number(minimumEstimatedSaving),
        0
      ),
    [data]
  );

  const getOpportunityRecommendations = useCallback(() => {
    request({
      url: RequestApi.GET_OPPORTUNITY_RECOMMENDATIONS,
      method: "post",
      data: { auditRecommendationId, auditId },
    })
      .then((resp) => {
        setData(resp.data.data);
        setCurrency(resp.data.currency);
        setActualSavings({
          totalQuotedValue: resp.data.totalQuotedValue,
          totalExpectedValue: resp.data.totalExpectedValue,
        });
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
        console.log(err);
      });
  }, [auditId, auditRecommendationId, request]);

  const getOpportunityRecommendationById = async (id: number) => {
    await request({
      url: RequestApi.GET_OPPORTUNITY_RECOMMENDATION_BY_ID,
      method: "post",
      data: { id },
    })
      .then((resp) => {
        setEditData(resp.data);
      })
      .catch((err) => {
        setAlert("error", "Request Failed", true);
        console.log(err);
      });
  };

  const deleteOpportunityRecommendationById = useCallback(
    async (
      id: number,
      auditId: number,
      auditRecommendationId: number | null
    ) => {
      await request({
        url: RequestApi.DELETE_OPPORTUNITY_RECOMMENDATION_BY_ID,
        method: "post",
        data: { id, auditId, auditRecommendationId },
      })
        .then(() => {
          setIsDeleting(true);
          setOpenSuccessDialog(true);
        })
        .catch((err) => {
          setAlert("error", "Request Failed", true);
          console.log(err);
        });
    },
    [request]
  );

  useEffect(() => {
    if (open) {
      if (auditRecommendationId) {
        getOpportunityRecommendations();
      }
    }
  }, [
    auditRecommendationId,
    getOpportunityRecommendations,
    open,
    openSuccessDialog,
  ]);

  const handleSuccessDialogClose = (): void => {
    setOpenSuccessDialog(false);
    setIsDeleting(false);

    // setOpen(false);
  };

  const handleAddRecommendationClose = () => {
    setRecommendationDialogOpen(false);
  };
  const handleAddRecommendationOpen = () => {
    setEdit(false);
    setRecommendationDialogOpen(true);
  };

  const handleEditRecommendationOpen = async (event: any, row: any) => {
    event.stopPropagation();
    await getOpportunityRecommendationById(row.id);
    setEdit(true);
    setRecommendationDialogOpen(true);
  };

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  async function onConfirmCancelSave(id: number | undefined) {
    if (id) {
      dispatchCancelPopup(hideLoader());
      await deleteOpportunityRecommendationById(
        id,
        auditId,
        auditRecommendationId
      );
    }
  }

  function handleCancelClickOpen(event: any, row: any) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose the selected product recommendation.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
      id: row.id,
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  }

  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      sx={{
        "&.MuiModal-root>.MuiDialog-container>.MuiPaper-root": {
          maxHeight: "unset",
          borderRadius: "1.25rem",
          boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#FFF",
        },
      }}
    >
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />

      <div className={classes["Edit-savings-container"]}>
        <Stack
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <div
            className={`${classes["flex-row-center"]} ${classes["header-with-icon"]}`}
          >
            <img
              className={classes["back-icon"]}
              src={BackIcon}
              alt="back-icon"
              onClick={handleClose}
              loading="lazy"
              decoding="async"
            />
            <Typography className={`${classes["edit-savings-heading"]}`}>
              Edit Products
            </Typography>
            <div className={classes["audit-number-container"]}>
              <div className={classes["audit-number"]}>
                <span className={classes["fw-600"]}>Project ID:</span>
                {auditNumber}
              </div>
            </div>
          </div>
          <img
            className={classes["close-icon"]}
            src={CloseIcon}
            alt="close-icon"
            onClick={handleClose}
            loading="lazy"
            decoding="async"
          />
        </Stack>
        <div className={classes["wrapper-table-scroll"]}>
          <Box
            border={"none"}
            height={"21rem"}
            overflow={"auto"}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ borderRadius: ".25rem" }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      className={`${classes["table-header"]} ${classes["tabel-cell-border"]} `}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <p className="tablethsec">
                        <span className={classes["table-title"]}>
                          {column.label}
                        </span>
                      </p>
                    </TableCell>
                  ))}
                  <TableCell
                    style={{ minWidth: "100px" }}
                    className={`${classes["table-header"]} ${classes["stickyHeader-right"]}  ${classes["tabel-cell-border"]}`}
                  >
                    <span className={classes["table-title"]}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 && (
                  <>
                    <TableRow>
                      <TableCell
                        className={classes["tabel-cell-border"]}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                        colSpan={7}
                      >
                        <img
                          src={noDataFound}
                          alt="no-data"
                          width="130px"
                          height="130px"
                          loading="lazy"
                          decoding="async"
                        />
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {data.length > 0 &&
                  data.map((row: any) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column, i) => {
                          const columnName = column.id;
                          const value = row[columnName];
                          return (
                            <TableCell className={classes["tabel-cell-border"]}>
                              <Tooltip
                                title={value}
                                placement="right"
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -25],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <span
                                  style={{ width: column.minWidth }}
                                  className={`${classes["cell-container"]}`}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </span>
                              </Tooltip>
                            </TableCell>
                          );
                        })}
                        <TableCell
                          key={row.id}
                          className={classes["sticky-right"]}
                        >
                          <img
                            style={{ marginRight: "10px" }}
                            src={EditIcon}
                            height="24"
                            className={
                              GeneratedOpportunitiesPermission.update
                                ? "action-icons"
                                : "disabled-icons"
                            }
                            alt="edit product"
                            loading="lazy"
                            decoding="async"
                            onClick={(event) => {
                              if (GeneratedOpportunitiesPermission.update) {
                                handleEditRecommendationOpen(event, row);
                              }
                            }}
                          ></img>
                          <img
                            src={DeleteIcon}
                            height="24"
                            className={
                              data.length > 1 &&
                              GeneratedOpportunitiesPermission.delete
                                ? "action-icons"
                                : "disabled-icons"
                            }
                            alt="delete product"
                            loading="lazy"
                            decoding="async"
                            onClick={(event) => {
                              if (
                                data.length > 1 &&
                                GeneratedOpportunitiesPermission.delete
                              ) {
                                handleCancelClickOpen(event, row);
                              }
                            }}
                          ></img>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </div>
        <div>
          <Grid
            container
            spacing={2}
            margin={"0.25rem 0 1rem 0"}
            position={"relative"}
            left={"-16px"}
          >
            <Grid item>
              <p className={classes["quoted-value-heading"]}>
                {` Total ${
                  actualSavings?.totalQuotedValue ? "Quoted" : "Expected"
                } Value In C4C (${currency})`}
              </p>
              <p className={classes["quoted-value"]}>
                {actualSavings?.totalQuotedValue
                  ? actualSavings?.totalQuotedValue
                  : actualSavings?.totalExpectedValue}
              </p>
            </Grid>
            <Grid item>
              <p className={classes["quoted-value-heading"]}>
                {` Total Estimated Savings (${currency})`}
              </p>
              <p className={classes["quoted-value"]}>{totalEstimate}</p>
            </Grid>
          </Grid>
          <Grid
            style={{ marginBottom: "20px" }}
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              sx={{ textTransform: "none" }}
              className="cancel-button custom-buttons"
              disableRipple
              style={{
                fontSize: "18px",
                fontWeight: "400",
                marginRight: "15px",
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ fontSize: "18px", fontWeight: "400" }}
              className="custom-buttons"
              sx={{
                textTransform: "none",
                "&.Mui-disabled": {
                  background: "rgba(0, 0, 0, 0.12) !important",
                },
              }}
              variant="contained"
              disableRipple
              disabled={!GeneratedOpportunitiesPermission.create}
              size="medium"
              onClick={handleAddRecommendationOpen}
            >
              Add Product
            </Button>
          </Grid>
        </div>
        <SavedSuccessDailog
          open={openSuccessDialog}
          isDelete={isDeleting}
          edit={edit}
          onClose={handleSuccessDialogClose}
        />
        <AddRecommendationsdailog
          open={recommendationDialogOpen}
          onClose={handleAddRecommendationClose}
          isEdit={edit}
          selectedRecommendation={
            edit ? editData : ({} as AuditRecommendations)
          }
          fromOpportunity={true}
          auditRecommendationId={
            auditRecommendationId ? auditRecommendationId : 0
          }
          setOpenSuccessDialog={setOpenSuccessDialog}
          auditId={auditId}
        />
      </div>
    </Dialog>
  );
}

interface SavedSuccessDailogProps {
  open: boolean;
  edit: boolean;
  onClose: () => any;
  isDelete: boolean;
}

export function SavedSuccessDailog(props: SavedSuccessDailogProps) {
  const { onClose, open, edit, isDelete } = props;

  const close = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
        onClose={close}
        open={open}
      >
        <div
          className="iconimg"
          style={{ paddingTop: "1rem" }}
        >
          <img
            onClick={close}
            src={SavingsSaved}
            height="35"
            alt="saved-icon"
            loading="lazy"
            decoding="async"
          />
        </div>
        <img
          onClick={close}
          src={CloseIcon}
          alt="Back"
          loading="lazy"
          decoding="async"
          className={classes["inner-close-icon"]}
        />

        <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
          <ConfirmDelete
            message={`Product ${
              isDelete ? "deleted" : edit ? "updated" : "added"
            } successfully!`}
            textCss="bold-message-report"
            CloseDeleteDailog={close}
            CloseRecommendationDailog={close}
            btnText="Okay"
            btnCss="cancel-button custom-buttons"
            hideCancel={false}
            variantCSS="outlined"
          ></ConfirmDelete>
        </div>
      </Dialog>
    </>
  );
}
