import { Dialog, Grid, SxProps, Theme } from "@mui/material";

interface CustomRibbonProps {
  md: number;
  style: SxProps<Theme>;
  messagesInHtmlArray: any[];
  icon?: any;
  iconHeight?: number;
  paraColor: string;
}

const CustomMessageDialog = ({ md, style, messagesInHtmlArray, icon, iconHeight, paraColor }: CustomRibbonProps) => {

  return (
    <>
      {messagesInHtmlArray != null && messagesInHtmlArray.length > 0 && (
              <Grid md={md} lg={md} sx={style}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{
                          margin: "30px 28px 30px 28px",
                      }}>
                          <img
                              src={icon}
                              height={"auto"}
                              alt={icon}
                              loading="lazy"
                              decoding="async"
                          ></img>
                      </div>
                      <div style={{
                          margin: 0,
                          color: paraColor,
                          paddingTop: '12px',
                          paddingBottom: '12px'
                      }}>
                          {messagesInHtmlArray.map((message) => (
                              <p>{message}</p>
                          ))}
                      </div>
                  </div>
              </Grid>
      )}
    </>
  );
};

export default CustomMessageDialog;