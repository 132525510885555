import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuditRecommendations {
  rowNo: number;
  id: number;
  investmentId: number;
  investmentName: string;
  recommendationId: number;
  recommendationName: string;
  description: string;
  aproxInvestment: number;
  minimumEstimatedSaving: number;
  paybackMonths: number | null;
  followUpDate: string | null;
  sentToC4C: boolean;
  leadId?: number;
  opportunityId?: number;
  totalQuotedValue?:number;
  totalExpectedValue?:number;
  energySourceId: number;
  energyCost: number;
  energySourceUnitId: number;
  sourceCO2FactorId: number;
  cO2FactorUnitId: number;
  fromOpportunity?: boolean;
  comments:string;
}

interface RecommendationsListInterface {
  recommendations: AuditRecommendations[];
  showSavedAlert: boolean;
  showDeletedAlert: boolean;
}

const initialState: RecommendationsListInterface = {
  recommendations: [],
  showSavedAlert: false,
  showDeletedAlert: false,
};

const auditRecommendationsDataSlice = createSlice({
  name: 'recommendations',
  initialState: initialState,
  reducers: {
    addRecommendations: (
      state,
      action: PayloadAction<AuditRecommendations>
    ) => {
      let rowNo = 1;
      action.payload.rowNo = rowNo;
      if (state.recommendations.length > 0) {
        let index = Math.max(...state.recommendations.map((o) => o.rowNo));
        action.payload.rowNo = index + 1;
      }
      state.recommendations.push(action.payload);
      state.showSavedAlert = true;
    },

    addAllRecommendations: (
      state,
      action: PayloadAction<AuditRecommendations[]>
    ) => {
      state.recommendations = action.payload.map((recommendation, index) => {
        recommendation.rowNo = index + 1;
        return recommendation;
      });
    },
    updateRecommendations: (
      state,
      action: PayloadAction<AuditRecommendations>
    ) => {
      const index = state.recommendations.findIndex((recommendation) => {
        return recommendation.rowNo === action.payload.rowNo;
      });
      if (index > -1) {
        state.recommendations[index] = {...state.recommendations[index] ,...action.payload};
        state.showSavedAlert = true;
      }
    },
    deleteRecommendations: (
      state,
      action: PayloadAction<AuditRecommendations>
    ) => {
      console.log("add-all")
      const index = state.recommendations.findIndex(
        (x) => x.rowNo === action.payload.rowNo
      );
      if (index > -1) {
        state.recommendations.splice(index, 1);
        state.showDeletedAlert = true;
      }
    },
    deleteAllRecommendations: (state) => {
      state.recommendations = [];
    },
    hideSaveAlert: (state) => {
      state.showSavedAlert = false;
    },
    // hideDeleteAlert: (state) => {
    //   state.showDeletedAlert = false;
    // },
  },
});

export const {
  addRecommendations,
  updateRecommendations,
  deleteRecommendations,
  deleteAllRecommendations,
  hideSaveAlert,
  addAllRecommendations,
  // hideDeleteAlert,
} = auditRecommendationsDataSlice.actions;

export default auditRecommendationsDataSlice;
