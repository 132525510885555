import { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import { IAirAuditData } from "./FinishedReports";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../common/interface/TableColumn";
import dayjs from "dayjs";
import { PageConstants } from "../../common/PageConstants";
import { AuditTypes } from "../../common/AuditTypeConstants";
import { useRequest } from "../../services/agent";
import { RequestApi } from "../../common/Constant";

export interface IAirNetAuditData extends IAirAuditData {
  classificationOfAudit: string;
}

export type tableColumns =
  | "id"
  | "auditId"
  | "projectSource"
  | "uploadedDate"
  | "c4CId"
  | "customer"
  | "auditName"
  | "typeOfAudit"
  | "country"
  | "city"
  | "auditClassification"
  | "responsible";

const airNetcolumns: TableColumn[] = [
  {
    id: "auditId" as tableColumns,
    label: "Project ID",
    align: "left",
    minWidth: 150,
    filter: true,
    filterType: "text",
    sorting: true,
  },
  {
    id: "uploadedDate",
    label: "Upload Date",
    minWidth: 155,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
    filterType: "datepicker",
    sorting: true,
    datePickerMode: DatePickerMode.MonthYear,
  },
  {
    id: "c4CId",
    label: "Account ID",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 200,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditName",
    label: "Project Name",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditType",
    label: "Type Of Project",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
  },
  {
    id: "auditClassification",
    label: "Project Classification",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "city",
    label: "City",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },

  {
    id: "responsible",
    label: "Responsible",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
];

interface AirNetProp {
  actionButtons: TableActionButtons[];
  airNetAuditFilters: any;
}

export default function AirNet({
  actionButtons,
  airNetAuditFilters,
}: AirNetProp) {
  const [getAirNetData, SetGetAirNetData] = useState(false);
  const [airNetAuditData, setAirNetAuditData] = useState<
    Array<IAirNetAuditData>
  >([]);

  const [airNetAuditTotalRecords, setAirNetAuditTotalRecords] =
    useState<number>(0);

  const {
    airNetAuditFilterC4cId,
    setAirNetAuditFilterC4cId,
    airNetAuditFilterUploadedDate,
    setAirNetAuditFilterUploadedDate,
    airNetFilterLocalDate,
    setAirNetFilterLocalDate,
    airNetAuditFilterAuditId,
    setAirNetAuditFilterAuditId,
    airNetAuditFilterAuditType,
    setAirNetAuditFilterAuditType,
    airNetAuditFilterProjectSource,
    setAirNetAuditFilterProjectSource,
    airNetAuditFilterAuditClassification,
    setAirNetAuditFilterAuditClassification,
    airNetAuditFilterResponsible,
    setAirNetAuditFilterResponsible,
    airNetAuditFilterAuditName,
    setAirNetAuditFilterAuditName,
    airNetAuditFilterCustomer,
    setAirNetAuditFilterCustomer,
    airNetAuditFilterCity,
    setAirNetAuditFilterCity,
    airNetAuditFilterCountry,
    setAirNetAuditFilterCountry,
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
    airNetAuditPage,
    setAirNetAuditPage,
  } = airNetAuditFilters;

  const request = useRequest();

  const airNetAuditfilterObject: any = useMemo(
    () => ({
      c4CId: airNetAuditFilterC4cId,
      auditId: airNetAuditFilterAuditId,
      uploadedDate: dayjs(airNetFilterLocalDate).isValid()
        ? dayjs(airNetFilterLocalDate)
        : "",
      auditType: airNetAuditFilterAuditType,
      responsible: airNetAuditFilterResponsible,
      auditName: airNetAuditFilterAuditName,
      customerName: airNetAuditFilterCustomer,
      city: airNetAuditFilterCity,
      country: airNetAuditFilterCountry,
      projectSource: airNetAuditFilterProjectSource,
      auditClassification: airNetAuditFilterAuditClassification,
    }),
    [
      airNetAuditFilterAuditId,
      airNetAuditFilterAuditName,
      airNetAuditFilterAuditType,
      airNetAuditFilterC4cId,
      airNetAuditFilterResponsible,
      airNetFilterLocalDate,
      airNetAuditFilterCustomer,
      airNetAuditFilterCity,
      airNetAuditFilterCountry,
      airNetAuditFilterProjectSource,
      airNetAuditFilterAuditClassification,
    ]
  );

  const handleAirNetFilterChange = (column: string, event: any) => {
    if (column === "c4CId") {
      setAirNetAuditFilterC4cId(event.target.value);
    } else if (column === "status") {
      // setAirAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setAirNetAuditFilterUploadedDate(dayjs(event.$d).format("DD/MM/YYYY"));
        setAirNetFilterLocalDate(event.$d);
      } else {
        setAirNetAuditFilterUploadedDate("");
        setAirNetFilterLocalDate("");
      }
    } else if (column === "auditId") {
      setAirNetAuditFilterAuditId(event.target.value);
    } else if (column === "auditType") {
      setAirNetAuditFilterAuditType(event.target.value);
    } else if (column === "responsible") {
      setAirNetAuditFilterResponsible(event.target.value);
    } else if (column === "auditName") {
      setAirNetAuditFilterAuditName(event.target.value);
    } else if (column === "customerName") {
      setAirNetAuditFilterCustomer(event.target.value);
    } else if (column === "city") {
      setAirNetAuditFilterCity(event.target.value);
    } else if (column === "country") {
      setAirNetAuditFilterCountry(event.target.value);
    } else if (column === "projectSource") {
      setAirNetAuditFilterProjectSource(event.target.value);
    } else if (column === "auditClassification") {
      setAirNetAuditFilterAuditClassification(event.target.value);
    }
  };

  const loadAirNetAuditData = useCallback((): void => {
    let body = {
      page: airNetAuditPage,
      pageSize: 20,
      sort: airNetAuditSortingColumn,
      order: airNetAuditSortingOrder,
      c4CId: airNetAuditFilterC4cId ? airNetAuditFilterC4cId : "",
      auditId: airNetAuditFilterAuditId ? airNetAuditFilterAuditId : "",
      auditType: airNetAuditFilterAuditType ? airNetAuditFilterAuditType : "",
      responsible: airNetAuditFilterResponsible
        ? airNetAuditFilterResponsible
        : "",
      uploadedDate: airNetAuditFilterUploadedDate
        ? airNetAuditFilterUploadedDate === "clear"
          ? ""
          : airNetAuditFilterUploadedDate
        : "",
      auditName: airNetAuditFilterAuditName ? airNetAuditFilterAuditName : "",
      customerName: airNetAuditFilterCustomer ? airNetAuditFilterCustomer : "",
      city: airNetAuditFilterCity ? airNetAuditFilterCity : "",
      country: airNetAuditFilterCountry ? airNetAuditFilterCountry : "",
      projectSource: airNetAuditFilterProjectSource
        ? airNetAuditFilterProjectSource
        : "",
      auditClassification: airNetAuditFilterAuditClassification
        ? airNetAuditFilterAuditClassification
        : "",
    };

    request({
      url: RequestApi.GET_AIR_NET_AUDIT_FINISHED_REPORT_LIST,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setAirNetAuditData(resp.data.data);
        setAirNetAuditTotalRecords(resp.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
        // setAlert("error", "Request Failed", true);
      });
  }, [
    airNetAuditFilterAuditId,
    airNetAuditFilterAuditName,
    airNetAuditFilterAuditType,
    airNetAuditFilterC4cId,
    airNetAuditFilterCity,
    airNetAuditFilterCountry,
    airNetAuditFilterCustomer,
    airNetAuditFilterResponsible,
    airNetAuditFilterUploadedDate,
    airNetAuditPage,
    airNetAuditSortingColumn,
    airNetAuditSortingOrder,
    airNetAuditFilterProjectSource,
    airNetAuditFilterAuditClassification,
    request,
  ]);

  useEffect(() => {
    if (getAirNetData == true) {
      loadAirNetAuditData();
    }
    return () => {
      SetGetAirNetData(false);
    };
  }, [loadAirNetAuditData, getAirNetData]);

  useEffect(() => {
    loadAirNetAuditData();
  }, []);

  const clearAllAirNetAuditSorting = useCallback(() => {
    setAirNetAuditPage(0);
    setAirNetAuditFilterAuditId("");
    setAirNetAuditFilterAuditName("");
    setAirNetAuditFilterAuditType("");
    setAirNetAuditFilterC4cId("");
    setAirNetAuditFilterResponsible("");
    // setAirAuditFilterStatus('');
    setAirNetAuditSortingColumn("");
    setAirNetAuditSortingOrder("");
    setAirNetFilterLocalDate("");
    setAirNetAuditFilterUploadedDate("");
    setAirNetAuditFilterCustomer("");
    setAirNetAuditFilterCity("");
    setAirNetAuditFilterCountry("");
    setAirNetAuditFilterAuditClassification("");
    setAirNetAuditFilterProjectSource("");
    SetGetAirNetData(true);
  }, []);

  return (
    <CustomTable
      isInsideTab={true}
      data={airNetAuditData}
      filterChange={handleAirNetFilterChange}
      actionButtons={actionButtons}
      columns={airNetcolumns}
      filterObject={airNetAuditfilterObject}
      setPage={setAirNetAuditPage}
      sortColumn={airNetAuditSortingColumn}
      setSortColumn={setAirNetAuditSortingColumn}
      sortColumnOrder={airNetAuditSortingOrder}
      setSortColumnOrder={setAirNetAuditSortingOrder}
      page={airNetAuditPage}
      clearAll={clearAllAirNetAuditSorting}
      finishedReportsPage={true}
      stickyActionColumn={true}
      actionColumnWidth={"155px"}
      loadDataFunction={loadAirNetAuditData}
      totalRecords={airNetAuditTotalRecords}
      setGetData={SetGetAirNetData}
      typeOfAudit={AuditTypes.AirNet}
      pageName={PageConstants.FinishedReports}
    />
  );
}
