import * as Yup from 'yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from '../common/components/CustomComponents/CustomInput';
import ContriesAutoComplete from '../common/components/CustomComponents/CountriesAutoComplete';
import CustomTable from '../common/components/CustomComponents/CustomTable';
import DropdownModel from '../common/interface/DropdownModel';
import { DatePickerMode, TableActionButtons } from '../common/interface/TableColumn';
import { useNavigate } from 'react-router';
import search_icon from '../assets/icons/search_icon.svg';
import classes from './AddCustomer.module.css';
import { useCountries } from '../services/countries';
import { RequestApi } from '../common/Constant';
import { useRequest } from '../services/agent';
import { PageConstants } from '../common/PageConstants';
import CloseIcon from "../assets/icons/close.svg";
import addCustomerIcon from "../assets/icons/add_Customer.svg";
import CustomAlert from '../common/components/CustomComponents/CustomAlert';
export interface CustomerSearch {
    customerName?: string;
    city?: string;
    country: string;
    externalId?: number;
    c4CId?: number;
}
export interface TableColumn {
    id: string | number;
    label: string;
    minWidth?: number;
    align?: "right" | "left" | "center";
    format?: (value: number) => string;
    filter: true | false;
    filterType?: "text" | "dropdown" | "datepicker";
    dropdownData?: DropdownModel[];
    sortOrder?: any;
    sorting?: true | false;
    datePickerMode?: DatePickerMode;
}
interface CustomerData {
    c4CId: string;
    customerName: string;
    country: string;
    city: string;
    address: string;
    action: string;
    soldTo: string;
}
interface AddCustomerProps {
    open: boolean;
    auditId: number;
    onClose: () => any;
}
export type tableColumns =
    | "opportunityId"
    | "accountId"
    | "customerName"
    | "status"
    | "country"
    | "city"
    | "address"
    | "externalId"
    | "action"
    ;
function AddCustomer(props: AddCustomerProps) {
    const {
        open,
        auditId,
        onClose,
    } = props;
    const [getData, setData] = useState(true);
    const navigation = useNavigate();
    const [page, setPage] = useState(0);
    const [notInitialRender, setNotInitialRender] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [totalRcords, setTotalRcords] = useState(0);
    const countries = useCountries();
    const [filterC4cId, setFilterC4cId] = useState<string>('');
    const request = useRequest();
    const [customerList, setCustomerList] = useState<Array<CustomerData>>([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
        "success"
    );
    const [alertOpen, setAlertOpen] = useState(false);
    const validationSchema = Yup.object().shape({
        country: Yup.string().required('This field is required'),
    });

    const methods = useForm<CustomerSearch>({
        resolver: yupResolver(validationSchema),
        mode: 'all',
        reValidateMode: 'onBlur',
    });
    
    const customerSearchfields = [
        {
            name: 'customerName',
            label: 'Name',
        },
        {
            name: 'city',
            label: 'City',
        },
        {
            name: 'country',
            label: 'Country',
        },
        {
            name: 'c4CId',
            label: 'Account ID',
        },
        {
            name: 'externalId',
            label: 'External Id (Sold To)',
        },
    ];

    const columns: TableColumn[] = [
        {
            id: "c4CId" as tableColumns,
            label: "Account ID",
            align: "left",
            minWidth: 70,
            filter: false,
            sorting: false
        },
        {
            id: "customerName" as tableColumns,
            label: " Customer Name",
            align: "left",
            minWidth: 150,
            filter: false,
            sorting: false
        },
        {
            id: "country" as tableColumns,
            label: " Country",
            align: "left",
            minWidth: 70,
            filter: false,
            sorting: false
        },
        {
            id: "city" as tableColumns,
            label: " City",
            align: "left",
            minWidth: 150,
            filter: false,
            sorting: false
        },
        {
            id: "address" as tableColumns,
            label: " Address",
            minWidth:250,
            align: "left",
            filter: false,
            sorting: false
        },
        {
            id: "soldTo" as tableColumns,
            label: " External ID (Sold To)",
            align: "left",
            minWidth: 80,
            filter: false,
            sorting: false
        }
    ];

    function setAlert(severity: any, message: string, OCflag: boolean) {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(OCflag);
    }
    function handleAddCustomerClick(auditIdVal: number,
        auditNumber: string,
        event: any,
        row: any) {
        let payload = {
            c4CId: row.c4CId,
            customerName: row.customerName,
            country: row.country,
            city: row.city,
            address: row.address,
            action: "",
            soldTo: row.soldTo,
            auditId: auditId
        };
     
        request({
            url: RequestApi.ADD_CUSTOMER,
            method: 'post',
            data: payload,
        })
            .then((resp) => {
                setAlert("success", "Customer Added Successfully", true);
                methods.reset();
                close();
            })
            .catch((err) => {
                setAlert("error", "Request Failed", true);
            });
    }
    const close = () => {
        onClose();
        methods.reset();
        setCustomerList([]);
    };
    useEffect(() => {
        if (notInitialRender) {
            loadData();
        }
    }, [page]);
    function loadData() {
        setNotInitialRender(true);

        methods.trigger();
        let body = {
            page: filterC4cId ? setPage(0) : page,
            pageSize: rowsPerPage,
            c4CId: methods.getValues('c4CId') ? methods.getValues('c4CId') : '',
            customerName: methods.getValues('customerName')
                ? methods.getValues('customerName')?.toUpperCase()
                : '',
            country: methods.getValues('country') ? methods.getValues('country') : '',
            city: methods.getValues('city')
                ? methods.getValues('city')?.toUpperCase()
                : '',
            soldTo: methods.getValues('externalId')
                ? methods.getValues('externalId')
                : '',
            // address: filterAddress ? filterAddress : "",
        };

        request({
            url: RequestApi.GET_UPLOAD_FINISHED_REPORT_LIST,
            method: 'post',
            data: body,
        })
            .then((resp) => {
                let customerData = resp.data.data.map((customer: CustomerData) => ({
                    ...customer,
                    country: countries.getCountryName(customer.country),
                }));
                setTotalRcords(resp.data.totalRecord);
                setCustomerList(customerData);
            })
            .catch((err) => {
                setAlert(err, "Request Failed",true);
            });
    }
    function loadSearchData() {
       page === 0 ? loadData() : !notInitialRender ? loadData() : setPage(0);
    }

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const actionButtons: TableActionButtons[] = [
        {
            name: "",
            handleClick: handleAddCustomerClick,
            permission: true,
            icon: addCustomerIcon,
            text: PageConstants.AddCustomer,
            textInsideButton: <span style={{marginLeft: "5px"}}>Add Customer</span>
        }
    ];

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <React.Fragment>
            <CustomAlert
                severity={alertSeverity}
                open={alertOpen}
                message={alertMessage}
                onClose={handleAlertClose}
            />
            <Dialog
                sx={{
                    right: '0',
                    '&.MuiModal-root>.MuiDialog-container>.MuiPaper-root': {
                        marginTop: '0',
                        marginInline: '0',
                        height: 'calc(90% - 32px)',
                        width: "1083px",
                        maxHeight: 'unset',
                        maxWidth: 'unset',
                        borderRadius: '1.25rem',
                        overflow: "auto"
                    },
                    '&.MuiModal-root>.MuiDialog-container': {
                        justifyContent: 'center',
                    }
                }}
                open={open}
                
            >
                <div className={classes['title-container-right']}>
                    <img
                        style={{ cursor: 'pointer', float: 'right' }}
                        onClick={close}
                        src={CloseIcon}
                        height="35px"
                        alt="Back"
                        loading="lazy"
                        decoding="async"
                    ></img>
                </div>
                <Box sx={{
                    boxShadow: 0, borderBlock: 0, "&.MuiBox-root": {
                        border: "none",
                        backgroundColor: "#fff",
                        paddingLeft: "21px",
                        paddingRight: "29.27px",
                        borderRadius: "8px"
                    }
                }}>
                    <Grid item md={12} style={{ marginBottom: "13px" }} className={classes["page-title"]} >Customer Info
                    </Grid>
                    <Grid container
                        style={{
                            marginBottom: "13px",
                        }}
                    >
                        <Grid md={2} style={{ borderRight: "1px solid #9F9F9F" }}>
                            <p style={{ fontWeight: 600, color: '#000000'}} >Customer Name</p>
                            <p style={{ marginTop: "16px" }}>Nestle Brazil</p>
                        </Grid>
                        <Grid md={0.28}></Grid>
                        <Grid md={2} style={{ borderRight: "1px solid #9F9F9F" }}>
                            <p style={{ fontWeight: 600, color: '#000000' }}>Country</p>
                            <p style={{ marginTop: "16px" }}>Brazil</p>
                        </Grid>
                        <Grid md={0.28}></Grid>
                        <Grid md={2}>                  
                            <p style={{ fontWeight: 600, color: '#000000' }}>FAM Code</p>
                            <p style={{ marginTop: "16px" }}>BOQ</p>
                        </Grid> 
                     </Grid>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(loadSearchData)}>
                            <Grid
                                container
                                spacing={2.2}
                            >
                                {customerSearchfields.map(({ name, label }, index) => {
                                    if (name === 'country') {
                                        return (
                                            <ContriesAutoComplete
                                                setValue={methods.setValue}
                                                data={countries.countriesData}
                                            />
                                        );
                                    } else {
                                        return (
                                            <CustomInput
                                                isRequired={false}
                                                name={name}
                                                label={label}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        );
                                    }
                                })}

                                <Grid
                                    item
                                    alignSelf="flex-end"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        startIcon={
                                            <img
                                                src={search_icon}
                                                alt="search-icon"
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                }}
                                                loading="lazy"
                                                decoding="async"
                                            />
                                        }
                                        sx={{
                                            padding: '10px 12px !important',
                                            color: '#FFF',
                                            fontSize: 18,
                                            fontWeight: 400,
                                            height: '35px !important',
                                            lineHeight: '20px',
                                            textTransform: 'none',
                                            boxShadow: 'none !important',
                                            gap: '6px !important',
                                        }}
                                        disableRipple
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>
                <Box sx={{ boxShadow: 0, borderTop: 0, background: '#F9F9F9', border: "none", marginTop: "15px" }}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        sx={{
                            '& .MuiIconButton-root': {
                                backgroundColor: '#f0f0f0',
                                borderRadius: '5px  !important',
                                padding: '2px  !important',
                                margin: '2px  !important',
                                height: '24px',
                                width: '24px',
                            },
                            '& .MuiIconButton-root .Mui-disabled': {
                                backgroundColor: 'transparent',
                            },
                            '& .MuiTablePagination-toolbar': {
                                minHeight: '30px',
                                paddingRight: '10px',
                            },
                        }}
                        component="div"
                        count={totalRcords}
                        rowsPerPage={rowsPerPage}
                        showFirstButton
                        showLastButton
                        page={page}
                        onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                <Box style={{border: "none"}}>
                    <CustomTable
                    data={customerList}
                    isInsideTab={true}
                    columns={columns}
                    actionColumnWidth={"300px"}
                    setGetData={setData}
                    showPagination={false}
                    stickyActionColumn={false}
                    showFilters={false}
                    actionButtons={actionButtons}
                    pageName={PageConstants.AddCustomer}
                    />
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default AddCustomer;