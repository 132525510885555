import { Dialog, DialogTitle } from '@mui/material';
import { AuditRecommendations } from '../../store/addRecommendationsDataSlice';
import AddRecommendations from '../../../features/AddRecommendations';
import CloseIcon from '../../../assets/icons/close.svg';
import BackArrowIcon from '../../../assets/icons/back_arrow-icon.svg';
import classes from './AddRecommendationsdailog.module.css';
import DropdownModel from '../../interface/DropdownModel';

interface RecommendationsProps {
  open: boolean;
  isEdit: boolean;
  selectedRecommendation: AuditRecommendations | undefined;
  setOpenSuccessDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => any;
  fromOpportunity: boolean;
  currency?: DropdownModel[];
  auditRecommendationId: number;
  auditId?: number;
  isEditApiCall?: boolean;
  setIsApiSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  hideFields?: any;
}

function AddRecommendationsdailog(props: RecommendationsProps) {
  const {
    onClose,
    open,
    setOpenSuccessDialog,
    isEdit,
    currency,
    selectedRecommendation,
    fromOpportunity,
    auditRecommendationId,
    auditId,
    isEditApiCall,
    setIsApiSuccess,
    hideFields,
  } = props;

  const handleClose = () => {
    //onClose(selectedValue);
  };

  const Close = () => {
    onClose();
  };
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container.MuiDialog-scrollPaper': {
          position: 'absolute !important',
          right: '0 !important',
          top: '0 !important',
        },
        '& .MuiDialog-paper': {
          height: '100vh',
          borderRadius: '20px 0 0 20px',
          marginTop: '0',
          marginInline: '0',
          maxHeight: 'calc(100% - 32px)',
        },
        '& .MuiDialogTitle-root': {
          padding: '16px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>
        <div className={classes['dialog-title-container']}>
          <div className={classes['page-title']}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={Close}
              src={BackArrowIcon}
              height='20'
              alt='Back'
              loading='lazy'
              decoding='async'
            ></img>
            <div className={classes['add-recommendation-title']}>
              {isEdit ? 'Edit' : 'Add'} Product Recommendation
            </div>
          </div>
          <div>
            <img
              style={{ cursor: 'pointer', float: 'right' }}
              onClick={Close}
              src={CloseIcon}
              height='35'
              alt='Back'
              loading='lazy'
              decoding='async'
            ></img>
          </div>
        </div>
      </DialogTitle>
      <AddRecommendations
        fromOpportunity={fromOpportunity ? fromOpportunity : false}
        CloseDailog={Close}
        recommendationToEdit={selectedRecommendation}
        isEdit={isEdit}
        auditRecommendationId={auditRecommendationId}
        setOpenSuccessDialog={setOpenSuccessDialog}
        currency={currency}
        auditId={auditId}
        isEditApiCall={isEditApiCall}
        setIsApiSuccess={setIsApiSuccess}
        hideFields={hideFields}
      />
    </Dialog>
  );
}

export default AddRecommendationsdailog;
