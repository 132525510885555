import LowIcon from "./../../../assets/icons/low_icon.svg";
import MediumIcon from "./../../../assets/icons/medium_icon.svg";
import CriticalIcon from "./../../../assets/icons/critical_icon.svg";

interface CustomRangeColumnProps {
  CriticalResult: number;
  mediumResult: number;
  lowResult: number;
}

const CustomRangeColumn: React.FC<CustomRangeColumnProps> = ({
  CriticalResult,
  mediumResult,
  lowResult,
}) => {
  return (
    <div style={{ overflow: "hidden", width: "116px" }}>
      {Array.from(Array(CriticalResult), (e, i) => {
        return (
          <img
            src={CriticalIcon}
            alt=""
            loading="lazy"
            decoding="async"
          ></img>
        );
      })}

      {Array.from(Array(mediumResult), (e, i) => {
        return (
          <img
            src={MediumIcon}
            loading="lazy"
            decoding="async"
            alt=""
          ></img>
        );
      })}

      {Array.from(Array(lowResult), (e, i) => {
        return (
          <img
            src={LowIcon}
            loading="lazy"
            decoding="async"
            alt=""
          ></img>
        );
      })}
    </div>
  );
};

export default CustomRangeColumn;
