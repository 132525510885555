import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UploadFinishedReportDataModel {
  customerName: string;
  auditId: number;
  c4cId: string;
}

const initialDataState: UploadFinishedReportDataModel = {
  customerName: "",
  auditId: 0,
  c4cId: "",
};

const uploadFinishedReportsDataSlice = createSlice({
  name: "date",
  initialState: initialDataState,
  reducers: {
    setUploadFinishedReportsData(
      state,
      action: PayloadAction<UploadFinishedReportDataModel>
    ) {
      state.customerName = action.payload.customerName;
      state.auditId = action.payload.auditId;
      state.c4cId = action.payload.c4cId;
    },
  },
});

export const { setUploadFinishedReportsData } =
  uploadFinishedReportsDataSlice.actions;

export default uploadFinishedReportsDataSlice;
