import { OperationPermissions } from "../common/models/operationPermission";

export const PermissionService = (pageName: string) => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const navigationPermissions = decodedData ? JSON.parse(decodedData) : "";
    var dataValue = navigationPermissions?.navigationDetailList?.filter((data: any) => data.description === pageName);
    if (dataValue?.length>0 && dataValue[0] && dataValue[0].permissions) {
        const permissions: OperationPermissions = dataValue[0].permissions;
        return permissions;
    }
    else {
        const permissions: OperationPermissions = {view:false,update:false,delete:false,create:false};
        return permissions;
    }
};

export default PermissionService;